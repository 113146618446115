import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

const MAIN_TABLE_WIDTH_RATE = 1223 // 1.57
const ASK_DEALER_WIDTH_RATE = 1476 // 1.38
const DEFAULT_SCREEN_WIDTH_1920 = 1920 // 1
const DEFAULT_ZOOM = 1

const useScalding = () => {
  const dispatch = useDispatch()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [scaldingMaintable, setScaldingMaintable] = useState(screenWidth / MAIN_TABLE_WIDTH_RATE)
  const [scaldingAskDealer, setScaldingAskDealer] = useState(screenWidth / ASK_DEALER_WIDTH_RATE)
  const [scaldingDefault, setScaldingDefault] = useState(screenWidth / DEFAULT_SCREEN_WIDTH_1920)

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })

  useEffect(() => {
    setScaldingMaintable((screenWidth / MAIN_TABLE_WIDTH_RATE))
    setScaldingAskDealer((screenWidth / ASK_DEALER_WIDTH_RATE))
    setScaldingDefault((screenWidth / DEFAULT_SCREEN_WIDTH_1920))
  }, [screenWidth, location.pathname])

  return {
    pathname: location.pathname,
    is_main_page: location.pathname == '/main',
    scaldingMaintable: scaldingMaintable,
    scaldingAskDealer: scaldingAskDealer,
    scaldingDefault: scaldingDefault
  }
}

export default useScalding;