import BACK_INNER from "@assets/modal/bg-promo-back-inner.png"
import MODAL_PROMO from "@assets/modal/bg-promo.png"
import BTN_BACK from "@assets/modal/btn-back.png"
import BTN_CLOSE from "@assets/modal/btn-close.png"
import BTN_OK from "@assets/modal/btn-ok.png"
import PROMO_INPUT from "@assets/modal/promo-input.png"
import {
  backAction,
  setDataModalPromo,
  setDataModalPromoSubmitted,
  setModalPromoIsOpen,
} from "@redux/slices/actionsSlice"
import { RootState } from "@redux/store"
import React, { useEffect, useRef, useState } from "react"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"

import { nextAction } from "@redux/slices/actionsSlice"
import { setMetric } from "@redux/slices/metricSlice"
import { backCount, promoErrorCount } from "@services/performanceMetricApi"
import { MetricDto } from "@types"
import styles from "./Modal.module.css"

const ModalPromo = ({ scalding }: { scalding: number }) => {
  const inputPromoRef = useRef(null)
  const {
    modalPromoIsOpen,
    dataModalPromo,
    currentIndex,
    dataModalPromoSubmitted,
  } = useSelector((state: RootState) => state.actions)
  const { metric } = useSelector((state: RootState) => state.metric)
  const [inputPromo, setInputPromo] = useState<number | undefined>(undefined)

  const dispatch = useDispatch()
  const {
    promo,
    onBackAction = false,
    modalIndex = 0,
  } = dataModalPromo ? dataModalPromo : { promo: 0 }

  const submittedInput =
    dataModalPromoSubmitted.find((e: any) => e.modalIndex === modalIndex) ||
    undefined
  // @ts-ignore:next-line
  const previousInputPromo = submittedInput?.promo || undefined

  useEffect(() => {
    if (previousInputPromo) {
      setInputPromo(previousInputPromo)
    }
  }, [previousInputPromo])

  const onCloseModal = async (back: boolean) => {
    if (back) {
      console.log('back on modal')
      backCount((metric as MetricDto).id)
        .then((data) => {
          console.log("backend response backCount", data)
          dispatch(setMetric(data))
        })
        .catch((error) => {
          console.error(error)
        })
    }
    dispatch(setModalPromoIsOpen(false))
    if (!onBackAction) {
      dispatch(backAction(currentIndex - 1))
    }
    dispatch(
      setDataModalPromo({
        promo: 0,
        onBackAction: false,
      }),
    )
  }
  const onOk = async () => {
    dispatch(setModalPromoIsOpen(false))
    // @ts-ignore:next-line
    const currentInputPromo = inputPromoRef?.current?.value

    setInputPromo(currentInputPromo)

    if (Number(promo) === Number(currentInputPromo)) {
      console.log("-- User answered correctly ")
    } else {
      console.log("-- User answered incorrectly ")

      if (onBackAction) {
        if (Number(previousInputPromo) !== Number(currentInputPromo)) {
          console.log("-- different with previous")
          const data = await promoErrorCount((metric as MetricDto).id)
          console.log("backend response data", data)
          dispatch(setMetric(data))
        }
      } else {
        if (Number(previousInputPromo) !== Number(currentInputPromo)) {
          const data = await promoErrorCount((metric as MetricDto).id)
          console.log("backend response data", data)
          dispatch(setMetric(data))
        } else {
          console.log("--- User re-anwser with same input")
        }
      }
    }

    dispatch(
      setDataModalPromo({
        promo: 0,
        onBackAction: false,
      }),
    )

    // store user submitted
    let newSubmittedList = [...dataModalPromoSubmitted]
    const modalIndexFound = newSubmittedList.find(
      (i: any) => i.modalIndex === modalIndex,
    )
    // console.log('modalIndexFound', modalIndexFound)

    if (modalIndexFound) {
      newSubmittedList.splice(newSubmittedList.indexOf(modalIndexFound), 1)
    }

    newSubmittedList = newSubmittedList.concat({
      modalIndex: modalIndex,
      promo: currentInputPromo,
    })

    console.log("newSubmittedList", newSubmittedList)

    dispatch(setDataModalPromoSubmitted(newSubmittedList))

    if (onBackAction) {
      // go to next action after re-anwser on back
      dispatch(nextAction(currentIndex + 1))
    }
  }
  Modal.setAppElement("#root")
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "700px",
      overflow: "hidden",
      zoom: scalding,
    },
    overlay: {
      zIndex: 100000,
    },
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modalPromoIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            fontFamily: "Lato",
          }}
          className="relative w-[100%] text-white"
          id="popupModal"
        >
          <img src={MODAL_PROMO} alt="MODAL_PROMO" />
          <img
            className="absolute top-[70px] left-[35px] max-w-[calc(100%-70px)]"
            src={BACK_INNER}
            alt="BACK_INNER"
          />
          <img
            onClick={() => { onCloseModal(false) }}
            src={BTN_CLOSE}
            alt="BTN_CLOSE"
            className="w-[40px] absolute top-[3px] right-[33px] cursor-pointer"
          />
          <div className="w-full cursor-pointer absolute top-[113px] flex justify-center items-end">
            <img
              src={PROMO_INPUT}
              alt="PROMO_INPUT"
              className=" w-[210px] h-[48px] mr-5"
            />
            <img
              src={BTN_OK}
              alt="BTN_OK"
              className="w-[120px] cursor-pointer"
              onClick={onOk}
              id="popupNextBtn"
            />
          </div>
          <form
            action=""
            onSubmit={(event) => {
              event.preventDefault()
            }}
          >
            <div className="absolute top-[120px] left-[282px] font-bold text-[18px] w-[100px] text-center">
              <input
                ref={inputPromoRef}
                type="number"
                name="InputPromo"
                className={`bg-transparent font-bold text-[18px] w-[100px] h-[44px] p-1 border-transparent text-center ${styles.inputOutline}`}
                value={previousInputPromo ? inputPromo : undefined}
                onChange={(e) => {
                  setInputPromo(Number(e.target.value))
                }}
                autoFocus
              />
            </div>
          </form>
          <div className="absolute top-[250px] w-full">
            <img
              src={BTN_BACK}
              alt="BTN_BACK"
              className="w-[120px] cursor-pointer mx-auto"
              onClick={() => { onCloseModal(true) }}
              id="popupBackBtn"
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ModalPromo
