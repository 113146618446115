import {
  CARD_POSITION_HAND_READER,
  HIGHLIGHT_POSITION,
} from "@constants/HandReaderPosition"
import {
  setDataModalHandReader,
  setModalHandReaderIsOpen,
} from "@redux/slices/actionsSlice"
import { RootState } from "@redux/store"
import anime from "animejs"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

export type ActionHighlightCardsProps = {
  playerPositionEnd: number
  counterAction: number
  animationRef?: any
}

const ActionHighlightCards = (props: ActionHighlightCardsProps) => {
  const { modalHandReaderIsOpen, currentIndex, actions } = useSelector(
    (state: RootState) => state.actions,
  )
  const dispatch = useDispatch()
  const { playerPositionEnd, animationRef, counterAction } = props
  const { gameMode } = useSelector((state: RootState) => state.actions)
  const [isPlayed, setIsPlayed] = useState(false)

  const handReaderCode = actions && (actions[counterAction] as any)?.input;

  useEffect(() => {
    if (playerPositionEnd) {
      console.log('running animation')
      animationRef.is_array = true
      animationRef.subAnimations = []
      animationRef.highlight = {}
      animationRef.is_hightlight = true
      let numberCards = [1, 2, 3, 4]
      if (gameMode === "no_limit_hold_em") {
        numberCards = [1, 2]
      }
      numberCards.forEach((num: number, index: number) => {
        const key = `highlight-${playerPositionEnd}-${num}-${index}`
        animationRef.subAnimations[key] = {}
        animationRef.subAnimations[key].current = anime({
          targets: `.s-${playerPositionEnd}-${num}`,
          translateX:
            CARD_POSITION_HAND_READER[gameMode][
              `PLAYER_${playerPositionEnd}_${num}`
            ].X,
          translateY:
            CARD_POSITION_HAND_READER[gameMode][
              `PLAYER_${playerPositionEnd}_${num}`
            ].Y,
          duration: 200,
          scale: 2.5,
          easing: "linear",
          zIndex: 100001,
        })
      })

      animationRef.current = anime({
        autoplay: true,
        targets: ".highlight-" + playerPositionEnd,
        opacity: 1,
        duration: 300,
        complete: function (anim) {

          if (
            modalHandReaderIsOpen === false &&
            anim.completed &&
            anim.progress === 100
          ) {
            dispatch(
              setDataModalHandReader({
                code: handReaderCode,
                playerPosition: Number(playerPositionEnd),
                onBackAction: isPlayed,
                modalIndex: counterAction,
              }),
            )
            setTimeout(() => {
              dispatch(setModalHandReaderIsOpen(true))
            }, 50)
            setIsPlayed(true)
          }
        },
      })

      animationRef.highlight = anime({
        targets: `.highlight-${playerPositionEnd}`,
        opacity: [0,1],
        duration: 750,
        scale: 1.05,
        easing: "linear",
        zIndex: 100001,
        loop: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationRef, dispatch, gameMode, playerPositionEnd])

  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          top: HIGHLIGHT_POSITION[gameMode][`PLAYER_${playerPositionEnd}`].top,
          left: HIGHLIGHT_POSITION[gameMode][`PLAYER_${playerPositionEnd}`]
            .left,
          width: HIGHLIGHT_POSITION[gameMode].HIGHLIGHT_WIDTH,
          height: HIGHLIGHT_POSITION[gameMode].HIGHLIGHT_HEIGHT,
          transform: `rotate(${HIGHLIGHT_POSITION[gameMode][`PLAYER_${playerPositionEnd}`].ROTATE}deg)`
        }}
        className={`opacity-0 highlight-${playerPositionEnd} border-[2px] rounded-[15px] border-[#fc3d17] z-[100001]`}
      ></div>
      <button
        id={`reverse-action-highlight-cards-${counterAction}`}
        className="opacity-0"
        onClick={() => {
          if (animationRef.is_array) {
            Object.entries(animationRef.subAnimations).forEach((entry) => {
              let subAnime: any = entry[1]
              if (subAnime.current && subAnime.current.completed) {
                subAnime.current.reverse()
                subAnime.current.play()
              }
            })
          }
          if (animationRef.current) {
            animationRef.current.reverse()
            animationRef.current.play()
          }
          if (animationRef.highlight) {
            animationRef.highlight.remove(`.highlight-${playerPositionEnd}`)
          }
        }}
      ></button>
    </React.Fragment>
  )
}

export default ActionHighlightCards
