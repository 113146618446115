import {
  CHIP_TYPE,
  CHIP_VALUE,
  MAIN_POT_POSITION_TRANSLATE,
} from "@constants/Chip"
import { PLAYER_NUMBER } from "@constants/Player"
import { randomIntFromInterval } from "@helpers/commonFunctions"
import React, { useCallback } from "react"
import ActionCollectChip from "./ActionCollectChip"

export type ActionActionPromo2Props = {
  betsForPromo: number
  control?: boolean
  animationRef?: any
}

const ActionPromo2 = (props: ActionActionPromo2Props) => {
  const { animationRef, betsForPromo } = props

  const ActionCollectChipsToPromo = useCallback(() => {
    let _betsForPromo = betsForPromo || 0
    let _counter = 0
    const Elements: JSX.Element[] = []

    animationRef.is_array = true
    animationRef.subAnimations = []

    const domElements: any =
      document.getElementsByClassName("main-pot-collected")

    CHIP_TYPE.forEach((type) => {
      PLAYER_NUMBER.forEach((num) => {
        for (let element of domElements) {
          if (
            !element.classList.contains("rake-collected") &&
            !element.classList.contains("promo2-collected") &&
            element.classList.contains(`${type}-${num}`) &&
            _betsForPromo > 0
          ) {
            _counter += 1
            const _key = `${type}-${num}-${_counter}-${randomIntFromInterval(
              0,
              99999,
            )}`
            animationRef.subAnimations[_key] = {}
            Elements.push(
              <ActionCollectChip
                translateX={
                  MAIN_POT_POSITION_TRANSLATE[num].X +
                  -154 +
                  randomIntFromInterval(-5, 5)
                }
                translateY={
                  MAIN_POT_POSITION_TRANSLATE[num].Y +
                  100 +
                  randomIntFromInterval(-5, 5)
                }
                targets={element}
                key={_key}
                animationRef={animationRef.subAnimations[_key]}
              />,
            )
            _betsForPromo = _betsForPromo - CHIP_VALUE[type]
            setTimeout(() => {
              element.classList.add("promo2-collected")
            }, 100)
          }
        }
      })
    })
    return Elements
  }, [animationRef, betsForPromo])
  return <React.Fragment>{ActionCollectChipsToPromo()}</React.Fragment>
}

export default ActionPromo2
