export const PLAYER_POSITION_REVEAL: any = {
  1: {
    top: "top-[458px]",
    left: "left-[196px]",
  },
  2: {
    top: "top-[396px]",
    left: "left-[195px]",
  },
  3: {
    top: "top-[273px]",
    left: "left-[171px]",
  },
  4: {
    top: "top-[158px]",
    left: "left-[284px]",
  },
  5: {
    top: "top-[160px]",
    left: "left-[456px]",
  },
  6: {
    top: "top-[234px]",
    left: "left-[680px]",
  },
  7: {
    top: "top-[214px]",
    left: "left-[744px]",
  },
  8: {
    top: "top-[400px]",
    left: "left-[815px]",
  },
  9: {
    top: "top-[462px]",
    left: "left-[662px]",
  },
}
