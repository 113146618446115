import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface MetricState {
  metric: object
}

const initialState: MetricState = {
  metric: {},
}

const metricSlice = createSlice({
  name: "metric",
  initialState,
  reducers: {
    setMetric(state, action: PayloadAction<object>) {
      state.metric = action.payload
    },
    resetMetric(state) {
      state.metric = {}
    },
  },
})

export const { setMetric, resetMetric } = metricSlice.actions

export default metricSlice.reducer
