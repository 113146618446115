import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "../store"
import axios from "../../config/axiosInstance"

interface User {
  id: number
  name: string
  email: string
  token: string
  showAskDealer: boolean
  alternatePotLocation: boolean,
  scriptMode: {
    hasScriptModeFirst: boolean,
    hasScriptModeSecond: boolean,
    hasScriptModeThird: boolean
  }
}

interface IRegisterRequest {
  username: string
  password: string
  name: string
  email: string
  phoneNumber: string
  address: string
  city: string
  state: string
  zipcode: number
  paymentMethodId: string
}

interface AuthState {
  user: User | null
  isLoading: boolean
  error: string | null
}

const initialState: AuthState = {
  user: null,
  isLoading: false,
  error: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signinStart(state) {
      state.isLoading = true
      state.error = null
    },
    signinSuccess(state, action: PayloadAction<User>) {
      state.isLoading = false
      state.user = action.payload
    },
    signinFailure(state, action: PayloadAction<string>) {
      state.isLoading = false
      state.error = action.payload
    },
    signout(state) {
      state.user = null
    },
    setUserInformation(state, action: PayloadAction<any>) {
      state.user = {
        ...state.user,
        ...action.payload
      }
    }
  },
})

export const { signinStart, signinSuccess, signinFailure, signout, setUserInformation } =
  authSlice.actions

export default authSlice.reducer

export const signin =
  (user: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(signinStart())
      // Call api sign-in
      const response = await axios.post("/auth/login", {
        username: user,
        password,
      })
      const { id, name, email, token, showAskDealer, alternatePotLocation, scriptMode } = response.data
      const authUser = { id, name, email, token, showAskDealer, alternatePotLocation, scriptMode }

      if (authUser) {
        dispatch(signinSuccess(authUser))
      }

      return true
    } catch (error: any) {
      console.log("error.data", error?.response?.data?.error)
      dispatch(signinFailure(error?.response?.data?.error))
      if (error?.response?.status === 403) {
        dispatch(
          signinFailure("Please verify your email account and try again."),
        )
      } else {
        dispatch(
          signinFailure(
            "The sign-in credentials are incorrect. Please try again.",
          ),
        )
      }
      return false
    }
  }

export const fetchUserInfo = (): AppThunk =>
  async (dispatch) => {
    try {
      const response = await axios.get("/auth/current-user-information")
      if (response && response.data) {
        console.log('current-user-information', response.data)
        dispatch(setUserInformation(response.data))
      }
    } catch (error: any) {
      console.log("error.data", error?.response?.data?.error)
    }
  }

export const register =
  (payload: IRegisterRequest): AppThunk =>
  async (dispatch) => {
    try {
      await axios.post("/auth/register", {
        username: payload.username,
        password: payload.password,
        name: payload.name,
        email: payload.email,
        address: payload.address,
        phone_number: payload.phoneNumber,
        city: payload.city,
        state: payload.state,
        zipcode: payload.zipcode,
        payment_method_id: payload.paymentMethodId,
      })
      return { success: true }
    } catch (error: any) {
      return { success: false, error: error?.response?.data?.error }
    }
  }
