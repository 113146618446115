import MainPage from "@components/MainLayout/MainLayout"
import RequireAuth from "@hoc/requireAuth"
import BlackLayout from "@layouts/BlackLayout"
import EmailConfirmationFailure from "@pages/EmailConfirmationFailure"
import EmailConfirmationSuccess from "@pages/EmailConfirmationSuccess"
import LoginPage from "@pages/LoginPage"
import RegisterPage from "@pages/RegisterPage"
import SelectGameMode from "@pages/SelectGameMode"
import TermsOfServicePage from "@pages/TermsOfServicePage"
import { Route, Routes } from "react-router-dom"

export default function App() {
  return (
    <Routes>
      <Route element={<BlackLayout />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register/tos" element={<TermsOfServicePage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/email-confirmation-success"
          element={<EmailConfirmationSuccess />}
        />
        <Route
          path="/email-confirmation-failure"
          element={<EmailConfirmationFailure />}
        />
        <Route
          path="/select-game-mode"
          element={
            <RequireAuth>
              <SelectGameMode />
            </RequireAuth>
          }
        />
        <Route
          path="/main"
          element={
            <RequireAuth>
              <MainPage />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  )
}

function PublicPage() {
  return <h3>Public</h3>
}

function ProtectedPage() {
  return <h3>Protected</h3>
}
