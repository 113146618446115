import React, { useState, useLayoutEffect } from "react"
import { useNavigate } from "react-router-dom"

const EmailConfirmationSuccess = () => {
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(3)

  useLayoutEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown > 0 ? prevCountdown - 1 : 0,
      )
    }, 1000)

    // Redirect to the login page after the countdown reaches 0
    if (countdown <= 0) {
      navigate("/login")
    }

    // Clean up the timer to prevent memory leaks
    return () => clearInterval(timer)
  }, [countdown, navigate])

  const handleRedirectClick = () => {
    navigate("/login")
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="p-8 max-w-md w-full bg-white shadow-md rounded-lg">
        <h2 className="text-3xl text-center text-green-600 font-semibold mb-4">
          Email Confirmation Success
        </h2>
        <p className="text-lg text-center text-gray-700">
          Your email has been successfully confirmed.
        </p>
        <p className="text-lg text-center text-gray-700 mt-2">
          Redirecting to login page in {countdown} seconds...
        </p>
        <div className="flex justify-center mt-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleRedirectClick}
          >
            Redirect Now
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmailConfirmationSuccess
