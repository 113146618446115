import BTN_BACK from "@assets/modal/btn-back.png"
import BTN_CLOSE from "@assets/modal/btn-close.png"
import BTN_OK from "@assets/modal/btn-ok.png"
import {
  backAction,
  setDataModalHandReader,
  setDataModalHandReaderSubmitted,
  setModalHandReaderIsOpen,
} from "@redux/slices/actionsSlice"
import { RootState } from "@redux/store"
import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"

import { nextAction } from "@redux/slices/actionsSlice"
import { setMetric } from "@redux/slices/metricSlice"
import { backCount, handReaderErrorCount } from "@services/performanceMetricApi"
import { MetricDto } from "@types"

const ModalHandReader = ({ scalding }: { scalding: number }) => {
  const {
    modalHandReaderIsOpen,
    currentIndex,
    dataHandReader,
    dataModalHandReader,
    dataModalHandReaderSubmitted,
  } = useSelector((state: RootState) => state.actions)
  const { metric } = useSelector((state: RootState) => state.metric)
  const [currentCode, setCurrentCode] = useState<string | undefined>(undefined)
  const [dataHandReaderFilter, setDataHandReaderFilter] =
    useState<any[]>(dataHandReader)

  const dispatch = useDispatch()
  const { code, playerPosition, onBackAction, modalIndex } = dataModalHandReader

  const submittedInput =
    dataModalHandReaderSubmitted.find(
      (e: any) => e.modalIndex === modalIndex,
    ) || undefined
  // @ts-ignore:next-line
  const previousCode = submittedInput?.code || undefined
  // @ts-ignore:next-line
  const isOnBack = currentIndex === submittedInput?.modalIndex
  const previousCodeIndex = dataHandReaderFilter && dataHandReaderFilter.findIndex(x => x.code == previousCode)

  useEffect(() => {
    setDataHandReaderFilter(dataHandReader)
  }, [dataHandReader, currentIndex])

  useEffect(() => {
    if (previousCode) {
      setCurrentCode(previousCode)
    } else {
      setCurrentCode(dataHandReader[0]?.code)
    }
  }, [dataHandReader, previousCode])

  useEffect(() => {
    if (dataHandReaderFilter && dataHandReaderFilter.length > 0) {
      setCurrentCode(dataHandReaderFilter[0].code)
    }
  }, [dataHandReaderFilter])

  useEffect(() => {
    const scroll = (scrollByAmount: number) => {
      const element = document.getElementById("hand-reader-container")
      if (element) {
        element.scrollBy(0, scrollByAmount)
      }
    }
    const handleEvent = (event: { key: any }) => {
      const target = document.activeElement
      if (target && target.id === "modalHandReader") {
        const _currentIndex = dataHandReaderFilter.findIndex(
          (el) => Number(el.code) === Number(currentCode),
        )
        if (_currentIndex !== -1) {
          if (event.key.toLocaleLowerCase() === "s") {
            if (_currentIndex < dataHandReaderFilter.length - 1) {
              setCurrentCode(dataHandReaderFilter[_currentIndex + 1].code)
              scroll(26)
            }
          }
          if (event.key.toLocaleLowerCase() === "w") {
            if (_currentIndex > 0) {
              setCurrentCode(dataHandReaderFilter[_currentIndex - 1].code)
              scroll(-26)
            }
          }
        }
      }
    }
    document.addEventListener("keydown", handleEvent)
    return () => {
      document.removeEventListener("keydown", handleEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCode, dataHandReaderFilter, previousCodeIndex])

  useEffect(() => {
    if (modalHandReaderIsOpen) {
      if (previousCodeIndex > 0) {
        setTimeout(() => {
          const element = document.getElementById("hand-reader-container")
          const scrollToNumber = 26 * previousCodeIndex as number
          console.log('element.scroll', scrollToNumber)
          element?.scrollBy(0, scrollToNumber)
        }, 50)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalHandReaderIsOpen])

  const onCloseModal = async (back: boolean) => {
    if (back) {
      console.log('back on modal')
      backCount((metric as MetricDto).id)
        .then((data) => {
          console.log("backend response backCount", data)
          dispatch(setMetric(data))
        })
        .catch((error) => {
          console.error(error)
        })
    }
    dispatch(setModalHandReaderIsOpen(false))
    if (!isOnBack) {
      dispatch(backAction(currentIndex - 1))
    }
    dispatch(
      setDataModalHandReader({
        code: "",
        playerPosition: -1,
        onBackAction: false,
      }),
    )
    const btnReverseActionHighlight = document.getElementById(
      `reverse-action-highlight-cards-${modalIndex}`,
    )
    if (btnReverseActionHighlight) {
      btnReverseActionHighlight.click()
    }
  }
  const onOk = async () => {
    dispatch(setModalHandReaderIsOpen(false))
    // @ts-ignore:next-line
    if (Number(currentCode) === Number(code)) {
      console.log("-- User answered correctly ")
    } else {
      console.log("-- User answered incorrectly ")
      if (isOnBack) {
        if (Number(previousCode) !== Number(currentCode)) {
          console.log("-- different with previous")
          const data = await handReaderErrorCount((metric as MetricDto).id)
          console.log("backend response data", data)
          dispatch(setMetric(data))
        }
      } else {
        if (Number(previousCode) !== Number(currentCode)) {
          const data = await handReaderErrorCount((metric as MetricDto).id)
          console.log("backend response data", data)
          dispatch(setMetric(data))
        } else {
          console.log("--- User re-anwser with same input")
        }
      }
    }

    dispatch(
      setDataModalHandReader({
        code: "",
        playerPosition: -1,
        onBackAction: false,
      }),
    )

    // store user submitted
    let newSubmittedList = [...dataModalHandReaderSubmitted]
    const modalIndexFound = newSubmittedList.find(
      (i: any) => i.modalIndex === modalIndex,
    )

    if (modalIndexFound) {
      newSubmittedList.splice(newSubmittedList.indexOf(modalIndexFound), 1)
    }

    newSubmittedList = newSubmittedList.concat({
      modalIndex: modalIndex,
      code: currentCode,
    })

    console.log("newSubmittedList", newSubmittedList)

    dispatch(setDataModalHandReaderSubmitted(newSubmittedList))

    if (isOnBack) {
      // go to next action after re-anwser on back
      dispatch(nextAction(currentIndex + 1))
    }

    const btnReverseActionHighlight = document.getElementById(
      `reverse-action-highlight-cards-${modalIndex}`,
    )
    if (btnReverseActionHighlight) {
      btnReverseActionHighlight.click()
    }
  }

  const onSearch = (searchText: string) => {
    const _dataHandReaderFilter = dataHandReader.filter((element) =>
      element.hand_name.toLowerCase().includes(searchText.toLowerCase()),
    )
    setDataHandReaderFilter(_dataHandReaderFilter)
  }
  Modal.setAppElement("#root")
  const customStyles = {
    content: {
      top: "50%",
      left: [1, 2, 3, 4, 5].includes(playerPosition) ? "75%" : "35%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "35%",
      overflow: "hidden",
      zoom: scalding,
      backgroundColor: "#4C6880",
    },
    overlay: {
      zIndex: 100000,
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modalHandReaderIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
        id="modalHandReader"
      >
        <div
          style={{
            fontFamily: "Lato",
          }}
          className="relative w-[100%] text-white h-auto px-4 py-8"
          id="popupModal"
        >
          <img
            onClick={() => { onCloseModal(false) }}
            src={BTN_CLOSE}
            alt="BTN_CLOSE"
            className="w-[23px] absolute top-[6px] right-[16px] cursor-pointer"
          />

          <input
            type="text"
            name="change"
            className={`bg-white w-full p-1 border-white rounded text-[15px] text-black`}
            placeholder="Hand name"
            onChange={(e) => {
              onSearch(e.target.value)
            }}
          />
          <div
            id="hand-reader-container"
            className="w-full h-[40vh] overflow-y-auto mt-4 mb-6"
          >
            <table className="border-2 border-white text-[13px] w-full table-hand-reader">
              <thead>
                <tr className="text-left uppercase">
                  <th>Hand Name</th>
                  <th>Kicker</th>
                </tr>
              </thead>
              <tbody>
                {dataHandReaderFilter &&
                  dataHandReaderFilter.length > 0 &&
                  dataHandReaderFilter.map((item: any, index: number) => (
                    <tr
                      className={
                        (previousCode && previousCode == item.code) ? "bg-[#2563eb]" : ((currentCode === item.code) ? "bg-[#2563eb]" : "")
                      }
                      key={index}
                      onClick={() => {
                        setCurrentCode(item.code)
                      }}
                    >
                      <td>{item.hand_name}</td>
                      <td>{item.kicker}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="absolute bottom-[10px] right-28">
            <img
              src={BTN_OK}
              alt="BTN_OK"
              className="w-[90px] cursor-pointer"
              onClick={onOk}
              id="popupNextBtn"
            />
          </div>
          <div className="absolute bottom-[10px] left-28">
            <img
              src={BTN_BACK}
              alt="BTN_BACK"
              className="w-[90px] cursor-pointer mx-auto"
              onClick={() => { onCloseModal(true) }}
              id="popupBackBtn"
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ModalHandReader
