import ActionShowOut from "@components/Animations/ActionShowOut"
import { CHIP_TYPE } from "@constants/Chip"
import { PLAYER_WINNER_POSITION, PLAYER_WINNER_V2_POSITION } from "@constants/MainPotChips"
import { PLAYER_NUMBER } from "@constants/Player"
import { randomIntFromInterval } from "@helpers/commonFunctions"
import anime from "animejs/lib/anime.es.js"
import React, { useCallback, useEffect, useState } from "react"
import { RootState } from "@redux/store"
import { useSelector } from "react-redux"

export type ActionPotPros = {
  translateX: number
  translateY: number
  targets: string
  iconType: string
  className?: string
  control?: boolean
  delay?: number
  playerPositionEnd: number
  hideOldIcons: string[]
  chipInfos?: {
    quantityOfType: any
    bet: number
    change: number
  }
  animationRef?: any
}

const ActionMainPot = (props: ActionPotPros) => {
  const {
    control = false,
    translateX,
    translateY,
    targets,
    delay = 0,
    iconType,
    className = "",
    hideOldIcons = [],
    playerPositionEnd,
    chipInfos,
    animationRef,
  } = props
  const { user } = useSelector((state: RootState) => state.auth)
  // const [hideOld, setHideOld] = useState(false)

  // animationRef.is_array = true
  // animationRef.subAnimations = []

  // useEffect(() => {
  //   // hide old show out icon
  //   const domElements: any =
  //     document.getElementsByClassName("main-pot-collected")
  //   let counter = 0
  //   for (let element of domElements) {
  //     counter += 1
  //     if (
  //       !element.classList.contains("side-pot-collected") &&
  //       !element.classList.contains("rake-collected") &&
  //       !element.classList.contains("promo2-collected")
  //     ) {
  //       const _key = `pot-hidden-${counter}-${randomIntFromInterval(0, 19999)}`
  //       animationRef.subAnimations[_key] = {}
  //       animationRef.subAnimations[_key].current = anime({
  //         targets: element,
  //         autoplay: true,
  //         delay: delay,
  //         easing: "easeInOutExpo",
  //         opacity: 0,
  //         duration: 1,
  //       })
  //     }
  //   }
  // }, [animationRef, delay, hideOld, hideOldIcons, iconType])

  useEffect(() => {
    animationRef.current = anime({
      targets: `.main-pot-collected`,
      autoplay: true,
      delay: delay,
      easing: "easeInOutExpo",
      opacity: 0,
    })
  }, [animationRef, control, delay])

  // useEffect(() => {
  //   // hide old show out icon
  //   if (!hideOld && hideOldIcons.length > 0) {
  //     hideOldIcons.forEach((iconClass: string) => {
  //       const oldElements: any = document.getElementsByClassName(iconClass)
  //       for (let element of oldElements) {
  //         element.classList.add("hidden")
  //       }
  //     })
  //   }
  //   setTimeout(() => {
  //     setHideOld(true)
  //   }, 10)
  // }, [hideOld, hideOldIcons, iconType])

  useEffect(() => {
    if (animationRef && animationRef.current) {
      if (control) {
        animationRef.current.play()
      }
      if (!control && animationRef.current.completed) {
        animationRef.current.reverse()
      }
    }
  }, [control])

  const ActionCollectChips = useCallback(() => {
    animationRef.is_array = true
    animationRef.subAnimations = []
    const Elements: JSX.Element[] = []

    const domElements: any = document.getElementsByClassName("main-pot-collected")
    let _counter = 1

    for (let element of domElements) {
      console.log("element", element)
      const className = element.classList[1]
      const type = className.split("-")[2]
      animationRef.subAnimations[`pot-${type}-${_counter}`] = {}
      Elements.push(
        <ActionShowOut
          translateX={
            (user?.alternatePotLocation ? PLAYER_WINNER_V2_POSITION[playerPositionEnd].X : PLAYER_WINNER_POSITION[playerPositionEnd].X) +
            randomIntFromInterval(-10, 10)
          }
          translateY={
            (user?.alternatePotLocation ? PLAYER_WINNER_V2_POSITION[playerPositionEnd].Y : PLAYER_WINNER_POSITION[playerPositionEnd].Y) +
            randomIntFromInterval(-10, 10)
          }
          className={`winner-main-pot absolute w-[90px] ${user?.alternatePotLocation ? 'top-[310px] left-[300px]' : 'top-[350px] left-[484px]' } pot-${type}-${_counter}`}
          targets={`pot-${type}-${_counter}`}
          key={`${type}-${_counter}-${randomIntFromInterval(0, 10000)}`}
          action={"pot"}
          iconType={type}
          animationRef={animationRef.subAnimations[`pot-${type}-${_counter}`]}
        />,
      )
      _counter += 1
    }

    // CHIP_TYPE.forEach((type) => {
    //   PLAYER_NUMBER.forEach((num) => {
    //     const domElements: any = document.getElementsByClassName(
    //       `${type}-${num}`,
    //     )
    //     let _counter = 1
    //     for (let element of domElements) {
    //       animationRef.subAnimations[`pot-${type}-${num}`] = {}
    //       Elements.push(
    //         <ActionShowOut
    //           translateX={
    //             PLAYER_WINNER_POSITION[playerPositionEnd].X +
    //             randomIntFromInterval(-10, 10)
    //           }
    //           translateY={
    //             PLAYER_WINNER_POSITION[playerPositionEnd].Y +
    //             randomIntFromInterval(-10, 10)
    //           }
    //           className={`winner-main-pot absolute w-[90px] top-[350px] left-[484px] pot-${type}-${num}`}
    //           targets={`pot-${type}-${num}`}
    //           key={`${type}-${num}-${_counter}-${randomIntFromInterval(
    //             0,
    //             10000,
    //           )}`}
    //           action={"pot"}
    //           iconType={type}
    //           animationRef={animationRef.subAnimations[`pot-${type}-${num}`]}
    //         />,
    //       )
    //       _counter += 1
    //     }
    //   })
    // })
    return Elements
  }, [animationRef, playerPositionEnd])
  return <React.Fragment>{ActionCollectChips()}</React.Fragment>
}

export default ActionMainPot
