import axios from 'axios';
import dotenv from 'dotenv';
import { store } from "@redux/store"

const instance = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const storeAllState = store.getState();
    const token = storeAllState?.auth?.user?.token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
