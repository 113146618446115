export const PLAYER_WINNER_POSITION: any = {
  1: {
    X: -231,
    Y: 47,
  },
  2: {
    X: -282,
    Y: 12,
  },
  3: {
    X: -278,
    Y: -67,
  },
  4: {
    X: -205,
    Y: -82,
  },
  5: {
    X: -19,
    Y: -87,
  },
  6: {
    X: 152,
    Y: -87,
  },
  7: {
    X: 220,
    Y: -65,
  },
  8: {
    X: 250,
    Y: 4,
  },
  9: {
    X: 183,
    Y: 63,
  },
}


export const PLAYER_WINNER_V2_POSITION: any = {
  1: {
    X: -46,
    Y: 100,
  },
  2: {
    X: -128,
    Y: 65,
  },
  3: {
    X: -128,
    Y: -48,
  },
  4: {
    X: -25,
    Y: -80,
  },
  5: {
    X: 158,
    Y: -81,
  },
  6: {
    X: 339,
    Y: -87,
  },
  7: {
    X: 440,
    Y: -44,
  },
  8: {
    X: 440,
    Y: 56,
  },
  9: {
    X: 378,
    Y: 78,
  },
}
