import * as React from "react"
import { useLocation, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "@redux/store"

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth.user,
})

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useSelector(mapStateToProps)

  let location = useLocation()

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

export default RequireAuth
