import React from "react"
import { useNavigate } from "react-router-dom"

const EmailConfirmationFailure = () => {
  const navigate = useNavigate()

  const handleRedirectClick = () => {
    navigate("/login")
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="p-8 max-w-md w-full bg-white shadow-md rounded-lg">
        <h2 className="text-3xl text-center text-red-600 font-semibold mb-4">
          Email Confirmation Failed
        </h2>
        <p className="text-lg text-center text-gray-700">
          Email confirmation failed. Please try again.
        </p>
        <div className="flex justify-center mt-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleRedirectClick}
          >
            Go to Login
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmailConfirmationFailure
