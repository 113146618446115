export const COIN_POSITION_TRANSLATE: any = {
  1: {
    X: 20,
    Y: -60,
  },
  2: {
    X: 60,
    Y: -34,
  },
  3: {
    X: 56,
    Y: 36,
  },
  4: {
    X: 0,
    Y: 50,
  },
  5: {
    X: 0,
    Y: 46,
  },
  6: {
    X: 0,
    Y: 50,
  },
  7: {
    X: -46,
    Y: 28,
  },
  8: {
    X: -58,
    Y: -38,
  },
  9: {
    X: -12,
    Y: -54,
  },
}
