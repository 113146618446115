export const REMOVE_CARD_POSITION: any = {
  1: {
    X: 163,
    Y: -57,
    rotate: 0,
  },
  2: {
    X: 163,
    Y: 2,
    rotate: 0,
  },
  3: {
    X: 186,
    Y: 127,
    rotate: 0,
  },
  4: {
    X: 75,
    Y: 243,
    rotate: 0,
  },
  5: {
    X: -97,
    Y: 241,
    rotate: 0,
  },
  6: {
    X: -321,
    Y: 164,
    rotate: 0,
  },
  7: {
    X: -385,
    Y: 186,
    rotate: 0,
  },
  8: {
    X: -450,
    Y: 36,
    rotate: 0,
  },
  9: {
    X: -303,
    Y: -62,
    rotate: 0,
  },
}
