import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { PaymentMethodResult } from "@stripe/stripe-js"
import React, { forwardRef, useCallback, useImperativeHandle } from "react"

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
}

export type StripeInputCardMethods = {
  confirmPayment: (
    formData: FormData,
  ) => Promise<PaymentMethodResult | { error: { message: string } }>
}

const InputCard = forwardRef<StripeInputCardMethods>((props, ref) => {
  const stripe = useStripe()
  const elements = useElements()

  const confirmPayment = useCallback(
    async (formData: FormData) => {
      if (!elements) {
        return { error: { message: "No card elements" } }
      }
      const cardElem = elements.getElement(CardElement)
      if (stripe && cardElem) {
        const data = await stripe.createPaymentMethod({
          type: "card",
          card: cardElem,
          billing_details: {
            name: formData.get("name") as string,
            phone: formData.get("phoneNumber") as string,
            email: formData.get("email") as string,
          },
        })
        return data
      }
      return { error: { message: "Card elements can't verify" } }
    },
    [stripe, elements],
  )
  useImperativeHandle(
    ref,
    () => {
      return {
        confirmPayment: confirmPayment,
      }
    },
    [confirmPayment],
  )
  return (
    <div className="block max-w-lg m-auto p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-200 dark:border-gray-700">
      <h5 className="text-2xl font-bold tracking-tight text-gray-90 mb-7">
        Payment Verification
      </h5>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  )
})

export default InputCard
