import { signin, signout } from "@redux/slices/authSlice"
import { RootState } from "@redux/store"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { redirect, useNavigate } from "react-router-dom"
import NewLogoWithText from "../../assets/main/new-logo-with-text.png"
import styles from "./LoginPage.module.css"

const LoginPage = () => {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const { user } = useSelector((state: RootState) => state.auth)
  const errorMessage = useSelector((state: RootState) => state.auth.error)
  // const errorMessage = "The sign-in credentials are incorrect. Please try again.";

  const [loginSuccess, setLoginSuccess] = React.useState(null)
  const isShowLoginError = typeof(loginSuccess) === 'boolean' && !loginSuccess

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    let formData = new FormData(event.currentTarget)
    let userId = formData.get("userId") as string
    let password = formData.get("password") as string

    // @ts-ignore:next-line
    const signInResult = await dispatch(signin(userId, password))
    // @ts-ignore:next-line
    setLoginSuccess(signInResult)
    console.log("go down there", signInResult)

    if (user) {
      // TODO: Check user
      navigate("/select-game-mode")
    }
  }

  React.useEffect(() => {
    if (user) {
      navigate("/select-game-mode")
    }
  }, [user])

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="mt-10 mx-auto relative w-[1024px]">
          <img
            src={NewLogoWithText}
            className="mb-10 max-w-full h-auto"
            alt="new main logo"
          />
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="flex justify-between mb-20">
              <label htmlFor="userId" className="text-[55px] text-[#000000]">
                User ID:
              </label>
              <div>
                <input
                  id="userId"
                  name="userId"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${isShowLoginError ? 'border-2 border-red-500' : 'border-0'}`}
                />
              </div>
            </div>

            <div className="flex justify-between mb-5">
              <label htmlFor="userId" className="text-[55px] text-[#000000]">
                Password:
              </label>
              <div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${isShowLoginError ? 'border-2 border-red-500' : 'border-0'}`}
                />
              </div>
            </div>

            { isShowLoginError ?
              <div className="flex justify-between">
                <label htmlFor="userId" className="text-[55px] text-[#6B9EFF]">
                </label>
                <p className="text-red-500 text-[30px]">{errorMessage}</p>
              </div>
              : null }

            <div className="flex mt-20">
              <button
                type="submit"
                className={`w-[356px] h-[123px] ml-auto justify-center rounded-[20px] px-3 py-1.5 text-[55px] font-bold text-white ${styles.bgNextBtn}`}
              >
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default LoginPage
