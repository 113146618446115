import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "@redux/slices/counterSlice"
import authReducer from "@redux/slices/authSlice"
import actionsReducer from "@redux/slices/actionsSlice"
import metricReducer from "@redux/slices/metricSlice"
import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"

const persistConfig = {
  key: "chipstacks",
  version: 1,
  storage,
}

const rootReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  actions: actionsReducer,
  metric: metricReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

const persistedStore = persistStore(store)
export { store, persistedStore }

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
