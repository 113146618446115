import React from "react"
import SINGLE_CAR from "@assets/cards/SINGLE_CAR.png"
import { SRC_CARD } from "@constants/SingleCard"
import styles from "./SingleCard.module.css"

export type SingleCardPros = {
  cardType?: string
  cardName?: string
  className?: string | string
  style?: React.CSSProperties | undefined
  optionalStyles?: boolean
}

const SingleCard = (props: SingleCardPros) => {
  const { className = "", cardType, cardName, style } = props
  return (
    <React.Fragment>
      <div
        style={style}
        className={`single-card ${className} ${styles.revealCard}`}
      >
        <img
          src={cardType && cardName ? SRC_CARD[cardType][cardName] : SINGLE_CAR}
          alt="CARD"
        />
      </div>
    </React.Fragment>
  )
}

export default SingleCard
