import ActionShowOut from "@components/Animations/ActionShowOut"
import { CHIP_TYPE } from "@constants/Chip"
import { PLAYER_NUMBER } from "@constants/Player"
import { WINNER_SIDE_POSITION } from "@constants/SidePotChips"
import { randomIntFromInterval } from "@helpers/commonFunctions"
import anime from "animejs/lib/anime.es.js"
import React, { useCallback, useEffect, useState } from "react"

export type ActionSidePotPros = {
  translateX: number
  translateY: number
  targets: string
  iconType: string
  className?: string
  control?: boolean
  delay?: number
  playerPositionEnd: number
  hideOldIcons: string[]
  chipInfos?: {
    quantityOfType: any
    bet: number
    change: number
  }
  animationRef?: any
}

const ActionSidePot = (props: ActionSidePotPros) => {
  const {
    control = false,
    translateX,
    translateY,
    targets,
    delay = 0,
    iconType,
    className = "",
    hideOldIcons = [],
    playerPositionEnd,
    chipInfos,
    animationRef,
  } = props

  useEffect(() => {
    animationRef.current = anime({
      targets: `.side-pot-collected`,
      autoplay: true,
      delay: delay,
      easing: "easeInOutExpo",
      opacity: 0,
    })
  }, [animationRef, control, delay])

  useEffect(() => {
    if (animationRef && animationRef.current) {
      if (control) {
        animationRef.current.play()
      }
      if (!control && animationRef.current.completed) {
        animationRef.current.reverse()
      }
    }
  }, [animationRef, control])

  const ActionCollectChips = useCallback(() => {
    animationRef.is_array = true
    animationRef.subAnimations = []
    const Elements: JSX.Element[] = []

    const domElements: any = document.getElementsByClassName("side-pot-collected")
    let _counter = 1
    for (let element of domElements) {
      console.log("element", element)
      const className = element.classList[1]
      const type = className.split("-")[2]
      animationRef.subAnimations[`pot2-${type}-${_counter}`] = {}
      Elements.push(
        <ActionShowOut
          translateX={
            WINNER_SIDE_POSITION[playerPositionEnd].X +
            randomIntFromInterval(-10, 10)
          }
          translateY={
            WINNER_SIDE_POSITION[playerPositionEnd].Y +
            randomIntFromInterval(-10, 10)
          }
          className={`winner-side-pot absolute w-[90px] top-[294px] left-[629px] pot2-${type}-${_counter}`}
          targets={`pot2-${type}-${_counter}`}
          key={`${type}-${_counter}-${randomIntFromInterval(0, 10000)}`}
          action={"pot"}
          iconType={type}
          animationRef={animationRef.subAnimations[`pot2-${type}-${_counter}`]}
        />,
      )
      _counter += 1
    }

    // CHIP_TYPE.forEach((type) => {
    //   PLAYER_NUMBER.forEach((num) => {
    //     const domElements: any =
    //       document.getElementsByClassName("side-pot-collected")
    //     let _counter = 1
    //     for (let element of domElements) {
    //       animationRef.subAnimations[`pot2-${type}-${num}`] = {}
    //       Elements.push(
    //         <ActionShowOut
    //           translateX={
    //             WINNER_SIDE_POSITION[playerPositionEnd].X +
    //             randomIntFromInterval(-10, 10)
    //           }
    //           translateY={
    //             WINNER_SIDE_POSITION[playerPositionEnd].Y +
    //             randomIntFromInterval(-10, 10)
    //           }
    //           className={`winner-side-pot absolute w-[90px] top-[294px] left-[629px] pot2-${type}-${num}`}
    //           targets={`pot2-${type}-${num}`}
    //           key={`${type}-${num}-${_counter}-${randomIntFromInterval(
    //             0,
    //             10000,
    //           )}`}
    //           action={"pot"}
    //           iconType={type}
    //           animationRef={animationRef.subAnimations[`pot2-${type}-${num}`]}
    //         />,
    //       )
    //       _counter += 1
    //     }
    //   })
    // })
    return Elements
  }, [animationRef, playerPositionEnd])
  return <React.Fragment>{ActionCollectChips()}</React.Fragment>
}

export default ActionSidePot
