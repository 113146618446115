export const ICON_POSITION_TRANSLATE: any = {
  1: {
    X: 30,
    Y: -85,
  },
  2: {
    X: 84,
    Y: -40,
  },
  3: {
    X: 70,
    Y: 45,
  },
  4: {
    X: -20,
    Y: 80,
  },
  5: {
    X: -15,
    Y: 75,
  },
  6: {
    X: 0,
    Y: 80,
  },
  7: {
    X: -64,
    Y: 42,
  },
  8: {
    X: -72,
    Y: -42,
  },
  9: {
    X: -20,
    Y: -85,
  },
}

export const CHECK_FOLD_ICON_POSITION_TRANSLATE: any = {
  1: {
    X: 30,
    Y: -85,
  },
  2: {
    X: 84,
    Y: -40,
  },
  3: {
    X: 70,
    Y: 45,
  },
  4: {
    X: 0,
    Y: 80,
  },
  5: {
    X: 0,
    Y: 75,
  },
  6: {
    X: 0,
    Y: 80,
  },
  7: {
    X: -64,
    Y: 42,
  },
  8: {
    X: -72,
    Y: -42,
  },
  9: {
    X: -20,
    Y: -85,
  },
}