export const GAME_MODES: any = {
  NLHE: "no_limit_hold_em",
  PLO: "pot_limit_omaha",
  PLO_TOURNAMENT: "plo_tournament",
}

export const GAME_MODES_ID: any = {
  no_limit_hold_em: 0,
  pot_limit_omaha: 1,
  plo_tournament: 2,
}
