import AuthStatus from "@components/AuthStatus"
import ModalActionWindow from "@components/PopUp/ActionWindow"
import ModalCalculate from "@components/PopUp/ModalCalculate"
import ModalChip from "@components/PopUp/ModalChip"
import ModalHandReader from "@components/PopUp/ModalHandReader"
import ModalPromo from "@components/PopUp/ModalPromo"
import ModalRemaining from "@components/PopUp/ModalRemaining"
import ModalWinAction from "@components/PopUp/ModalWinAction"
import useScalding from "@hooks/useScalding"
import { useAppSelector } from "@redux/hooks"
import {
  resetCounter,
  setModalActionWindowIsOpen,
} from "@redux/slices/actionsSlice"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Outlet, useLocation } from "react-router-dom"

const BlackLayout = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { scaldingDefault, scaldingMaintable } = useScalding()
  const { pathname } = useLocation()
  const loginOrGameModeSelectPage = pathname && ['/login', '/select-game-mode', '/', '/register', '/register/tos'].includes(pathname)
  const modalActionWindowIsOpen = useAppSelector(
    (state) => state.actions.modalActionWindowIsOpen,
  )

  useEffect(() => {
    const handleTabClose = (event: any) => {
      console.log("beforeunload event triggered")
      dispatch(resetCounter())
    }
    window.addEventListener("beforeunload", handleTabClose)
    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  useEffect(() => {
    if (location.pathname !== "/main") return

    const handleShowActionModal = (event: KeyboardEvent) => {
      const windowProps = window as any
      if (event.key.toLocaleLowerCase() === "t") {
        dispatch(setModalActionWindowIsOpen(!modalActionWindowIsOpen))
        return
      }
      if (event.key.toLocaleLowerCase() === "z") {
        if (windowProps.countKeydownZ === 0) {
          return
        }
        dispatch(setModalActionWindowIsOpen(true))
      }
    }
    window.addEventListener("keydown", handleShowActionModal)
    return () => {
      window.removeEventListener("keydown", handleShowActionModal)
    }
  }, [dispatch, modalActionWindowIsOpen, location.pathname])

  return (
    <div
      // @ts-ignore:next-line
      className={`${
        loginOrGameModeSelectPage ? "bg-[#FFFFFF]" : "bg-[#1E1E1E]"
      } flex flex-col h-full overflow-hidden`}
    >
      <AuthStatus scalding={scaldingDefault} />
      <Outlet />
      <ModalChip scalding={scaldingMaintable} />
      <ModalRemaining scalding={scaldingMaintable} />
      <ModalCalculate scalding={scaldingMaintable} />
      <ModalPromo scalding={scaldingMaintable} />
      <ModalHandReader scalding={scaldingMaintable} />
      <ModalWinAction scalding={scaldingMaintable} />
    </div>
  )
}

export default BlackLayout
