export const WINNER_SIDE_POSITION: any = {
  1: {
    X: -372,
    Y: 100,
  },
  2: {
    X: -441,
    Y: 72,
  },
  3: {
    X: -413,
    Y: -4,
  },
  4: {
    X: -352,
    Y: -44,
  },
  5: {
    X: -166,
    Y: -33,
  },
  6: {
    X: 8,
    Y: -34,
  },
  7: {
    X: 87,
    Y: -13,
  },
  8: {
    X: 91,
    Y: 54,
  },
  9: {
    X: 30,
    Y: 101,
  },
}
