
// calculate the distance to translate from element A location to element B location
export function calculateMovingDistance(elA: HTMLElement, elB: HTMLElement) {
  // find the center of each element
  const centerA = getCenterLocation(elA, true);
  const centerB = getCenterLocation(elB);

  return {
    x: centerB.x - centerA.x,
    y: centerB.y - centerA.y
  }
}

function getCenterLocation(element: HTMLElement, withTransformation = false) {
  const location = element.getBoundingClientRect();

  const transform = {x: 0, y: 0}

  if (withTransformation) {
    const style = window.getComputedStyle(element)
    const matrix = new DOMMatrixReadOnly(style.transform)
    transform.x = matrix.m41;
    transform.y = matrix.m42;
  }

  return {
    x: location.x + location.width / 2 - transform.x,
    y: location.y + location.height / 2 - transform.y
  }
}

export function compareArrays(a: any, b: any) {
  return a.length === b.length && a.every((element: any, index: number) => element === b[index]);
}

export function onlyUnique(value: any, index: number, array: any) {
  return array.indexOf(value) === index;
}

export function calculateMovingDistanceWithMuckIcon(element: HTMLElement, muckIcon: HTMLElement) {
  const style = window.getComputedStyle(element);
  let elementTop, elementLeft;
  elementTop = parseInt(style.getPropertyValue('top'));
  elementLeft = parseInt(style.getPropertyValue('left'));
  // muckIcon Center: top: 386px, left: 348px
  // distance = muckIcon - element
  let distance = {
    x: 348 - elementLeft, // left
    y: 386 - elementTop // top
  }
  return distance
}