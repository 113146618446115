import { REMOVE_CARD_POSITION } from "@constants/PositionRemoveCard"
import { calculateMovingDistance, calculateMovingDistanceWithMuckIcon } from "@helpers/utils"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect, useState } from "react"

export type ActionDiscardCardPros = {
  translateX: number
  translateY: number
  targets: string
  iconType: string
  className?: string
  control?: boolean
  delay?: number
  hideOldIcons?: string[]
  animationRef?: any
  playerPositionEnd: number
}

const ActionDiscardCard = (props: ActionDiscardCardPros) => {
  const {
    translateX,
    translateY,
    targets,
    delay = 0,
    animationRef,
    playerPositionEnd,
  } = props
  //   animationRef.subAnimations[`s-${playerPositionEnd}`] = {}
  // const animationRef = useRef<AnimeInstance | null>(null)
  const [hideOld] = useState(false)
  useEffect(() => {
    const muckIcon = document.getElementById('muckIcon');

    animationRef.is_array = true
    animationRef.subAnimations = []
    const domElements: any = document.querySelectorAll(
      `.s-${playerPositionEnd},.f-card-${playerPositionEnd}`,
    )
    let index = 1
    for (let element of domElements) {

      let distance = {
        x: REMOVE_CARD_POSITION[playerPositionEnd].X,
        y: REMOVE_CARD_POSITION[playerPositionEnd].Y
      }

      if (muckIcon) {
        distance = calculateMovingDistanceWithMuckIcon(element, muckIcon)
      }

      animationRef.subAnimations[`action-s-${playerPositionEnd}-${index}`] = {}
      animationRef.subAnimations[
        `action-s-${playerPositionEnd}-${index}`
      ].current = anime({
        targets: element,
        autoplay: true,
        delay: delay,
        keyframes: [
          {
            translateX: distance.x,
            translateY: distance.y,
            rotate: REMOVE_CARD_POSITION[playerPositionEnd].rotate,
            easing: "easeInOutQuad",
          },
          { opacity: 0, duration: 10 },
        ],
        complete: function(anim) {

          // this should not be the case but we just check to be sure
          if (!muckIcon) return;

          if (!anim.reversed) {
            // show muck icon
            anime({
              targets: muckIcon,
              autoplay: true,
              opacity: 1,
              duration: 500
            })

            // count number of times we have muckings so we can reverse correctly later
            if (!muckIcon.dataset.muckCounter) {
              muckIcon.dataset.muckCounter = '0';
            }

            const counter = parseInt(muckIcon.dataset.muckCounter) + 1;
            muckIcon.dataset.muckCounter = counter.toString();
          }

          //this is a hack, we must set this to false so when we reverse, the `begin` callback can run
          anim.began = false;
        },
        begin: function(anim) {
          if (anim.direction == 'reverse') {
            // if this is a reverse action, we need to know whether we should hide
            // muck icon or not
            if (muckIcon) {
              const counter = muckIcon.dataset?.muckCounter ? parseInt(muckIcon.dataset?.muckCounter) : 0

              if (counter >= 1) {
                muckIcon.dataset.muckCounter = (counter - 1).toString()
              }

              if (counter <= 1) {
                anime({
                  targets: muckIcon,
                  autoplay: true,
                  opacity: 0,
                  duration: 1000
                })
              }
            }
          }
        }
      })
      element.classList.remove("show-cards")
      index += 1
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, hideOld, targets, translateX, translateY, animationRef])

  return <React.Fragment></React.Fragment>
}

export default ActionDiscardCard
