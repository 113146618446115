import { getElementForActionShowOut } from "@helpers/commonFunctions"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "@redux/store"

export type ActionShowOutPros = {
  translateX: number
  translateY: number
  targets: string
  iconType: string
  className?: string
  control?: boolean
  delay?: number
  hideOldIcons?: string[]
  animationRef?: any
  style?: React.CSSProperties | undefined
  action: string
}

const ActionShowOut = (props: ActionShowOutPros) => {
  const {
    control = false,
    translateX,
    translateY,
    targets,
    delay = 0,
    iconType,
    className = "",
    hideOldIcons = [],
    animationRef,
    action,
    style,
  } = props
  // const animationRef = useRef<AnimeInstance | null>(null)
  const [hideOld, setHideOld] = useState(false)

  // get gameMode for check chip Coin
  const { gameMode } = useSelector((state: RootState) => state.actions)
  useEffect(() => {
    if (hideOld) {
      animationRef.current = anime({
        targets: `.${targets}`,
        autoplay: true,
        delay: delay,
        keyframes: [
          {
            opacity: 1,
            duration: 5,
          },
          {
            translateX: translateX,
            translateY: translateY,
            easing: "easeInOutExpo",
            duration: 450,
          },
        ],
        update: function (e) {
          this.animatables[0].target.classList.remove("hidden")
        },
        complete: function (e) {
          if (this.completed && this.reversed) {
            console.log("completed reverse")
            this.animatables[0].target.classList.add("hidden")
          }
        },
      })
    }
  }, [animationRef, delay, hideOld, targets, translateX, translateY])

  useEffect(() => {
    // hide old show out icon
    if (action !== "pot" && !hideOld && hideOldIcons.length > 0) {
      hideOldIcons.forEach((iconClass: string) => {
        const oldElements: any = document.getElementsByClassName(iconClass)
        for (let element of oldElements) {
          if (
            !element.classList.contains("side-pot-collected") &&
            !element.classList.contains("main-pot-collected") &&
            !element.classList.contains("rake-collected") &&
            !element.classList.contains("promo2-collected")
          ) {
            // console.log("-------- add hidden", element)
            element.classList.add("hidden")
          }
        }
      })
    }
    setTimeout(() => {
      setHideOld(true)
    }, 10)
  }, [hideOld, hideOldIcons, iconType, action])
  useEffect(() => {
    if (animationRef && animationRef.current) {
      if (control) {
        animationRef.current.play()
      }
      if (!control && animationRef.current.completed) {
        animationRef.current.reverse()
      }
    }
  }, [animationRef, control])
  return (
    <React.Fragment>
      {hideOld && (
        <div style={style} className={`opacity-0 ${className}`}>
          {getElementForActionShowOut(gameMode, iconType)}
        </div>
      )}
    </React.Fragment>
  )
}

export default ActionShowOut
