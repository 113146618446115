import { useAppDispatch } from "@redux/hooks"
import { RootState } from "@redux/store"
import * as React from "react"
import { useSelector } from "react-redux"
import { signout } from "@redux/slices/authSlice"
import { resetMetric } from "@redux/slices/metricSlice"
import { resetActions, resetActionsState } from "@redux/slices/actionsSlice"
import { useLocation } from "react-router-dom"

const AuthStatus = ({ scalding }: { scalding: number }) => {
  let auth = useSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const loginOrGameModeSelectPage = pathname && ['/login', '/select-game-mode', '/'].includes(pathname)

  const handleSignOut = () => {
    dispatch(signout())
    dispatch(resetMetric())
    dispatch(resetActionsState())
  }

  if (!auth.user) {
    return <p className={`${loginOrGameModeSelectPage ? 'text-[#000000]' : 'text-[#6B9EFF]'} text-[16px]`}>You are not logged in.</p>
  }

  return (
    <div
      style={{ zoom: scalding }}
      className={`${loginOrGameModeSelectPage ? 'text-[#000000]' : 'text-[#6B9EFF]'} text-[18px] h-[30px] mr-[15px] ml-[15px]`}
      id="headerStatus"
    >
      Welcome {auth.user.name}!
      <button className="float-right" onClick={() => handleSignOut()}>
        Sign out
      </button>
    </div>
  )
}

export default AuthStatus
