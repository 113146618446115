/// import for NLHE mode
import NLHE_WHITE_COIN from "@assets/icons/NLHE/white_coin.png"
import NLHE_RED_COIN from "@assets/icons/NLHE/red_coin.png"
import NLHE_GREEN_COIN from "@assets/icons/NLHE/green_coin.png"
import NLHE_BLACK_COIN from "@assets/icons/NLHE/black_coin.png"

/// import for PLO mode
import PLO_WHITE_COIN from "@assets/icons/PLO/white_coin.png"
import PLO_RED_COIN from "@assets/icons/PLO/red_coin.png"
import PLO_GREEN_COIN from "@assets/icons/PLO/green_coin.png"
import PLO_BLACK_COIN from "@assets/icons/PLO/black_coin.png"

// import for PLO TOURNAMENT mode
import PLO_TOURNAMENT_WHITE_COIN from "@assets/icons/PLO_Tournament/white_coin.png"
import PLO_TOURNAMENT_RED_COIN from "@assets/icons/PLO_Tournament/red_coin.png"
import PLO_TOURNAMENT_GREEN_COIN from "@assets/icons/PLO_Tournament/green_coin.png"
import PLO_TOURNAMENT_BLACK_COIN from "@assets/icons/PLO_Tournament/black_coin.png"

import { GAME_MODES } from "@constants/GameMode"

export function gameModeIcons(mode: string) {
  let whiteCoin,
    redCoin,
    greenCoin,
    blackCoin = ""
  switch (mode) {
    case GAME_MODES.NLHE:
      whiteCoin = NLHE_WHITE_COIN
      redCoin = NLHE_RED_COIN
      greenCoin = NLHE_GREEN_COIN
      blackCoin = NLHE_BLACK_COIN
      break
    case GAME_MODES.PLO:
      whiteCoin = PLO_WHITE_COIN
      redCoin = PLO_RED_COIN
      greenCoin = PLO_GREEN_COIN
      blackCoin = PLO_BLACK_COIN
      break
    case GAME_MODES.PLO_TOURNAMENT:
      whiteCoin = PLO_TOURNAMENT_WHITE_COIN
      redCoin = PLO_TOURNAMENT_RED_COIN
      greenCoin = PLO_TOURNAMENT_GREEN_COIN
      blackCoin = PLO_TOURNAMENT_BLACK_COIN
      break
  }
  return {
    whiteCoin,
    redCoin,
    greenCoin,
    blackCoin,
  }
}
