export const PLAYER_POSITION: any = {
  1: {
    top: "top-[458px]",
    left: "left-[196px]",
  },
  2: {
    top: "top-[396px]",
    left: "left-[72px]",
  },
  3: {
    top: "top-[214px]",
    left: "left-[96px]",
  },
  4: {
    top: "top-[158px]",
    left: "left-[270px]",
  },
  5: {
    top: "top-[158px]",
    left: "left-[446px]",
  },
  6: {
    top: "top-[158px]",
    left: "left-[612px]",
  },
  7: {
    top: "top-[214px]",
    left: "left-[764px]",
  },
  8: {
    top: "top-[400px]",
    left: "left-[786px]",
  },
  9: {
    top: "top-[462px]",
    left: "left-[662px]",
  },
}

export const CHECK_FOLD_POSITION: any = {
  1: {
    top: "top-[444px]",
    left: "left-[202px]",
  },
  2: {
    top: "top-[386px]",
    left: "left-[89px]",
  },
  3: {
    top: "top-[224px]",
    left: "left-[108px]",
  },
  4: {
    top: "top-[170px]",
    left: "left-[244px]",
  },
  5: {
    top: "top-[158px]",
    left: "left-[432px]",
  },
  6: {
    top: "top-[158px]",
    left: "left-[612px]",
  },
  7: {
    top: "top-[220px]",
    left: "left-[754px]",
  },
  8: {
    top: "top-[394px]",
    left: "left-[770px]",
  },
  9: {
    top: "top-[450px]",
    left: "left-[655px]",
  },
}

export const PLAYER_POSITION_COIN: any = {
  1: {
    top: 458,
    left: 234,
  },
  2: {
    top: 408,
    left: 122,
  },
  3: {
    top: 246,
    left: 142,
  },
  4: {
    top: 194,
    left: 274,
  },
  5: {
    top: 194,
    left: 462,
  },
  6: {
    top: 194,
    left: 642,
  },
  7: {
    top: 244,
    left: 776,
  },
  8: {
    top: 408,
    left: 796,
  },
  9: {
    top: 460,
    left: 684,
  },
}

export const PLAYER_NUMBER = [1, 2, 3, 4, 5, 6, 7, 8, 9]
