export const MAIN_POT_POSITION_TRANSLATE: any = {
  1: { X: 225, Y: -105 },
  2: { X: 337, Y: -55 },
  3: { X: 317, Y: 106 },
  4: { X: 185, Y: 157 },
  5: { X: -3, Y: 156 },
  6: { X: -184, Y: 157 },
  7: { X: -318, Y: 106 },
  8: { X: -337, Y: -56 },
  9: { X: -225, Y: -110 },
}

export const MAIN_POT_V2_POSITION_TRANSLATE: any = {
  1: { X: 52, Y: -156 },
  2: { X: 166, Y: -108 },
  3: { X: 145, Y: 56 },
  4: { X: 12, Y: 106 },
  5: { X: -176, Y: 108 },
  6: { X: -353, Y: 110 },
  7: { X: -490, Y: 58 },
  8: { X: -515, Y: -100 },
  9: { X: -397, Y: -156 },
}

export const SIDE_POT_POSITION_TRANSLATE: any = {
  1: { X: 403, Y: -153 },
  2: { X: 515, Y: -103 },
  3: { X: 495, Y: 58 },
  4: { X: 363, Y: 109 },
  5: { X: 175, Y: 108 },
  6: { X: -6, Y: 109 },
  7: { X: -140, Y: 58 },
  8: { X: -159, Y: -104 },
  9: { X: -47, Y: -158 },
}

export const CHIP_TYPE = ["white", "red", "green", "black"]
export const CHIP_VALUE: any = {
  black: 100,
  green: 25,
  red: 5,
  white: 1,
}
