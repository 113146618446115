export const BTN_DEALER_POSITION: any = {
  1: {
    top: 352,
    left: 255,
  },
  2: {
    top: 322,
    left: 195,
  },
  3: {
    top: 288,
    left: 183,
  },
  4: {
    top: 236,
    left: 220,
  },
  5: {
    top: 236,
    left: 410,
  },
  6: {
    top: 236,
    left: 580,
  },
  7: {
    top: 266,
    left: 670,
  },
  8: {
    top: 326,
    left: 653,
  },
  9: {
    top: 352,
    left: 590,
  },
}
