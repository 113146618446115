import backBtn from "@assets/icons/back_btn.png"
import deadlerBtn from "@assets/icons/deadler.png"
import handIcon from "@assets/icons/hand.png"
import nextBtn from "@assets/icons/next_btn.png"
import characterLayout from "@assets/layouts/character.png"
import character1 from "@assets/layouts/character_1.png"
import characterTopLeft from "@assets/layouts/character_3.png"
import character7 from "@assets/layouts/character_7.png"
import character8 from "@assets/layouts/character_8.png"
import character9 from "@assets/layouts/character_9.png"
import characterLeft from "@assets/layouts/character_left.png"
import characterRight from "@assets/layouts/character_right.png"
import characterTop from "@assets/layouts/character_top.png"
import tableLayout from "@assets/layouts/table_fixed.png"
import promoIcon from "@assets/icons/promo_icon.jpg"
import muckImage from "@assets/cards/muck.png"
import ActionD from "@components/Animations/ActionD"
import { DELAY_DEFAULT, DELAY_BETWEEN_ACTIONS } from "@constants/Actions"
import { BTN_DEALER_POSITION } from "@constants/Dealer"
import { GAME_MODES, GAME_MODES_ID } from "@constants/GameMode"
import {
  findNearestChipElements,
  getActionElement,
  randomIntFromInterval,
  resetDealer,
} from "@helpers/commonFunctions"
import useScalding from "@hooks/useScalding"
import {
  backAction,
  fetchActions,
  fetchCurrentExerciseId,
  fetchDataHandReader,
  nextAction,
  resetActions,
  resetCounter,
  setCurrentDealerPosition,
} from "@redux/slices/actionsSlice"
import { resetMetric, setMetric } from "@redux/slices/metricSlice"
import { fetchUserInfo } from "@redux/slices/authSlice"
import { RootState } from "@redux/store"
import {
  fetchMetric,
  setEndTime,
  backCount,
} from "@services/performanceMetricApi"
import { ActionDto, MetricDto } from "@types"
import * as React from "react"
import { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./MainLayout.module.css"
import anime from "animejs"
import ActionWindow from "@components/PopUp/ActionWindow"
import ModalWinAction from "@components/PopUp/ModalWinAction"
// import actions from "./actions.json"

const MainLayout = () => {
  const dispatch = useDispatch()
  const {
    currentIndex,
    actions,
    listExerciseIds,
    currentExerciseId,
    gameMode,
    currentDealerPosition,
    exerciseLoading,
    modalWinActionIsOpen,
  } = useSelector((state: RootState) => state.actions)
  const { user } = useSelector((state: RootState) => state.auth)
  const [loadExercise, setLoadExercise] = useState(false)
  const [disabledBtnNext, setDisabledBtnNext] = useState(false)
  const [firstExercise, setFirstExercise] = useState(currentExerciseId)
  const [disabledBtnBack, setDisabledBtnBack] = useState(false)
  const [askDealer, setAskDealer] = useState(true)
  const [actionELements, setActionELements] = useState<any[]>([])
  const [dealerDescription, setDealerDescription] = useState<string>("")
  const { metric } = useSelector((state: RootState) => state.metric)
  const { scaldingMaintable, scaldingAskDealer } = useScalding()
  const [isUpdating, setIsUpdating] = useState(false)
  const windowProps = window as any
  const handNarrator = document.getElementById("handNarrator")

  const onNext = () => {
    console.log(isUpdating || modalWinActionIsOpen)
    if (isUpdating || modalWinActionIsOpen) {
      console.log("running action, please wait!")
      return
    }
    setIsUpdating(true)

    console.log(
      "%c onNext action ============================================================= ",
      "color: green",
    )
    if (handNarrator) {
      handNarrator.scrollTop = 0
    }
    console.log("- currentIndex", currentIndex)
    const hideOldIcons = ["icon-f", "icon-c", "icon-a"]
    if (currentIndex < actions.length && !disabledBtnNext) {
      setDisabledBtnNext(true)
      let _counter = currentIndex
      let autoCounter = 0
      do {
        let animationRef = {}
        let actionElement = actionELements && actionELements[_counter]
        const dealerDescription = (actions[_counter] as ActionDto)
          .dealerDescription
        if (dealerDescription) {
          setDealerDescription(dealerDescription)
        } else {
          setDealerDescription("")
        }
        if (actionElement) {
          console.log(
            "- in case restarting the already action we had ran before",
            actionElement,
          )
          let actionName = (actions[_counter] as ActionDto).type
          if (actionName == "dealb") {
            let nextDealerPosition = currentDealerPosition + 1

            if (nextDealerPosition > 9) {
              nextDealerPosition = 1
            }

            console.log("<<<<<<<< move dealer position <<<<<<<< ")
            console.log("nextDealerPosition", nextDealerPosition)

            dispatch(setCurrentDealerPosition(nextDealerPosition))
          }
          if (actionElement === "pop-up") {
            getActionElement(
              actions[_counter] as ActionDto,
              autoCounter,
              _counter,
              animationRef,
              dispatch,
              undefined,
              gameMode,
              user,
            )
          } else {
            let currentAnimationRef = actionElement?.props?.animationRef
            if (currentAnimationRef?.is_array) {
              Object.entries(currentAnimationRef.subAnimations).forEach(
                (entry) => {
                  let subAnime: any = entry[1]
                  console.log("- subAnime", subAnime)
                  subAnime.current.reverse()
                  subAnime.current.play()
                },
              )
            }
            currentAnimationRef?.current?.reverse()
            currentAnimationRef?.current?.play()
            if (currentAnimationRef.is_hightlight) {
              actionElement.props.animationRef.highlight = anime({
                targets: `.highlight-${actionElement?.props?.playerPositionEnd}`,
                opacity: [0, 1],
                duration: 750,
                scale: 1.05,
                easing: "linear",
                zIndex: 1000000,
                loop: true,
              })
            }
          }
          // handle back: hide chips of current user
          if (actionElement.props && actionElement.props.playerPositionStart) {
            const chipElements = document.getElementsByClassName(
              `chips-${actionElement.props.playerPositionStart}`,
            )
            for (let chipElement of chipElements) {
              if (
                !chipElement.classList.contains("hidden") &&
                !chipElement.classList.contains("side-pot-collected") &&
                !chipElement.classList.contains("main-pot-collected") &&
                !chipElement.classList.contains("rake-collected") &&
                !chipElement.classList.contains("promo2-collected")
              ) {
                // console.log("-------- add hidden", chipElement)
                chipElement.classList.add("hidden")
              }
            }
          }
        } else {
          console.log("- in case new action")
          let nextDealerPosition = (actions[0] as ActionDto).deadlerPosition
          let actionName = (actions[_counter] as ActionDto).type
          let isClearAfterDealb =
            currentDealerPosition !== nextDealerPosition &&
            actionName === "clear"
          console.log("isClearAfterDealb", isClearAfterDealb)
          if (
            actions.length > 0 &&
            (currentDealerPosition === nextDealerPosition || isClearAfterDealb)
          ) {
            actionElement = getActionElement(
              actions[_counter] as ActionDto,
              autoCounter,
              _counter,
              animationRef,
              dispatch,
              undefined,
              gameMode,
              user,
            )
            setActionELements((o) => {
              return [...o, actionElement]
            })
          }
        }

        console.log("- action", actions[_counter])
        console.log("- actionElement", actionElement)

        _counter = _counter + 1
        autoCounter = autoCounter + 1

        hideOldIcons.forEach((iconClass: string) => {
          const oldElements: any = document.getElementsByClassName(iconClass)
          for (let element of oldElements) {
            if (!element.classList.contains("hidden")) {
              // console.log("-------- add hidden", element)
              element.classList.add("hidden")
            }
          }
        })
      } while (
        _counter < actions.length &&
        actions[_counter] &&
        (actions[_counter] as ActionDto).autoRun
      )
      setTimeout(() => {
        setDisabledBtnNext(false)
      }, DELAY_DEFAULT * autoCounter)

      dispatch(nextAction(_counter))
    }
    if (
      disabledBtnNext &&
      actionELements[currentIndex - 1]?.props?.type === "deal" &&
      !actionELements[currentIndex - 1]?.props?.animationRef?.current
        ?.completed &&
      (actions[currentIndex - 1] as ActionDto).autoRun
    ) {
      actionELements
        // .filter((element) => !element?.props?.animationRef?.current?.completed)
        .forEach((actionELement, index) => {
          const delay = actionELement.props.delay
          // const target = `.${actionELement.props.targets}`
          const translateX = actionELement.props.translateX
          const translateY = actionELement.props.translateY
          const rotate = actionELement.props.rotate
          if (
            delay > 0 &&
            actionELement.props?.type === "deal" &&
            (actions[index] as ActionDto).autoRun
          ) {
            let animationRef = {}
            const _actionELement = (
              <ActionD
                translateX={translateX}
                translateY={translateY}
                rotate={rotate}
                targets={`deal-card-${
                  (actions[index] as ActionDto).playerPositionEnd
                }-${(actions[index] as ActionDto).cardNumber}`}
                key={`deal-card-${
                  (actions[index] as ActionDto).playerPositionEnd
                }-${
                  (actions[index] as ActionDto).cardNumber
                }-${randomIntFromInterval(0, 1000)}`}
                className={`f-card-${
                  (actions[index] as ActionDto).playerPositionEnd
                }`}
                animationRef={animationRef}
                type="deal"
                duration={5}
              />
            )
            actionELements[index] = _actionELement
          }
        })
      setDisabledBtnNext(false)
    }

    setTimeout(() => {
      setIsUpdating(false)
    }, DELAY_BETWEEN_ACTIONS)
  }

  const onBack = () => {
    if (isUpdating || currentIndex == 0 || modalWinActionIsOpen) {
      console.log("running action, please wait!")
      return
    }
    setIsUpdating(true)

    console.log(
      "%c onBack action ============================================================= ",
      "color: red",
    )

    backCount((metric as MetricDto).id)
      .then((data) => {
        console.log("backend response backCount", data)
        dispatch(setMetric(data))
      })
      .catch((error) => {
        console.error(error)
      })

    if (handNarrator) {
      handNarrator.scrollTop = 0
    }
    let previousIndex = currentIndex - 1
    let actionElement = actionELements && actionELements[previousIndex]
    let currentAction: any = actions[previousIndex]
    let onBackAction = true
    if (currentAction && currentAction.dealerDescription) {
      setDealerDescription(currentAction.dealerDescription)
    } else {
      setDealerDescription("")
    }
    console.log("previousIndex", previousIndex)
    console.log("actionELements", actionELements)

    if (actionElement && !disabledBtnBack) {
      setDisabledBtnBack(true)
      console.log("- in case back action", actionElement)
      console.log("- currentAction", currentAction)
      if (actionElement === "pop-up") {
        console.log("pop-up")
        getActionElement(
          currentAction as ActionDto,
          previousIndex,
          previousIndex,
          undefined,
          dispatch,
          onBackAction,
          gameMode,
          user,
        )
      } else {
        // handle back action: display hidden chips again
        if (actionElement.props && actionElement.props.playerPositionStart) {
          //only find the chip elements which is nearest to the current step
          const chipElements = findNearestChipElements(
            actionElement.props.playerPositionStart,
            previousIndex,
          )

          for (let chipElement of chipElements) {
            if (
              chipElement.classList.contains("hidden") &&
              !chipElement.classList.contains("side-pot-collected") &&
              !chipElement.classList.contains("main-pot-collected") &&
              !chipElement.classList.contains("rake-collected") &&
              !chipElement.classList.contains("promo2-collected")
            ) {
              // console.log("-------- add hidden", chipElement)
              chipElement.classList.remove("hidden")
            }
          }
        }
        let currentAnimationRef = actionElement.props.animationRef
        if (currentAnimationRef.is_array) {
          Object.entries(currentAnimationRef.subAnimations).forEach((entry) => {
            let subAnime: any = entry[1]
            console.log("- subAnime", subAnime)
            if (subAnime.current && subAnime.current.completed) {
              subAnime.current.reverse()
              subAnime.current.play()
            }
          })
        }
        if (currentAnimationRef.current) {
          currentAnimationRef.current.reverse()
          currentAnimationRef.current.play()
        }
        if (currentAnimationRef.is_hightlight) {
          actionElement.props.animationRef.highlight = anime({
            targets: `.highlight-${actionElement?.props?.playerPositionEnd}`,
            opacity: [0, 1],
            duration: 750,
            scale: 1.05,
            easing: "linear",
            zIndex: 1000000,
            loop: true,
          })
        }
        if (currentAction.type == "dealb") {
          console.log("____onBack dealb action___")
          let previousCurrentDealerPosition = currentDealerPosition - 1

          if (previousCurrentDealerPosition > 0) {
            previousCurrentDealerPosition = currentDealerPosition - 1
          } else {
            previousCurrentDealerPosition = 9
          }

          console.log("<<<<<<<< move previous dealer position <<<<<<<< ")
          console.log(
            "previousCurrentDealerPosition",
            previousCurrentDealerPosition,
          )

          dispatch(setCurrentDealerPosition(previousCurrentDealerPosition))
        }
      }
      setTimeout(() => {
        setDisabledBtnBack(false)
      }, 50)
      dispatch(backAction(previousIndex))
    } else {
      console.log("- in case action not yet implemented")
      console.log("- currentAction", currentAction)
      console.log("- actionElement ::", actionElement)
      console.log("- disabledBtnBack ::", disabledBtnBack)
      alert(
        // @ts-ignore:next-line
        `Action ${currentAction.type} with position start ${currentAction.playerPositionStart} is not handled`,
      )
      dispatch(backAction(previousIndex))
    }

    setTimeout(() => {
      setIsUpdating(false)
    }, DELAY_BETWEEN_ACTIONS)
  }

  const handleEndTime = async () => {
    try {
      const data = await setEndTime((metric as MetricDto).id)
      dispatch(setMetric(data))
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    console.log("0. first loading current exercise with id")
    // @ts-ignore:next-line
    dispatch(resetCounter())
    // @ts-ignore:next-line
    dispatch(fetchCurrentExerciseId(gameMode))
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // @ts-ignore:next-line
    dispatch(fetchDataHandReader(GAME_MODES_ID[gameMode]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    console.log("0. reset counter and re-fetch actions when reloading page")
    console.log("1. currentExerciseId", currentExerciseId)
    //reset muck icon position
    anime({
      targets: "#muckIcon",
      translateX: 0,
      translateY: 0,
      duration: 0,
      scale: 1,
      "data-muck-counter": "0",
      opacity: 0,
    })

    // @ts-ignore:next-line
    // @ts-ignore:next-line
    if (currentExerciseId && !loadExercise) {
      // @ts-ignore:next-line
      dispatch(fetchCurrentExerciseId(gameMode))

      console.log("2. currentExerciseId", currentExerciseId)
      console.log("3. firstExercise", firstExercise)

      if (currentExerciseId && currentExerciseId !== -1) {
        console.log(
          `------ dispatch(fetchActions(currentExerciseId, gameMode)): ${currentExerciseId} ${gameMode}`,
        )
        // @ts-ignore:next-line
        dispatch(fetchActions(currentExerciseId, gameMode))
        setFirstExercise(currentExerciseId)
      }

      if (currentExerciseId === -1) {
        console.log("fetchActions in case currentExerciseId == -1")
        // @ts-ignore:next-line
        dispatch(fetchActions(undefined, gameMode))
      }

      setLoadExercise(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadExercise, currentExerciseId, gameMode, firstExercise])

  React.useEffect(() => {
    if (metric && (metric as MetricDto).id) {
      fetchMetric((metric as MetricDto).id)
        .then((data) => {
          dispatch(setMetric(data))
        })
        .catch((error) => {
          console.error(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  React.useEffect(() => {
    if (
      currentIndex === actions.length &&
      listExerciseIds.length > 0 &&
      currentExerciseId
    ) {
      console.log("Set end time for current exercise")
      handleEndTime()

      const currentIndexExercise = listExerciseIds.findIndex(
        (id) => id == currentExerciseId,
      )

      console.log(
        `debug: currentExerciseId ${currentExerciseId} & listExerciseIds ${listExerciseIds}`,
      )
      console.log(
        `debug: currentIndexExercise ${currentIndexExercise} & ${
          listExerciseIds[currentIndexExercise + 1]
        }`,
      )

      // Handle Next Exercise
      if (
        currentIndexExercise !== -1 &&
        listExerciseIds[currentIndexExercise + 1]
      ) {
        console.log("%c Handle Next exercise", "color: green")
        dispatch(resetActions())
        console.log("listExerciseIds", listExerciseIds)
        console.log("currentIndexExercise", currentIndexExercise)
        console.log("Fetching next exercise...")
        if (disabledBtnNext) {
          dispatch(resetCounter())
          setActionELements([])
          dispatch(
            // @ts-ignore:next-line
            fetchActions(listExerciseIds[currentIndexExercise + 1], gameMode),
          )
          dispatch(resetMetric())
        }

        if (currentExerciseId == listExerciseIds[currentIndexExercise + 1]) {
          setDisabledBtnNext(false)
        }
      }

      console.log("currentIndexExercise", currentIndexExercise)
      console.log("listExerciseIds.at(-1)", listExerciseIds.at(-1))

      // Handle loop to first exercise when reached all exercises
      // Only mode NLHE can loop when script done
      if (
        currentIndexExercise !== -1 &&
        listExerciseIds.at(-1) == currentExerciseId &&
        gameMode === GAME_MODES.NLHE
      ) {
        console.log(
          "%c Handle loop to first exercise when reached all exercises",
          "color: green",
        )
        console.log(">>>>> last exercise <<<<<< ")

        const resetDealerElement = resetDealer(9, 0, {})
        console.log("resetDealerElement", resetDealerElement)

        setActionELements((o) => {
          return [...o, resetDealerElement]
        })

        setTimeout(() => {
          dispatch(resetActions())
          if (disabledBtnNext) {
            dispatch(resetCounter())
            setActionELements([])
            dispatch(
              // @ts-ignore:next-line
              fetchActions(listExerciseIds[0], gameMode),
            )
            dispatch(resetMetric())
          }
        }, 200)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions.length, dispatch, currentIndex])

  React.useEffect(() => {
    // @ts-ignore:next-line
    dispatch(fetchUserInfo())
  }, [])

  const isZoom = devicePixelRatio > 1
  const innerHeight = window.innerHeight < 576 ? 576 + window.innerHeight : 576
  const minHeight = (innerHeight * 1) / devicePixelRatio

  const handleSidebar = (e: any) => {
    ;(e.target as any).blur()
    setAskDealer(!askDealer)
  }

  const setWinActionStylesWhenSidebarToggle = useCallback(() => {
    const modalWinAction = document.getElementById("modalWinAction")
    const layoutContainerElement = document.getElementById("layoutContainer")
    const sidebarElement = document.getElementById("handNarrator")
    if (modalWinActionIsOpen && modalWinAction && layoutContainerElement) {
      // @ts-ignore:next-line
      if (
        askDealer &&
        sidebarElement &&
        sidebarElement.style.display !== "none"
      ) {
        switch (parseInt(layoutContainerElement.dataset.zoomLevel || "0")) {
          case 0:
            modalWinAction.style.inset = "312px auto auto 578px"
            break
          case 1:
            modalWinAction.style.inset = "312px auto auto 674px"
            break
          case 2:
            modalWinAction.style.inset = "312px auto auto 674px"
            break
          default:
            break
        }
      } else {
        modalWinAction.style.inset = "312px auto auto 494px"
      }
    }
  }, [modalWinActionIsOpen, askDealer])

  // When Toggle Before Win Action Happen
  if (askDealer) {
    setWinActionStylesWhenSidebarToggle()
  }

  useEffect(() => {
    setWinActionStylesWhenSidebarToggle()
  }, [askDealer])

  return (
    <>
      <div
        // @ts-ignore:next-line
        // style={{ minHeight: `${minHeight < 576 ? 576 : minHeight}px` }}
        className={
          styles.bgRed +
          `${
            isZoom
              ? " flex-1 overflow-hidden"
              : " h-[calc(100%-30px)] overflow-hidden"
          }`
        }
        id="layoutContainer"
        data-zoom-level="0"
      >
        <ActionWindow
          scalding={scaldingMaintable}
          isOpenHandNarrator={!!askDealer}
        />
        <div
          className={`main-layout h-full overflow-hidden flex flex-1 justify-center`}
        >
          {askDealer && (
            <div
              style={{
                zoom: scaldingAskDealer,
                zIndex: 1,
                wordBreak: "break-word",
              }}
              className="w-[200px] min-h-full bg-black text-orange-400 text-[15px] p-1 overflow-auto"
              id="handNarrator"
            >
              <div dangerouslySetInnerHTML={{ __html: dealerDescription }} />
            </div>
          )}
          <div
            style={{ zoom: scaldingMaintable }}
            className={`${styles.wrapMainLayout} mx-auto relative  w-[1024px] h-[658px] mt-[-80px]`}
            id="tableLayoutContainer"
          >
            <img src={tableLayout} className="table mt-[80px]" alt="table" />
            <img
              src={promoIcon}
              className="absolute w-[38px] top-[470px] left-[330px]"
              alt="promoIcon"
            />
            {/* yellow area main pot*/}
            <div className="absolute top-[285px] left-[372px] w-[270px] h-[78px] border-[4px] rounded-[15px] border-[#FCB917]"></div>
            {!exerciseLoading && (
              <div>
                <img
                  style={{
                    top: `${BTN_DEALER_POSITION[currentDealerPosition].top}px`,
                    left: `${BTN_DEALER_POSITION[currentDealerPosition].left}px`,
                  }}
                  src={deadlerBtn}
                  className={`btn-dealer ${styles.deadlerLayout} absolute`}
                  alt=""
                />
              </div>
            )}

            <div id="action-container">
              {actionELements
                .filter((a) => a !== "pop-up")
                .map((Action) => Action)}
            </div>
            <div style={{ zIndex: 0 }}>
              <img
                src={character1}
                className={styles.character1}
                alt="character 1"
              />
              <img
                src={characterLeft}
                className={styles.characterLeft}
                alt="character left"
              />
              <img
                src={characterTopLeft}
                className={styles.characterTopLeft}
                alt="character-top-left"
              />
              <img
                src={characterTop}
                className={styles.characterTop}
                alt="character-top"
              />
              <img
                src={characterLayout}
                className={styles.characterLayout}
                alt="character-middle"
              />
              <img
                src={characterRight}
                className={styles.characterRight}
                alt="character-right"
              />
              <img
                src={character7}
                className={styles.character7}
                alt="character 7"
              />
              <img
                src={character8}
                className={styles.character8}
                alt="character 8"
              />
              <img
                src={character9}
                className={styles.character9}
                alt="character 9"
              />
            </div>
            <div className="absolute top-[90px] w-full z-[100]">
              <div className="clearfix"></div>
              <img
                id="backBtn"
                src={backBtn}
                className={styles.backBtn}
                alt=""
                onClick={onBack}
              />
              <img
                id="nextBtn"
                data-modal-target="popupModal"
                data-modal-toggle="popupModal"
                src={nextBtn}
                className={styles.nextBtn}
                alt=""
                onClick={onNext}
              />
            </div>
            <div
              className="absolute opacity-0 top-[386px] left-[348px] z-[9999999]"
              id="muckIconCenterPosition"
            >
              O
            </div>
            <div
              className="absolute top-[355px] left-[290px] opacity-0"
              id="muckIcon"
            >
              <img src={muckImage} className={styles.muckImage} />
            </div>
            <div className="absolute top-[400px] left-[260px] z-[100]">
              <img
                src={handIcon}
                className={styles.handIcon + " w-[280px]"}
                alt=""
              />
            </div>
            {user?.showAskDealer && (
              <div className="flex items-center justify-center w-full absolute bottom-[30px] z-[100]">
                <input
                  checked={askDealer}
                  id="askDealer-checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    handleSidebar(e)
                  }}
                  className="w-[30px] h-[30px] hover:border-orange-400 text-orange-400 border-gray-300 rounded focus:ring-orange-400 dark:focus:ring-orange-400 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="askDealer-checkbox"
                  className="ml-2 text-[28px] font-medium text-orange-400 dark:text-orange-400"
                >
                  Ask Dealer
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MainLayout
