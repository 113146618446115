import MODAL_REMAINING from "@assets/modal/bg-remaining.png"
import BTN_BACK from "@assets/modal/btn-back.png"
import BTN_CLOSE from "@assets/modal/btn-close.png"
import BTN_OK from "@assets/modal/btn-ok.png"
import PLAYER from "@assets/modal/players-input.png"
import {
  backAction,
  setDataModalRemaining,
  setDataModalRemainingSubmitted,
  setModalRemainingIsOpen,
} from "@redux/slices/actionsSlice"
import { RootState } from "@redux/store"
import React, { useEffect, useRef, useState } from "react"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"

import { nextAction } from "@redux/slices/actionsSlice"
import { setMetric } from "@redux/slices/metricSlice"
import {
  backCount,
  remainingPlayerErrorCount,
} from "@services/performanceMetricApi"
import { MetricDto } from "@types"
import styles from "./Modal.module.css"

const ModalRemaining = ({ scalding }: { scalding: number }) => {
  const inputPlayerRef = useRef(null)
  const {
    modalRemainingIsOpen,
    dataModalRemaining,
    currentIndex,
    dataModalRemainingSubmitted,
  } = useSelector((state: RootState) => state.actions)
  const { metric } = useSelector((state: RootState) => state.metric)
  const [inputPlayer, setInputPlayer] = useState<number | undefined>(undefined)

  const dispatch = useDispatch()
  const {
    players,
    onBackAction = false,
    modalIndex = 0,
  } = dataModalRemaining ? dataModalRemaining : { players: undefined }

  const submittedInput =
    dataModalRemainingSubmitted.find((e: any) => e.modalIndex === modalIndex) ||
    undefined
  // @ts-ignore:next-line
  const previousInputPlayers = submittedInput?.inputPlayers || undefined

  useEffect(() => {
    if (previousInputPlayers) {
      setInputPlayer(previousInputPlayers)
    }
  }, [previousInputPlayers])

  const onCloseModal = async (back: boolean) => {
    if (back) {
      console.log('back on modal')
      backCount((metric as MetricDto).id)
        .then((data) => {
          console.log("backend response backCount", data)
          dispatch(setMetric(data))
        })
        .catch((error) => {
          console.error(error)
        })
    }

    dispatch(setModalRemainingIsOpen(false))
    if (!onBackAction) {
      dispatch(backAction(currentIndex - 1))
    }
    dispatch(
      setDataModalRemaining({
        players: 0,
        onBackAction: false,
      }),
    )
  }
  const onOk = async () => {
    dispatch(setModalRemainingIsOpen(false))
    // @ts-ignore:next-line
    const currentUserPlayer = inputPlayerRef?.current?.value

    setInputPlayer(currentUserPlayer)

    if (Number(players) === Number(currentUserPlayer)) {
      console.log("-- User answered correctly ")
      // if (onBackAction) {
      //   const data = await backAnswer((metric as MetricDto).id, "remaining")
      //   console.log("backend response backAnswer", data)
      //   dispatch(setMetric(data))
      // }
    } else {
      console.log("-- User answered incorrectly ")

      if (onBackAction) {
        if (Number(previousInputPlayers) !== Number(currentUserPlayer)) {
          console.log("-- different with previous")
          const data = await remainingPlayerErrorCount((metric as MetricDto).id)
          console.log("backend response data", data)
          dispatch(setMetric(data))
        }
      } else {
        if (Number(previousInputPlayers) !== Number(currentUserPlayer)) {
          const data = await remainingPlayerErrorCount((metric as MetricDto).id)
          console.log("backend response data", data)
          dispatch(setMetric(data))
        } else {
          console.log("--- User re-anwser with same input")
        }
      }
    }

    dispatch(
      setDataModalRemaining({
        players: 0,
        onBackAction: false,
      }),
    )

    // store user submitted
    let newSubmittedList = [...dataModalRemainingSubmitted]
    const modalIndexFound = newSubmittedList.find(
      (i: any) => i.modalIndex === modalIndex,
    )
    // console.log('modalIndexFound', modalIndexFound)

    if (modalIndexFound) {
      newSubmittedList.splice(newSubmittedList.indexOf(modalIndexFound), 1)
    }

    newSubmittedList = newSubmittedList.concat({
      modalIndex: modalIndex,
      inputPlayers: currentUserPlayer,
    })

    console.log("newSubmittedList", newSubmittedList)

    dispatch(setDataModalRemainingSubmitted(newSubmittedList))

    if (onBackAction) {
      // go to next action after re-anwser on back
      dispatch(nextAction(currentIndex + 1))
    }
  }
  Modal.setAppElement("#root")
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "700px",
      overflow: "hidden",
      zoom: scalding,
    },
    overlay: {
      zIndex: 100000,
    },
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modalRemainingIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            fontFamily: "Lato",
          }}
          className="relative w-[100%] text-white"
          id="popupModal"
        >
          <img src={MODAL_REMAINING} alt="MODAL_REMAINING" />
          <img
            onClick={() => { onCloseModal(false) }}
            src={BTN_CLOSE}
            alt="BTN_CLOSE"
            className="w-[40px] absolute top-[3px] right-[33px] cursor-pointer"
          />
          <div className="w-full cursor-pointer absolute top-[113px] flex justify-center items-end">
            <img
              src={PLAYER}
              alt="PLAYER"
              className=" w-[210px] h-[48px] mr-5"
            />
            <img
              src={BTN_OK}
              alt="BTN_OK"
              className="w-[120px] cursor-pointer"
              onClick={onOk}
              id="popupNextBtn"
            />
          </div>
          <form
            action=""
            onSubmit={(event) => {
              event.preventDefault()
            }}
          >
            <div className="absolute top-[120px] left-[175px] font-bold text-[18px] w-[66px] text-center">
              <input
                ref={inputPlayerRef}
                type="number"
                name="InputPlayer"
                className={`bg-transparent font-bold text-[18px] w-[67px] h-[44px] p-1 border-transparent text-center ${styles.inputOutline}`}
                value={previousInputPlayers ? inputPlayer : undefined}
                onChange={(e) => {
                  setInputPlayer(Number(e.target.value))
                }}
                autoFocus
              />
            </div>
          </form>
          <div className="absolute top-[250px] w-full">
            <img
              src={BTN_BACK}
              alt="BTN_BACK"
              className="w-[120px] cursor-pointer mx-auto"
              onClick={() => { onCloseModal(true) }}
              id="popupBackBtn"
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ModalRemaining
