import SingleCard from "@components/Card/SingleCard"
import { DURATION_DEFAULT } from "@constants/Actions"
import { SINGLE_CARD_POSITION } from "@constants/SingleCard"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect } from "react"

export type ActionDPros = {
  translateX: number
  translateY: number
  rotate: number
  targets: string
  control?: boolean
  delay?: number
  className?: string
  animationRef?: any
  type?: string
  duration?: number
}

const ActionD = (props: ActionDPros) => {
  const {
    control = false,
    translateX,
    translateY,
    rotate,
    targets,
    delay = 0,
    className = "",
    animationRef,
    duration,
  } = props
  useEffect(() => {
    animationRef.current = anime.timeline({
      autoplay: true,
    })
    animationRef.current.add({
      targets: `.${targets}`,
      translateX: translateX,
      translateY: translateY,
      rotate: rotate,
      easing: "easeInOutQuad",
      delay: delay,
      duration: duration !== undefined ? duration : DURATION_DEFAULT,
    })
  }, [animationRef, delay, duration, rotate, targets, translateX, translateY])
  useEffect(() => {
    if (animationRef && animationRef.current) {
      if (control) {
        animationRef.current.play()
      }
      if (!control && animationRef.current.completed) {
        animationRef.current.reverse()
      }
    }
  }, [animationRef, control])
  return (
    <React.Fragment>
      <SingleCard
        style={{
          top: `${SINGLE_CARD_POSITION.DEFAULT.TOP}px`,
          left: `${SINGLE_CARD_POSITION.DEFAULT.LEFT}px`,
        }}
        className={`absolute w-[140px] ${className} ${targets}`}
      />
    </React.Fragment>
  )
}

export default ActionD
