import anime from "animejs"
import React, { useEffect } from "react"

export type ActionDropRakePros = {
  targets: string
  animationRef?: any
}

const ActionDropChip = (props: ActionDropRakePros) => {
  const { animationRef, targets } = props

  useEffect(() => {
    animationRef.current = anime({
      autoplay: true,
      targets: targets,
      opacity: 0,
      easing: "easeInOutQuad",
      duration: 50,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <React.Fragment></React.Fragment>
}

export default ActionDropChip
