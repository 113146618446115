import MODAL_POT from "@assets/modal/bg-pot.png"
import MODAL_POT_V2 from "@assets/modal/bg-pot-v2.png"
import MODAL_SIDE_POT from "@assets/modal/bg-side-pot.png"
import BTN_BACK from "@assets/modal/btn-back.png"
import BTN_CLOSE from "@assets/modal/btn-close.png"
import BTN_OK from "@assets/modal/btn-ok.png"
import {
  backAction,
  setDataModalCalculate,
  setDataModalCalculateSubmitted,
  setModalCalculateIsOpen,
} from "@redux/slices/actionsSlice"
import { RootState } from "@redux/store"
import React, { useEffect, useRef, useState } from "react"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"

import { nextAction } from "@redux/slices/actionsSlice"
import { setMetric } from "@redux/slices/metricSlice"
import {
  backCount,
  potAndSidePotErrorCount,
  potErrorCount,
  rakeErrorCount,
} from "@services/performanceMetricApi"
import { MetricDto } from "@types"
import styles from "./Modal.module.css"

const ModalCalculate = ({ scalding }: { scalding: number }) => {
  const inputPotRef = useRef(null)
  const inputRakeRef = useRef(null)
  const inputSidePotRef = useRef(null)
  const {
    modalCalculateIsOpen,
    dataModalCalculate,
    currentIndex,
    dataModalCalculateSubmitted,
  } = useSelector((state: RootState) => state.actions)
  const { metric } = useSelector((state: RootState) => state.metric)
  const [inputPot, setInputPot] = useState<number | undefined>(undefined)
  const [inputRake, setInputRake] = useState<number | undefined>(undefined)
  const [inputSidePot, setInputSidePot] = useState<number | undefined>(
    undefined,
  )
  const [background, setBackground] = useState<string>(MODAL_POT)

  const dispatch = useDispatch()
  const {
    potNumber,
    rakeNumber,
    sidePotNumber,
    onBackAction = false,
    modalIndex = 0,
    hasRakeNumber = true,
  } = dataModalCalculate
    ? dataModalCalculate
    : { potNumber: undefined, rakeNumber: undefined, sidePotNumber: undefined }

  const submittedInput =
    dataModalCalculateSubmitted.find((e: any) => e.modalIndex === modalIndex) ||
    undefined
  // @ts-ignore:next-line
  const previousInputPot = submittedInput?.inputPot || undefined
  // @ts-ignore:next-line
  const previousInputSidePot = submittedInput?.inputSidePot || undefined
  // @ts-ignore:next-line
  const previousInputRake = submittedInput?.inputRake || undefined

  useEffect(() => {
    if (previousInputPot) {
      setInputPot(previousInputPot)
    }
    if (previousInputSidePot) {
      setInputSidePot(previousInputSidePot)
    }
    if (previousInputRake) {
      setInputRake(previousInputRake)
    }
  }, [previousInputPot, previousInputSidePot, previousInputRake])

  const onCloseModal = async (back: boolean) => {
    if (back) {
      console.log('back on modal')
      backCount((metric as MetricDto).id)
        .then((data) => {
          console.log("backend response backCount", data)
          dispatch(setMetric(data))
        })
        .catch((error) => {
          console.error(error)
        })
    }
    dispatch(setModalCalculateIsOpen(false))
    if (!onBackAction) {
      dispatch(backAction(currentIndex - 1))
    }
    dispatch(
      setDataModalCalculate({
        potNumber: 0,
        rakeNumber: 0,
        sidePotNumber: 0,
        onBackAction: false,
        hasRakeNumber: true,
      }),
    )
  }

  const onOk = async () => {
    console.log(
      `potNumber ${potNumber} rakeNumber ${rakeNumber} sidePotNumber ${sidePotNumber}`,
    )

    // @ts-ignore:next-line
    const currentUserPot = inputPotRef?.current?.value
    // @ts-ignore:next-line
    const currentUserRake = inputRakeRef?.current?.value
    // @ts-ignore:next-line
    const currentUserSidePot = inputSidePotRef?.current?.value

    setInputPot(currentUserPot)
    setInputSidePot(currentUserSidePot)
    setInputRake(currentUserRake)

    dispatch(setModalCalculateIsOpen(false))

    console.log(
      `currentUserPot ${currentUserPot} - currentUserSidePot ${currentUserSidePot} - currentUserRake ${currentUserRake}`,
    )

    // Handle for Main Pot & Side Pot
    if (sidePotNumber) {
      if (
        Number(potNumber) === Number(currentUserPot) &&
        Number(sidePotNumber) === Number(currentUserSidePot)
      ) {
        console.log("Correctly for both Pot & Side")
        // if (onBackAction) {
        //   const data = await backAnswer(
        //     (metric as MetricDto).id,
        //     "pot_and_side",
        //   )
        //   console.log("backAnswer", data)
        //   dispatch(setMetric(data))
        // }
      } else {
        if (
          Number(potNumber) !== Number(currentUserPot) &&
          Number(sidePotNumber) !== Number(currentUserSidePot)
        ) {
          console.log("Incorrectly for both Pot & Side")

          if (onBackAction) {
            console.log("-- onBack")
            console.log("-- checking with previous input ")

            if (
              Number(previousInputPot) !== Number(currentUserPot) ||
              Number(previousInputSidePot) !== Number(currentUserSidePot)
            ) {
              console.log("-- different with previous")
              const data = await potAndSidePotErrorCount(
                (metric as MetricDto).id,
              )
              console.log(">>> potAndSidePotErrorCount", data)
              dispatch(setMetric(data))
            }
          } else {
            if (
              Number(previousInputPot) !== Number(currentUserPot) ||
              Number(previousInputSidePot) !== Number(currentUserSidePot)
            ) {
              console.log("--- User re-anwser with different input")
              const data = await potAndSidePotErrorCount(
                (metric as MetricDto).id,
              )
              console.log(">>> potAndSidePotErrorCount", data)
              dispatch(setMetric(data))
            } else {
              console.log("--- User re-anwser with same input")
            }
          }
        } else {
          if (
            (Number(potNumber) !== Number(currentUserPot) &&
              Number(sidePotNumber) === Number(currentUserSidePot)) ||
            (Number(potNumber) === Number(currentUserPot) &&
              Number(sidePotNumber) !== Number(currentUserSidePot))
          ) {
            console.log("Incorrectly either Pot or Side")

            if (onBackAction) {
              if (
                (Number(previousInputPot) !== Number(currentUserPot) &&
                  Number(previousInputSidePot) ===
                    Number(currentUserSidePot)) ||
                (Number(previousInputPot) === Number(currentUserPot) &&
                  Number(previousInputSidePot) !== Number(currentUserSidePot))
              ) {
                console.log("-- different with previous")
                const data = await potErrorCount((metric as MetricDto).id)
                console.log(">>> potErrorCount", data)
                dispatch(setMetric(data))
              }
            } else {
              if (
                (Number(previousInputPot) !== Number(currentUserPot) &&
                  Number(previousInputSidePot) ===
                    Number(currentUserSidePot)) ||
                (Number(previousInputPot) === Number(currentUserPot) &&
                  Number(previousInputSidePot) !== Number(currentUserSidePot))
              ) {
                const data = await potErrorCount((metric as MetricDto).id)
                console.log(">>> potErrorCount", data)
                dispatch(setMetric(data))
              } else {
                console.log("--- User re-anwser with same input")
              }
            }
          }
        }
      }
    } else {
      // in case modal only have main Pot
      if (Number(potNumber) === Number(currentUserPot)) {
        console.log("Correctly for Main Pot")
        // if (onBackAction) {
        //   const data = await backAnswer((metric as MetricDto).id, "pot")
        //   console.log("backAnswer", data)
        //   dispatch(setMetric(data))
        // }
      } else {
        console.log("Incorrectly for Main Pot")

        if (onBackAction) {
          if (Number(previousInputPot) !== Number(currentUserPot)) {
            console.log("-- different with previous")
            const data = await potErrorCount((metric as MetricDto).id)
            console.log(">>>> potErrorCount", data)
            dispatch(setMetric(data))
          }
        } else {
          if (Number(previousInputPot) !== Number(currentUserPot)) {
            const data = await potErrorCount((metric as MetricDto).id)
            console.log(">>>> potErrorCount", data)
            dispatch(setMetric(data))
          } else {
            console.log("--- User re-anwser with same input")
          }
        }
      }
    }
    // Handle for Rake
    if (hasRakeNumber) {
      if (Number(rakeNumber) === Number(currentUserRake)) {
        console.log("Correctly for Rake")
        // if (onBackAction) {
        //   const data = await backAnswer((metric as MetricDto).id, "rake")
        //   console.log("backend response backAnswer", data)
        //   dispatch(setMetric(data))
        // }
      } else {
        console.log("Incorrectly for Rake")

        if (onBackAction) {
          if (Number(previousInputRake) !== Number(currentUserRake)) {
            console.log("-- different with previous")
            const data = await rakeErrorCount((metric as MetricDto).id)
            console.log(">>>> rakeErrorCount", data)
            dispatch(setMetric(data))
          }
        } else {
          if (Number(previousInputRake) !== Number(currentUserRake)) {
            const data = await rakeErrorCount((metric as MetricDto).id)
            console.log(">>>> rakeErrorCount", data)
            dispatch(setMetric(data))
          } else {
            console.log("-- User re-anwser with same input")
          }
        }
      }
    }

    dispatch(
      setDataModalCalculate({
        potNumber: 0,
        rakeNumber: 0,
        sidePotNumber: 0,
        onBackAction: false,
        hasRakeNumber: true,
      }),
    )

    // store user submitted
    let newSubmittedList = [...dataModalCalculateSubmitted]
    const modalIndexFound = newSubmittedList.find(
      (i: any) => i.modalIndex === modalIndex,
    )
    // console.log('modalIndexFound', modalIndexFound)

    if (modalIndexFound) {
      newSubmittedList.splice(newSubmittedList.indexOf(modalIndexFound), 1)
    }

    newSubmittedList = newSubmittedList.concat({
      modalIndex: modalIndex,
      inputPot: currentUserPot,
      inputSidePot: currentUserSidePot,
      inputRake: currentUserRake,
    })

    console.log("newSubmittedList", newSubmittedList)

    dispatch(setDataModalCalculateSubmitted(newSubmittedList))

    if (onBackAction) {
      // go to next action after re-anwser on back
      dispatch(nextAction(currentIndex + 1))
    }
  }
  Modal.setAppElement("#root")
  useEffect(() => {
    if (sidePotNumber) {
      setBackground(MODAL_SIDE_POT)
    } else if (!hasRakeNumber) {
      setBackground(MODAL_POT_V2)
    } else {
      setBackground(MODAL_POT)
    }
  }, [sidePotNumber, hasRakeNumber])
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "380px",
      overflow: "hidden",
      zoom: scalding,
    },
    overlay: {
      zIndex: 100000,
    },
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modalCalculateIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            fontFamily: "Lato",
          }}
          className="relative w-[100%] text-white"
          id="popupModal"
        >
          <img src={background} alt="MODAL_POT" />
          <img
            onClick={() => { onCloseModal(false) }}
            src={BTN_CLOSE}
            alt="BTN_CLOSE"
            className="w-[40px] absolute top-[250px] right-[30px] cursor-pointer"
          />
          <div className="w-full cursor-pointer absolute top-[113px] "></div>
          {sidePotNumber ? (
            <form action="">
              <div className="absolute top-[308px] left-[196px] font-bold text-[18px]  text-center">
                <input
                  ref={inputPotRef}
                  type="number"
                  name="pot"
                  className={`bg-transparent w-[64px] h-[42px] p-1 border-transparent text-center ${styles.inputOutline}`}
                  value={previousInputPot ? inputPot : undefined}
                  onChange={(e) => {
                    setInputPot(Number(e.target.value))
                  }}
                  autoFocus
                />
              </div>
              <div className="absolute top-[364px] left-[196px] font-bold text-[18px] text-center">
                <input
                  ref={inputSidePotRef}
                  type="number"
                  name="sidePot"
                  className={`bg-transparent w-[64px] h-[42px] p-1 border-transparent text-center ${styles.inputOutline}`}
                  value={previousInputSidePot ? inputSidePot : undefined}
                  onChange={(e) => {
                    setInputSidePot(Number(e.target.value))
                  }}
                />
              </div>
              <div className="absolute top-[421px] left-[196px] font-bold text-[18px] text-center">
                <input
                  ref={inputRakeRef}
                  type="number"
                  name="rake"
                  className={`bg-transparent w-[64px] h-[42px] p-1 border-transparent text-center ${styles.inputOutline}`}
                  value={previousInputRake ? inputRake : undefined}
                  onChange={(e) => {
                    setInputRake(Number(e.target.value))
                  }}
                />
              </div>
            </form>
          ) : (
            <form
              action=""
              onSubmit={(event) => {
                event.preventDefault()
              }}
            >
              {hasRakeNumber ? (
                <>
                  <div className="absolute top-[10px] text-[20px] w-full text-center">
                    <p>How much is currently in the Pot?</p>
                    <p>What is the current Rake?</p>
                  </div>
                  <div className="absolute top-[312px] left-[50px] font-bold text-[20px]">
                    <p>Pot:</p>
                  </div>
                  <div className="absolute top-[308px] left-[158px] font-bold text-[18px]  text-center">
                    <input
                      ref={inputPotRef}
                      type="number"
                      name="pot"
                      className={`bg-transparent w-[186px] h-[42px] p-1 border-transparent text-center ${styles.inputOutline}`}
                      value={previousInputPot ? inputPot : undefined}
                      onChange={(e) => {
                        setInputPot(Number(e.target.value))
                      }}
                      autoFocus
                    />
                  </div>
                  <div className="absolute top-[372px] left-[50px] font-bold text-[20px]">
                    <p>Rake:</p>
                  </div>
                  <div className="absolute top-[368px] left-[158px] font-bold text-[18px] text-center">
                    <input
                      ref={inputRakeRef}
                      type="number"
                      name="rake"
                      className={`bg-transparent w-[186px] h-[42px] p-1 border-transparent text-center ${styles.inputOutline}`}
                      value={previousInputRake ? inputRake : undefined}
                      onChange={(e) => {
                        setInputRake(Number(e.target.value))
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="absolute top-[10px] text-[20px] w-full text-center">
                    <p>How much is currently in the Pot?</p>
                  </div>
                  <div className="absolute top-[329px] left-[50px] font-bold text-[20px]">
                    <p>Pot:</p>
                  </div>
                  <div className="absolute top-[325px] left-[153px] font-bold text-[18px]  text-center">
                    <input
                      ref={inputPotRef}
                      type="number"
                      name="pot"
                      className={`bg-transparent w-[186px] h-[42px] p-1 border-transparent text-center ${styles.inputOutline}`}
                      value={previousInputPot ? inputPot : undefined}
                      onChange={(e) => {
                        setInputPot(Number(e.target.value))
                      }}
                      autoFocus
                    />
                  </div>
                </>
              )}
            </form>
          )}

          <div className="absolute top-[500px] w-full flex justify-center items-end">
            <img
              src={BTN_BACK}
              alt="BTN_BACK"
              className="w-[120px] cursor-pointer"
              onClick={() => { onCloseModal(true) }}
              id="popupBackBtn"
            />
            <img
              src={BTN_OK}
              alt="BTN_OK"
              className="w-[120px] cursor-pointer"
              onClick={onOk}
              id="popupNextBtn"
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ModalCalculate
