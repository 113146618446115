// C
import C_A from "@assets/cards/C/A.png"
import C_2 from "@assets/cards/C/2.png"
import C_3 from "@assets/cards/C/3.png"
import C_4 from "@assets/cards/C/4.png"
import C_5 from "@assets/cards/C/5.png"
import C_6 from "@assets/cards/C/6.png"
import C_7 from "@assets/cards/C/7.png"
import C_8 from "@assets/cards/C/8.png"
import C_9 from "@assets/cards/C/9.png"
import C_10 from "@assets/cards/C/10.png"
import C_J from "@assets/cards/C/J.png"
import C_Q from "@assets/cards/C/Q.png"
import C_K from "@assets/cards/C/K.png"

// H
import H_A from "@assets/cards/H/A.png"
import H_2 from "@assets/cards/H/2.png"
import H_3 from "@assets/cards/H/3.png"
import H_4 from "@assets/cards/H/4.png"
import H_5 from "@assets/cards/H/5.png"
import H_6 from "@assets/cards/H/6.png"
import H_7 from "@assets/cards/H/7.png"
import H_8 from "@assets/cards/H/8.png"
import H_9 from "@assets/cards/H/9.png"
import H_10 from "@assets/cards/H/10.png"
import H_J from "@assets/cards/H/J.png"
import H_Q from "@assets/cards/H/Q.png"
import H_K from "@assets/cards/H/K.png"

//D
import D_A from "@assets/cards/D/A.png"
import D_2 from "@assets/cards/D/2.png"
import D_3 from "@assets/cards/D/3.png"
import D_4 from "@assets/cards/D/4.png"
import D_5 from "@assets/cards/D/5.png"
import D_6 from "@assets/cards/D/6.png"
import D_7 from "@assets/cards/D/7.png"
import D_8 from "@assets/cards/D/8.png"
import D_9 from "@assets/cards/D/9.png"
import D_10 from "@assets/cards/D/10.png"
import D_J from "@assets/cards/D/J.png"
import D_Q from "@assets/cards/D/Q.png"
import D_K from "@assets/cards/D/K.png"

//S
import S_A from "@assets/cards/S/A.png"
import S_2 from "@assets/cards/S/2.png"
import S_3 from "@assets/cards/S/3.png"
import S_4 from "@assets/cards/S/4.png"
import S_5 from "@assets/cards/S/5.png"
import S_6 from "@assets/cards/S/6.png"
import S_7 from "@assets/cards/S/7.png"
import S_8 from "@assets/cards/S/8.png"
import S_9 from "@assets/cards/S/9.png"
import S_10 from "@assets/cards/S/10.png"
import S_J from "@assets/cards/S/J.png"
import S_Q from "@assets/cards/S/Q.png"
import S_K from "@assets/cards/S/K.png"

export const SINGLE_CARD_POSITION: any = {
  DEFAULT: {
    TOP: 430,
    LEFT: 318,
  },
  // stack position of cards
  STACK_POSITION: {
    ROTATE: 360,
    X: -28,
    Y: -40,
  },
  pot_limit_omaha: {
    // PLAYER 1
    PLAYER_1_1: {
      ROTATE: -360,
      X: -90,
      Y: 30,
    },
    PLAYER_1_2: {
      ROTATE: -360,
      X: -96,
      Y: 30,
    },
    PLAYER_1_3: {
      ROTATE: -360,
      X: -102,
      Y: 30,
    },
    PLAYER_1_4: {
      ROTATE: -360,
      X: -108,
      Y: 30,
    },
    // PLAYER 2
    PLAYER_2_1: {
      ROTATE: -310,
      X: -192,
      Y: -12,
    },
    PLAYER_2_2: {
      ROTATE: -310,
      X: -196,
      Y: -16,
    },
    PLAYER_2_3: {
      ROTATE: -310,
      X: -200,
      Y: -20,
    },
    PLAYER_2_4: {
      ROTATE: -310,
      X: -204,
      Y: -24,
    },
    // PLAYER 3
    PLAYER_3_1: {
      ROTATE: -410,
      X: -190,
      Y: -148,
    },
    PLAYER_3_2: {
      ROTATE: -410,
      X: -186,
      Y: -152,
    },
    PLAYER_3_3: {
      ROTATE: -410,
      X: -182,
      Y: -156,
    },
    PLAYER_3_4: {
      ROTATE: -410,
      X: -178,
      Y: -160,
    },
    // PLAYER 4
    PLAYER_4_1: {
      ROTATE: -360,
      X: -77, // -35
      Y: -200,
    },
    PLAYER_4_2: {
      ROTATE: -360,
      X: -71,
      Y: -200,
    },
    PLAYER_4_3: {
      ROTATE: -360,
      X: -65,
      Y: -200,
    },
    PLAYER_4_4: {
      ROTATE: -360,
      X: -59,
      Y: -200,
    },
    // PLAYER 5
    PLAYER_5_1: {
      ROTATE: -360,
      X: 107, //+12
      Y: -200,
    },
    PLAYER_5_2: {
      ROTATE: -360,
      X: 113,
      Y: -200,
    },
    PLAYER_5_3: {
      ROTATE: -360,
      X: 120,
      Y: -200,
    },
    PLAYER_5_4: {
      ROTATE: -360,
      X: 127,
      Y: -200,
    },
    // PLAYER 6
    PLAYER_6_1: {
      ROTATE: -360,
      X: 286,
      Y: -200,
    },
    PLAYER_6_2: {
      ROTATE: -360,
      X: 292,
      Y: -200,
    },
    PLAYER_6_3: {
      ROTATE: -360,
      X: 298,
      Y: -200,
    },
    PLAYER_6_4: {
      ROTATE: -360,
      X: 304,
      Y: -200,
    },
    // PLAYER 7
    PLAYER_7_1: {
      ROTATE: -315,
      X: 406,
      Y: -166,
    },
    PLAYER_7_2: {
      ROTATE: -315,
      X: 410,
      Y: -162,
    },
    PLAYER_7_3: {
      ROTATE: -315,
      X: 414,
      Y: -158,
    },
    PLAYER_7_4: {
      ROTATE: -315,
      X: 418,
      Y: -154,
    },
    // PLAYER 8
    PLAYER_8_1: {
      ROTATE: -414,
      X: 442,
      Y: -18,
    },
    PLAYER_8_2: {
      ROTATE: -414,
      X: 439,
      Y: -14,
    },
    PLAYER_8_3: {
      ROTATE: -414,
      X: 436,
      Y: -10,
    },
    PLAYER_8_4: {
      ROTATE: -414,
      X: 432,
      Y: -6,
    },
    // PLAYER 9
    PLAYER_9_1: {
      ROTATE: -362,
      X: 346,
      Y: 30,
    },
    PLAYER_9_2: {
      ROTATE: -362,
      X: 340,
      Y: 30,
    },
    PLAYER_9_3: {
      ROTATE: -362,
      X: 334,
      Y: 30,
    },
    PLAYER_9_4: {
      ROTATE: -362,
      X: 328,
      Y: 30,
    },
  },
  no_limit_hold_em: {
    // PLAYER 1
    PLAYER_1_1: {
      ROTATE: -360,
      X: -102,
      Y: 30,
    },
    PLAYER_1_2: {
      ROTATE: -360,
      X: -108,
      Y: 30,
    },
    // PLAYER 2
    PLAYER_2_1: {
      ROTATE: -310,
      X: -196,
      Y: -16,
    },
    PLAYER_2_2: {
      ROTATE: -310,
      X: -200,
      Y: -20,
    },
    // PLAYER 3
    PLAYER_3_1: {
      ROTATE: -410,
      X: -186,
      Y: -152,
    },
    PLAYER_3_2: {
      ROTATE: -410,
      X: -182,
      Y: -156,
    },
    // PLAYER 4
    PLAYER_4_1: {
      ROTATE: -360,
      X: -71,
      Y: -200,
    },
    PLAYER_4_2: {
      ROTATE: -360,
      X: -65,
      Y: -200,
    },
    // PLAYER 5
    PLAYER_5_1: {
      ROTATE: -360,
      X: 113,
      Y: -200,
    },
    PLAYER_5_2: {
      ROTATE: -360,
      X: 120,
      Y: -200,
    },
    // PLAYER 6
    PLAYER_6_1: {
      ROTATE: -360,
      X: 296,
      Y: -200,
    },
    PLAYER_6_2: {
      ROTATE: -360,
      X: 302,
      Y: -200,
    },
    // PLAYER 7
    PLAYER_7_1: {
      ROTATE: -315,
      X: 410,
      Y: -162,
    },
    PLAYER_7_2: {
      ROTATE: -315,
      X: 416,
      Y: -156,
    },
    // PLAYER 8
    PLAYER_8_1: {
      ROTATE: -414,
      X: 439,
      Y: -14,
    },
    PLAYER_8_2: {
      ROTATE: -414,
      X: 436,
      Y: -10,
    },
    // PLAYER 9
    PLAYER_9_1: {
      ROTATE: -362,
      X: 340,
      Y: 30,
    },
    PLAYER_9_2: {
      ROTATE: -362,
      X: 334,
      Y: 30,
    },
  },
  plo_tournament: {
    // PLAYER 1
    PLAYER_1_1: {
      ROTATE: -360,
      X: -90,
      Y: 30,
    },
    PLAYER_1_2: {
      ROTATE: -360,
      X: -96,
      Y: 30,
    },
    PLAYER_1_3: {
      ROTATE: -360,
      X: -102,
      Y: 30,
    },
    PLAYER_1_4: {
      ROTATE: -360,
      X: -108,
      Y: 30,
    },
    // PLAYER 2
    PLAYER_2_1: {
      ROTATE: -310,
      X: -192,
      Y: -12,
    },
    PLAYER_2_2: {
      ROTATE: -310,
      X: -196,
      Y: -16,
    },
    PLAYER_2_3: {
      ROTATE: -310,
      X: -200,
      Y: -20,
    },
    PLAYER_2_4: {
      ROTATE: -310,
      X: -204,
      Y: -24,
    },
    // PLAYER 3
    PLAYER_3_1: {
      ROTATE: -410,
      X: -190,
      Y: -148,
    },
    PLAYER_3_2: {
      ROTATE: -410,
      X: -186,
      Y: -152,
    },
    PLAYER_3_3: {
      ROTATE: -410,
      X: -182,
      Y: -156,
    },
    PLAYER_3_4: {
      ROTATE: -410,
      X: -178,
      Y: -160,
    },
    // PLAYER 4
    PLAYER_4_1: {
      ROTATE: -360,
      X: -77, // -35
      Y: -200,
    },
    PLAYER_4_2: {
      ROTATE: -360,
      X: -71,
      Y: -200,
    },
    PLAYER_4_3: {
      ROTATE: -360,
      X: -65,
      Y: -200,
    },
    PLAYER_4_4: {
      ROTATE: -360,
      X: -59,
      Y: -200,
    },
    // PLAYER 5
    PLAYER_5_1: {
      ROTATE: -360,
      X: 107, //+12
      Y: -200,
    },
    PLAYER_5_2: {
      ROTATE: -360,
      X: 113,
      Y: -200,
    },
    PLAYER_5_3: {
      ROTATE: -360,
      X: 120,
      Y: -200,
    },
    PLAYER_5_4: {
      ROTATE: -360,
      X: 127,
      Y: -200,
    },
    // PLAYER 6
    PLAYER_6_1: {
      ROTATE: -360,
      X: 286,
      Y: -200,
    },
    PLAYER_6_2: {
      ROTATE: -360,
      X: 292,
      Y: -200,
    },
    PLAYER_6_3: {
      ROTATE: -360,
      X: 298,
      Y: -200,
    },
    PLAYER_6_4: {
      ROTATE: -360,
      X: 304,
      Y: -200,
    },
    // PLAYER 7
    PLAYER_7_1: {
      ROTATE: -315,
      X: 406,
      Y: -166,
    },
    PLAYER_7_2: {
      ROTATE: -315,
      X: 410,
      Y: -162,
    },
    PLAYER_7_3: {
      ROTATE: -315,
      X: 414,
      Y: -158,
    },
    PLAYER_7_4: {
      ROTATE: -315,
      X: 418,
      Y: -154,
    },
    // PLAYER 8
    PLAYER_8_1: {
      ROTATE: -414,
      X: 442,
      Y: -18,
    },
    PLAYER_8_2: {
      ROTATE: -414,
      X: 439,
      Y: -14,
    },
    PLAYER_8_3: {
      ROTATE: -414,
      X: 436,
      Y: -10,
    },
    PLAYER_8_4: {
      ROTATE: -414,
      X: 432,
      Y: -6,
    },
    // PLAYER 9
    PLAYER_9_1: {
      ROTATE: -362,
      X: 346,
      Y: 30,
    },
    PLAYER_9_2: {
      ROTATE: -362,
      X: 340,
      Y: 30,
    },
    PLAYER_9_3: {
      ROTATE: -362,
      X: 334,
      Y: 30,
    },
    PLAYER_9_4: {
      ROTATE: -362,
      X: 328,
      Y: 30,
    },
  },
}

export const BURN_CARD_POSITION: any = {
  pot_limit_omaha: {
    BURN_POT: {
      X: 180,
      Y: -30,
    },
  },
  no_limit_hold_em: {
    BURN_POT: {
      X: 180,
      Y: -30,
    },
  },
  plo_tournament: {
    BURN_POT: {
      X: 180,
      Y: -30,
    },
  },
}

export const BURN_CARD_V2_POSITION: any = {
  pot_limit_omaha: {
    BURN_POT: {
      X: 14,
      Y: -60,
    },
  },
  no_limit_hold_em: {
    BURN_POT: {
      X: 14,
      Y: -60,
    },
  },
  plo_tournament: {
    BURN_POT: {
      X: 14,
      Y: -60,
    },
  },
}

export const MAIN_CARD_POSITION: any = {
  top: 292,
  left: 384,
}

export const MAIN_CARD_TRANSLATE: any = {
  X: 0,
  Y: -120,
  SCALE: 1.75,
}

export const SRC_CARD: any = {
  C: {
    A: C_A,
    2: C_2,
    3: C_3,
    4: C_4,
    5: C_5,
    6: C_6,
    7: C_7,
    8: C_8,
    9: C_9,
    10: C_10,
    J: C_J,
    Q: C_Q,
    K: C_K,
  },
  H: {
    A: H_A,
    2: H_2,
    3: H_3,
    4: H_4,
    5: H_5,
    6: H_6,
    7: H_7,
    8: H_8,
    9: H_9,
    10: H_10,
    J: H_J,
    Q: H_Q,
    K: H_K,
  },
  D: {
    A: D_A,
    2: D_2,
    3: D_3,
    4: D_4,
    5: D_5,
    6: D_6,
    7: D_7,
    8: D_8,
    9: D_9,
    10: D_10,
    J: D_J,
    Q: D_Q,
    K: D_K,
  },
  S: {
    A: S_A,
    2: S_2,
    3: S_3,
    4: S_4,
    5: S_5,
    6: S_6,
    7: S_7,
    8: S_8,
    9: S_9,
    10: S_10,
    J: S_J,
    Q: S_Q,
    K: S_K,
  },
}

export const CARD_NUMBER = [1, 2, 3, 4]
export const CARD_NUMBER_BOARD = [1, 2, 3, 4, 5]
