export const CARD_POSITION_REVEAL: any = {
  DEFAULT: "top-[234px] left-[680px]",
  // stack position of cards
  STACK_POSITION: {
    ROTATE: 360,
    X: -28,
    Y: -40,
  },
  pot_limit_omaha: {
    // PLAYER 1
    PLAYER_1_1: {
      ROTATE: 0,
      X: 41,
      Y: -10,
    },
    PLAYER_1_2: {
      ROTATE: 0,
      X: 73,
      Y: -10,
    },
    PLAYER_1_3: {
      ROTATE: 0,
      X: 105,
      Y: -10,
    },
    PLAYER_1_4: {
      ROTATE: 0,
      X: 137,
      Y: -10,
    },
    // PLAYER 2
    PLAYER_2_1: {
      ROTATE: 60,
      X: -45,
      Y: -53,
    },
    PLAYER_2_2: {
      ROTATE: 60, //240
      X: -30,
      Y: -26,
    },
    PLAYER_2_3: {
      ROTATE: 60,
      X: -15,
      Y: 1,
    },
    PLAYER_2_4: {
      ROTATE: 60,
      X: 0,
      Y: 28,
    },
    // PLAYER 3
    PLAYER_3_1: {
      ROTATE: 133,
      X: 17, // -199
      Y: 46, // -138
    },
    PLAYER_3_2: {
      ROTATE: 133,
      X: 38, // -173
      Y: 23, // -159
    },
    PLAYER_3_3: {
      ROTATE: 133,
      X: 59, // -148
      Y: 0, // -180
    },
    PLAYER_3_4: {
      ROTATE: 133,
      X: 80, // -122
      Y: -23, // -201
    },
    // PLAYER 4
    PLAYER_4_1: {
      ROTATE: 0,
      X: 5,
      Y: 77,
    },
    PLAYER_4_2: {
      ROTATE: 0,
      X: 37,
      Y: 77,
    },
    PLAYER_4_3: {
      ROTATE: 0,
      X: 69,
      Y: 77,
    },
    PLAYER_4_4: {
      ROTATE: 0,
      X: 101,
      Y: 77,
    },
    // PLAYER 5
    PLAYER_5_1: {
      ROTATE: 0,
      X: -2,
      Y: 77,
    },
    PLAYER_5_2: {
      ROTATE: 0,
      X: 30,
      Y: 77,
    },
    PLAYER_5_3: {
      ROTATE: 0,
      X: 62,
      Y: 77,
    },
    PLAYER_5_4: {
      ROTATE: 0,
      X: 94,
      Y: 77,
    },
    // PLAYER 6
    PLAYER_6_1: {
      ROTATE: 0,
      X: -69,
      Y: 3,
    },
    PLAYER_6_2: {
      ROTATE: 0,
      X: -38,
      Y: 3,
    },
    PLAYER_6_3: {
      ROTATE: 0,
      X: -7,
      Y: 3,
    },
    PLAYER_6_4: {
      ROTATE: 0,
      X: 24,
      Y: 3,
    },
    // PLAYER 7
    PLAYER_7_1: {
      ROTATE: 222,
      X: -3,
      Y: 32,
    },
    PLAYER_7_2: {
      ROTATE: 222, //402
      X: 21,
      Y: 53,
    },
    PLAYER_7_3: {
      ROTATE: 222,
      X: 45,
      Y: 74,
    },
    PLAYER_7_4: {
      ROTATE: 222,
      X: 69,
      Y: 95,
    },
    // PLAYER 8
    PLAYER_8_1: {
      ROTATE: 119,
      X: -18,
      Y: 26,
    },
    PLAYER_8_2: {
      ROTATE: 119,
      X: -3,
      Y: -2,
    },
    PLAYER_8_3: {
      ROTATE: 119, //296
      X: 12,
      Y: -30,
    },
    PLAYER_8_4: {
      ROTATE: 119,
      X: 26,
      Y: -58,
    },
    // PLAYER 9
    PLAYER_9_1: {
      ROTATE: 0,
      X: -2,
      Y: -12,
    },
    PLAYER_9_2: {
      ROTATE: 0,
      X: 30,
      Y: -12,
    },
    PLAYER_9_3: {
      ROTATE: 0,
      X: 62,
      Y: -12,
    },
    PLAYER_9_4: {
      ROTATE: 0,
      X: 94,
      Y: -12,
    },
  },
  no_limit_hold_em: {
    // PLAYER 1
    PLAYER_1_1: {
      ROTATE: 0,
      X: 62,
      Y: -10,
    },
    PLAYER_1_2: {
      ROTATE: 0,
      X: 95,
      Y: -10,
    },
    // PLAYER 2
    PLAYER_2_1: {
      ROTATE: 55,
      X: 2,
      Y: 24,
    },
    PLAYER_2_2: {
      ROTATE: 55, //240
      X: -16,
      Y: -2,
    },
    // PLAYER 3
    PLAYER_3_1: {
      ROTATE: 133,
      X: 24, // -199
      Y: 26, // -138
    },
    PLAYER_3_2: {
      ROTATE: 133,
      X: 46, // -173
      Y: 2, // -159
    },
    // PLAYER 4
    PLAYER_4_1: {
      ROTATE: 0,
      X: 16,
      Y: 77,
    },
    PLAYER_4_2: {
      ROTATE: 0,
      X: 48,
      Y: 77,
    },
    // PLAYER 5
    PLAYER_5_1: {
      ROTATE: 0,
      X: 20,
      Y: 77,
    },
    PLAYER_5_2: {
      ROTATE: 0,
      X: 52,
      Y: 77,
    },
    // PLAYER 6
    PLAYER_6_1: {
      ROTATE: 0,
      X: -22,
      Y: 3,
    },
    PLAYER_6_2: {
      ROTATE: 0,
      X: 10,
      Y: 3,
    },
    // PLAYER 7
    PLAYER_7_1: {
      ROTATE: 222,
      X: 32,
      Y: 50,
    },
    PLAYER_7_2: {
      ROTATE: 222, //402
      X: 56,
      Y: 72,
    },
    // PLAYER 8
    PLAYER_8_1: {
      ROTATE: 119,
      X: -18,
      Y: 26,
    },
    PLAYER_8_2: {
      ROTATE: 119,
      X: -3,
      Y: -2,
    },
    // PLAYER 9
    PLAYER_9_1: {
      ROTATE: 0,
      X: 30,
      Y: -12,
    },
    PLAYER_9_2: {
      ROTATE: 0,
      X: 62,
      Y: -12,
    },
  },
  plo_tournament: {
    // PLAYER 1
    PLAYER_1_1: {
      ROTATE: 0,
      X: 41,
      Y: -10,
    },
    PLAYER_1_2: {
      ROTATE: 0,
      X: 73,
      Y: -10,
    },
    PLAYER_1_3: {
      ROTATE: 0,
      X: 105,
      Y: -10,
    },
    PLAYER_1_4: {
      ROTATE: 0,
      X: 137,
      Y: -10,
    },
    // PLAYER 2
    PLAYER_2_1: {
      ROTATE: 60,
      X: -45,
      Y: -53,
    },
    PLAYER_2_2: {
      ROTATE: 60, //240
      X: -30,
      Y: -26,
    },
    PLAYER_2_3: {
      ROTATE: 60,
      X: -15,
      Y: 1,
    },
    PLAYER_2_4: {
      ROTATE: 60,
      X: 0,
      Y: 28,
    },
    // PLAYER 3
    PLAYER_3_1: {
      ROTATE: 133,
      X: 17, // -199
      Y: 46, // -138
    },
    PLAYER_3_2: {
      ROTATE: 133,
      X: 38, // -173
      Y: 23, // -159
    },
    PLAYER_3_3: {
      ROTATE: 133,
      X: 59, // -148
      Y: 0, // -180
    },
    PLAYER_3_4: {
      ROTATE: 133,
      X: 80, // -122
      Y: -23, // -201
    },
    // PLAYER 4
    PLAYER_4_1: {
      ROTATE: 0,
      X: 5,
      Y: 77,
    },
    PLAYER_4_2: {
      ROTATE: 0,
      X: 37,
      Y: 77,
    },
    PLAYER_4_3: {
      ROTATE: 0,
      X: 69,
      Y: 77,
    },
    PLAYER_4_4: {
      ROTATE: 0,
      X: 101,
      Y: 77,
    },
    // PLAYER 5
    PLAYER_5_1: {
      ROTATE: 0,
      X: -2,
      Y: 77,
    },
    PLAYER_5_2: {
      ROTATE: 0,
      X: 30,
      Y: 77,
    },
    PLAYER_5_3: {
      ROTATE: 0,
      X: 62,
      Y: 77,
    },
    PLAYER_5_4: {
      ROTATE: 0,
      X: 94,
      Y: 77,
    },
    // PLAYER 6
    PLAYER_6_1: {
      ROTATE: 0,
      X: -69,
      Y: 3,
    },
    PLAYER_6_2: {
      ROTATE: 0,
      X: -38,
      Y: 3,
    },
    PLAYER_6_3: {
      ROTATE: 0,
      X: -7,
      Y: 3,
    },
    PLAYER_6_4: {
      ROTATE: 0,
      X: 24,
      Y: 3,
    },
    // PLAYER 7
    PLAYER_7_1: {
      ROTATE: 222,
      X: -3,
      Y: 32,
    },
    PLAYER_7_2: {
      ROTATE: 222, //402
      X: 21,
      Y: 53,
    },
    PLAYER_7_3: {
      ROTATE: 222,
      X: 45,
      Y: 74,
    },
    PLAYER_7_4: {
      ROTATE: 222,
      X: 69,
      Y: 95,
    },
    // PLAYER 8
    PLAYER_8_1: {
      ROTATE: 119,
      X: -18,
      Y: 26,
    },
    PLAYER_8_2: {
      ROTATE: 119,
      X: -3,
      Y: -2,
    },
    PLAYER_8_3: {
      ROTATE: 119, //296
      X: 12,
      Y: -30,
    },
    PLAYER_8_4: {
      ROTATE: 119,
      X: 26,
      Y: -58,
    },
    // PLAYER 9
    PLAYER_9_1: {
      ROTATE: 0,
      X: -2,
      Y: -12,
    },
    PLAYER_9_2: {
      ROTATE: 0,
      X: 30,
      Y: -12,
    },
    PLAYER_9_3: {
      ROTATE: 0,
      X: 62,
      Y: -12,
    },
    PLAYER_9_4: {
      ROTATE: 0,
      X: 94,
      Y: -12,
    },
  },
}
