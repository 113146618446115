import axios from "../config/axiosInstance"

export async function fetchMetric(metric_id: any) {
  try {
    const response = await axios.get(`/performance_metrics/${metric_id}`)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function setStartTime(exercise_id?: any, gameMode?: string) {
  try {
    let response
    if (exercise_id) {
      response = await axios.post(
        `/performance_metrics/set_start_time?exercise_id=${exercise_id}&mode_type=${gameMode}`,
      )
    } else {
      response = await axios.post(
        `/performance_metrics/set_start_time?mode_type=${gameMode}`,
      )
    }
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function setEndTime(metric_id: any) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/set_end_time`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function backCount(metric_id: any, type?: string) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/back_count?type=${type}`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function backAnswer(metric_id: any, type?: string) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/back_answer?type=${type}`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function betErrorCount(metric_id: any) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/bet_error_count`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function remainingPlayerErrorCount(metric_id: any) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/remaining_player_error_count`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function potErrorCount(metric_id: any) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/pot_error_count`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function rakeErrorCount(metric_id: any) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/rake_error_count`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function potRakeErrorCount(metric_id: any) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/pot_rake_error_count`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function potAndSidePotErrorCount(metric_id: any) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/pot_and_side_error_count`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function promoErrorCount(metric_id: any) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/promo_error_count`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function handReaderErrorCount(metric_id: any) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/hand_error_count`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function winActionErrorCount(metric_id: any) {
  try {
    const response = await axios.post(
      `/performance_metrics/${metric_id}/win_action_error_count`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

