import anime from "animejs/lib/anime.es.js"
import React, { useEffect } from "react"
import { getActionTargets } from "@helpers/commonFunctions"
import { MAIN_CARD_TRANSLATE } from "@constants/SingleCard"

export type ActionShowCardPros = {
  translateX: number
  translateY: number
  scale: number
  targets: any
  control?: boolean
  delay?: number
  animationRef: any
  isInit?: boolean
}

const ActionShowCard = (props: ActionShowCardPros) => {
  const {
    control = false,
    translateX,
    translateY,
    targets,
    delay = 0,
    scale,
    animationRef,
    isInit = false,
  } = props
  useEffect(() => {
    let keyframes = []
    if (isInit) {
      keyframes = [
        {
          opacity: 1,
          duration: 5,
        },
        {
          translateX: MAIN_CARD_TRANSLATE.X,
          translateY: MAIN_CARD_TRANSLATE.Y,
          easing: "linear",
          duration: 300,
          scale: scale,
        },
        {
          translateX: translateX,
          translateY: translateY,
          easing: "easeInOutExpo",
          scale: scale,
          delay: 300,
        },
      ]
    } else {
      keyframes = [
        {
          opacity: 1,
          duration: 5,
        },
        {
          translateX: translateX,
          translateY: translateY,
          easing: "linear",
          scale: scale,
          duration: 300,
        },
      ]
    }

    animationRef.current = anime({
      targets: getActionTargets(targets),
      autoplay: true,
      keyframes: keyframes,
      delay: delay,
    })
  }, [animationRef, delay, isInit, scale, targets, translateX, translateY])

  useEffect(() => {
    if (animationRef && animationRef.current) {
      if (control) {
        animationRef.current.play()
      }
      if (!control && animationRef.current.completed) {
        animationRef.current.reverse()
      }
    }
  }, [animationRef, control])
  return <React.Fragment></React.Fragment>
}

export default ActionShowCard
