import BTN_BACK from "@assets/modal/btn-back.png"
import BTN_CLOSE from "@assets/modal/btn-close.png"
import BTN_OK from "@assets/modal/btn-ok.png"
import {
  backAction,
  setDataModalWinActionSubmitted,
  setModalWinActionIsOpen,
  setDataModalWinAction,
  nextAction,
  setDataWinPlayersSelected,
} from "@redux/slices/actionsSlice"
import { RootState } from "@redux/store"
import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"

import { setMetric } from "@redux/slices/metricSlice"
import { backCount, winActionErrorCount } from "@services/performanceMetricApi"
import { MetricDto } from "@types"
import { compareArrays } from "@helpers/utils"
import { DELAY_DEFAULT, DELAY_BETWEEN_ACTIONS } from "@constants/Actions"

const ModalWinAction = ({ scalding }: { scalding: number }) => {
  const {
    currentIndex,
    modalWinActionIsOpen,
    dataModalWinAction,
    dataModalWinActionSubmitted,
    dataWinPlayersSelected,
    currentDealerPosition,
  } = useSelector((state: RootState) => state.actions)

  const { metric } = useSelector((state: RootState) => state.metric)

  const dispatch = useDispatch()
  const { actionTitle, winPlayers, winPlayersSelection, onBackAction, modalIndex, winPlayersSelected } = dataModalWinAction

  // find previous submitted when on back
  const submittedInput =
    dataModalWinActionSubmitted.find(
      (e: any) => e.modalIndex === modalIndex,
    ) || undefined
  // @ts-ignore:next-line
  const previousWinPlayersSelected = submittedInput?.winPlayersSelected || undefined
  // @ts-ignore:next-line
  const isOnBack = currentIndex === submittedInput?.modalIndex

  useEffect(() => {
    if (isOnBack) {
      // init set dataWinPlayersSelected with previousWinPlayersSelected when onBack
      if (previousWinPlayersSelected) {
        dispatch(setDataWinPlayersSelected(previousWinPlayersSelected))
      }

      // starting highlight reveal cards again
      for (let player in previousWinPlayersSelected) {

        let playerPositionWithDealerButton;

        if (currentDealerPosition == 9) {
          playerPositionWithDealerButton = previousWinPlayersSelected[player]
        } else {
          if ((previousWinPlayersSelected[player] + currentDealerPosition) > 9) {
            playerPositionWithDealerButton = (previousWinPlayersSelected[player] + currentDealerPosition) - 9
          } else {
            playerPositionWithDealerButton = (previousWinPlayersSelected[player] + currentDealerPosition)
          }
        }

        const btnReplayActionHighlight = document.getElementById(
          `replay-action-highlight-reveal-cards-${playerPositionWithDealerButton}`,
        )
        if (btnReplayActionHighlight) {
          btnReplayActionHighlight.click()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnBack])

  const onCloseModal = async (back: boolean) => {
    if (back) {
      backCount((metric as MetricDto).id)
        .then((data) => {
          console.log("backend response backCount", data)
          dispatch(setMetric(data))
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const userAnswered = [...dataWinPlayersSelected].sort()

    dispatch(setModalWinActionIsOpen(false))

    if (!isOnBack) {
      dispatch(backAction(currentIndex - 1))
    }

    dispatch(
      setDataModalWinAction({
        winPlayers: [],
        winPlayersSelection: [],
        onBackAction: false,
        modalIndex: 0
      }),
    )

    dispatch(setDataWinPlayersSelected([]))

    // reserve (turn off) highlight after submitted
    for (let player in userAnswered) {
      let playerPositionWithDealerButton;

      if (currentDealerPosition == 9) {
        playerPositionWithDealerButton = userAnswered[player]
      } else {
        if ((userAnswered[player] + currentDealerPosition) > 9) {
          playerPositionWithDealerButton = (userAnswered[player] + currentDealerPosition) - 9
        } else {
          playerPositionWithDealerButton = (userAnswered[player] + currentDealerPosition)
        }
      }

      const btnReverseActionHighlight = document.getElementById(
        `reverse-action-highlight-reveal-cards-${playerPositionWithDealerButton}`,
      )
      if (btnReverseActionHighlight) {
        btnReverseActionHighlight.click()
      }
    }
  }

  const onOk = async () => {
    dispatch(setModalWinActionIsOpen(false))

    const correctAnswer = winPlayers.map((x:string) => parseInt(x)).sort()
    const userAnswered = [...dataWinPlayersSelected].sort()

    // @ts-ignore:next-line
    if (compareArrays(correctAnswer, userAnswered)) {
      console.log("%c-- User answered correctly ", "color: green")
    } else {
      console.log("%c-- User answered incorrectly ", "color: red")
      if (isOnBack) {
        if (previousWinPlayersSelected) {
          if (!compareArrays(userAnswered, previousWinPlayersSelected)) {
            console.log("%c-- different with previous", "color: red")
            const data = await winActionErrorCount((metric as MetricDto).id)
            console.log("backend response data", data)
            dispatch(setMetric(data))
          } else {
            console.log("%c--- User re-anwser with same input", "color: green")
          }
        } else {
          const data = await winActionErrorCount((metric as MetricDto).id)
          console.log("backend response data", data)
          dispatch(setMetric(data))
        }
      } else {
        const data = await winActionErrorCount((metric as MetricDto).id)
        console.log("backend response data", data)
        dispatch(setMetric(data))
      }
    }

    // store user submitted into Refux
    let newSubmittedList = [...dataModalWinActionSubmitted]
    const modalIndexFound = newSubmittedList.find(
      (i: any) => i.modalIndex === modalIndex,
    )
    if (modalIndexFound) {
      newSubmittedList.splice(newSubmittedList.indexOf(modalIndexFound), 1)
    }
    newSubmittedList = newSubmittedList.concat({
      modalIndex: modalIndex,
      winPlayersSelected: userAnswered,
    })
    dispatch(setDataModalWinActionSubmitted(newSubmittedList))
    dispatch(setDataWinPlayersSelected([]))
    dispatch(
      setDataModalWinAction({
        winPlayers: [],
        winPlayersSelection: [],
        onBackAction: false,
        modalIndex: 0
      }),
    )

    // go to next action after re-anwser on back
    if (isOnBack) {
      dispatch(nextAction(currentIndex + 1))
    }

    // reserve (turn off) highlight after submitted
    for (let player in userAnswered) {

      let playerPositionWithDealerButton;

      if (currentDealerPosition == 9) {
        playerPositionWithDealerButton = userAnswered[player]
      } else {
        if ((userAnswered[player] + currentDealerPosition) > 9) {
          playerPositionWithDealerButton = (userAnswered[player] + currentDealerPosition) - 9
        } else {
          playerPositionWithDealerButton = (userAnswered[player] + currentDealerPosition)
        }
      }

      const btnReverseActionHighlight = document.getElementById(
        `reverse-action-highlight-reveal-cards-${playerPositionWithDealerButton}`,
      )
      if (btnReverseActionHighlight) {
        btnReverseActionHighlight.click()
      }
    }
  }

  Modal.setAppElement("#root")

  const sidebarElement = document.getElementById('handNarrator')

  const customStyles = {
    content: {
      top: "312px",
      left: sidebarElement ? "578px" : "494px",
      right: "auto",
      bottom: "auto",
      padding: "0px",
      width: "220px",
      overflow: "hidden",
      zoom: scalding,
      backgroundColor: "#4C6880",
      zIndex: 102
    },
    overlay: {
      zIndex: 101,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      position: 'unset'
    },
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modalWinActionIsOpen}
        // @ts-ignore:next-line
        style={customStyles}
        contentLabel="Win Action Modal"
        id="modalWinAction"
      >
        <div
          style={{
            fontFamily: "Lato",
          }}
          className="relative w-[100%] text-white h-auto px-4 py-4"
          id="popupModal"
        >
          <img
            onClick={() => { onCloseModal(false) }}
            src={BTN_CLOSE}
            alt="BTN_CLOSE"
            className="w-[23px] absolute top-[6px] right-[16px] cursor-pointer"
          />

          <div
            id="hand-reader-container"
            className="w-full overflow-y-auto mt-4 mb-6 text-[14px]"
          >
            {actionTitle}
            <br/>Selected: {dataWinPlayersSelected.join(', ')}
          </div>

          <div className="flex justify-betwwen w-full overflow-y-auto mt-4">
            <img
              src={BTN_BACK}
              alt="BTN_BACK"
              className="w-[90px] cursor-pointer mx-auto"
              onClick={() => { onCloseModal(true) }}
              id="popupBackBtn"
            />
            <img
              src={BTN_OK}
              alt="BTN_OK"
              className="w-[90px] cursor-pointer mx-auto"
              onClick={onOk}
              id="popupNextBtn"
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ModalWinAction
