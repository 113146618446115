import anime from "animejs/lib/anime.es.js"
import React, { useEffect } from "react"
import { getActionTargets } from "@helpers/commonFunctions"
import { BTN_DEALER_POSITION } from "@constants/Dealer"
import { setCurrentDealerPosition } from "@redux/slices/actionsSlice"
import { useDispatch } from "react-redux"

export type ActionMoveDealerButtonPros = {
  currentDealerPosition: number
  targets: any
  delay?: number
  animationRef: any
  flagReset?: boolean
}

const ActionMoveDealerButton = (props: ActionMoveDealerButtonPros) => {
  const {
    targets,
    delay = 0,
    animationRef,
    currentDealerPosition,
    flagReset,
  } = props
  const dispatch = useDispatch()
  useEffect(() => {
    let nextDealerPosition = 1

    if (flagReset) {
      nextDealerPosition = currentDealerPosition
    } else {
      if (currentDealerPosition < 9) {
        nextDealerPosition = currentDealerPosition + 1
      }
    }

    console.log("<<<<<<<< move dealer position <<<<<<<< ")
    console.log("flagReset", flagReset)
    console.log("nextDealerPosition", nextDealerPosition)

    dispatch(setCurrentDealerPosition(nextDealerPosition))

    animationRef.current = anime({
      targets: getActionTargets(targets),
      top: BTN_DEALER_POSITION[nextDealerPosition].top,
      left: BTN_DEALER_POSITION[nextDealerPosition].left,
      easing: "linear",
      autoplay: true,
      delay: delay,
      duration: 800,
    })
  }, [animationRef, currentDealerPosition, delay, targets])
  return <React.Fragment></React.Fragment>
}

export default ActionMoveDealerButton
