import { getActionTargets } from "@helpers/commonFunctions"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect } from "react"
export type ActionCollectChipPros = {
  translateX: number
  translateY: number
  targets: any | any[] | undefined
  control?: boolean
  delay?: number
  animationRef?: any
}

const ActionCollectChip = (props: ActionCollectChipPros) => {
  const { translateX, translateY, targets, delay = 0, animationRef } = props
  useEffect(() => {
    animationRef.current = anime({
      targets: getActionTargets(targets),
      translateX: translateX,
      translateY: translateY,
      easing: "easeInOutExpo",
      autoplay: true,
      delay: delay,
    })
  }, [animationRef, delay, targets, translateX, translateY])
  return <React.Fragment></React.Fragment>
}

export default ActionCollectChip
