export const CARD_POSITION_HAND_READER: any = {
  pot_limit_omaha: {
    // PLAYER 1
    PLAYER_1_1: {
      ROTATE: 0,
      X: 27,
      Y: -10,
    },
    PLAYER_1_2: {
      ROTATE: 0,
      X: 66,
      Y: -10,
    },
    PLAYER_1_3: {
      ROTATE: 0,
      X: 105,
      Y: -10,
    },
    PLAYER_1_4: {
      ROTATE: 0,
      X: 144,
      Y: -10,
    },
    // PLAYER 2
    PLAYER_2_1: {
      ROTATE: 60,
      X: -55,
      Y: -65,
    },
    PLAYER_2_2: {
      ROTATE: 60, //240
      X: -35,
      Y: -31,
    },
    PLAYER_2_3: {
      ROTATE: 60,
      X: -15,
      Y: 3,
    },
    PLAYER_2_4: {
      ROTATE: 60,
      X: 5,
      Y: 37,
    },
    // PLAYER 3
    PLAYER_3_1: {
      ROTATE: 133,
      X: 14, // -199
      Y: 50, // -138
    },
    PLAYER_3_2: {
      ROTATE: 133,
      X: 41, // -173
      Y: 21, // -159
    },
    PLAYER_3_3: {
      ROTATE: 133,
      X: 68, // -148
      Y: -8, // -180
    },
    PLAYER_3_4: {
      ROTATE: 133,
      X: 95, // -122
      Y: -37, // -201
    },
    // PLAYER 4
    PLAYER_4_1: {
      ROTATE: 0,
      X: 5,
      Y: 77,
    },
    PLAYER_4_2: {
      ROTATE: 0,
      X: 44,
      Y: 77,
    },
    PLAYER_4_3: {
      ROTATE: 0,
      X: 83,
      Y: 77,
    },
    PLAYER_4_4: {
      ROTATE: 0,
      X: 122,
      Y: 77,
    },
    // PLAYER 5
    PLAYER_5_1: {
      ROTATE: 0,
      X: -2,
      Y: 77,
    },
    PLAYER_5_2: {
      ROTATE: 0,
      X: 37,
      Y: 77,
    },
    PLAYER_5_3: {
      ROTATE: 0,
      X: 76,
      Y: 77,
    },
    PLAYER_5_4: {
      ROTATE: 0,
      X: 115,
      Y: 77,
    },
    // PLAYER 6
    PLAYER_6_1: {
      ROTATE: 0,
      X: -71,
      Y: 3,
    },
    PLAYER_6_2: {
      ROTATE: 0,
      X: -31,
      Y: 3,
    },
    PLAYER_6_3: {
      ROTATE: 0,
      X: 9,
      Y: 3,
    },
    PLAYER_6_4: {
      ROTATE: 0,
      X: 49,
      Y: 3,
    },
    // PLAYER 7
    PLAYER_7_1: {
      ROTATE: 222,
      X: -3,
      Y: 32,
    },
    PLAYER_7_2: {
      ROTATE: 222, //402
      X: 26,
      Y: 58,
    },
    PLAYER_7_3: {
      ROTATE: 222,
      X: 55,
      Y: 84,
    },
    PLAYER_7_4: {
      ROTATE: 222,
      X: 84,
      Y: 110,
    },
    // PLAYER 8
    PLAYER_8_1: {
      ROTATE: 119,
      X: -30,
      Y: 44,
    },
    PLAYER_8_2: {
      ROTATE: 119,
      X: -11,
      Y: 10,
    },
    PLAYER_8_3: {
      ROTATE: 119, //296
      X: 8,
      Y: -24,
    },
    PLAYER_8_4: {
      ROTATE: 119,
      X: 26,
      Y: -58,
    },
    // PLAYER 9
    PLAYER_9_1: {
      ROTATE: 0,
      X: -23,
      Y: -12,
    },
    PLAYER_9_2: {
      ROTATE: 0,
      X: 16,
      Y: -12,
    },
    PLAYER_9_3: {
      ROTATE: 0,
      X: 55,
      Y: -12,
    },
    PLAYER_9_4: {
      ROTATE: 0,
      X: 94,
      Y: -12,
    },
  },
  no_limit_hold_em: {
    // PLAYER 1
    PLAYER_1_1: {
      ROTATE: 0,
      X: 55,
      Y: -10,
    },
    PLAYER_1_2: {
      ROTATE: 0,
      X: 95,
      Y: -10,
    },
    // PLAYER 2
    PLAYER_2_1: {
      ROTATE: 55,
      X: 2,
      Y: 24,
    },
    PLAYER_2_2: {
      ROTATE: 55,
      X: -20,
      Y: -8,
    },
    // PLAYER 3
    PLAYER_3_1: {
      ROTATE: 133,
      X: 24,
      Y: 26,
    },
    PLAYER_3_2: {
      ROTATE: 133,
      X: 52,
      Y: -3,
    },
    // PLAYER 4
    PLAYER_4_1: {
      ROTATE: 0,
      X: 16,
      Y: 77,
    },
    PLAYER_4_2: {
      ROTATE: 0,
      X: 56,
      Y: 77,
    },
    // PLAYER 5
    PLAYER_5_1: {
      ROTATE: 0,
      X: 20,
      Y: 77,
    },
    PLAYER_5_2: {
      ROTATE: 0,
      X: 60,
      Y: 77,
    },
    // PLAYER 6
    PLAYER_6_1: {
      ROTATE: 0,
      X: -22,
      Y: 3,
    },
    PLAYER_6_2: {
      ROTATE: 0,
      X: 18,
      Y: 3,
    },
    // PLAYER 7
    PLAYER_7_1: {
      ROTATE: 222,
      X: 32,
      Y: 50,
    },
    PLAYER_7_2: {
      ROTATE: 222, //402
      X: 61,
      Y: 77,
    },
    // PLAYER 8
    PLAYER_8_1: {
      ROTATE: 119,
      X: -18,
      Y: 26,
    },
    PLAYER_8_2: {
      ROTATE: 119,
      X: 1,
      Y: -9,
    },
    // PLAYER 9
    PLAYER_9_1: {
      ROTATE: 0,
      X: 30,
      Y: -12,
    },
    PLAYER_9_2: {
      ROTATE: 0,
      X: 70,
      Y: -12,
    },
  },
  plo_tournament: {
    // PLAYER 1
    PLAYER_1_1: {
      ROTATE: 0,
      X: 27,
      Y: -10,
    },
    PLAYER_1_2: {
      ROTATE: 0,
      X: 66,
      Y: -10,
    },
    PLAYER_1_3: {
      ROTATE: 0,
      X: 105,
      Y: -10,
    },
    PLAYER_1_4: {
      ROTATE: 0,
      X: 144,
      Y: -10,
    },
    // PLAYER 2
    PLAYER_2_1: {
      ROTATE: 60,
      X: -55,
      Y: -65,
    },
    PLAYER_2_2: {
      ROTATE: 60, //240
      X: -35,
      Y: -31,
    },
    PLAYER_2_3: {
      ROTATE: 60,
      X: -15,
      Y: 3,
    },
    PLAYER_2_4: {
      ROTATE: 60,
      X: 5,
      Y: 37,
    },
    // PLAYER 3
    PLAYER_3_1: {
      ROTATE: 133,
      X: 14, // -199
      Y: 50, // -138
    },
    PLAYER_3_2: {
      ROTATE: 133,
      X: 41, // -173
      Y: 21, // -159
    },
    PLAYER_3_3: {
      ROTATE: 133,
      X: 68, // -148
      Y: -8, // -180
    },
    PLAYER_3_4: {
      ROTATE: 133,
      X: 95, // -122
      Y: -37, // -201
    },
    // PLAYER 4
    PLAYER_4_1: {
      ROTATE: 0,
      X: 5,
      Y: 77,
    },
    PLAYER_4_2: {
      ROTATE: 0,
      X: 44,
      Y: 77,
    },
    PLAYER_4_3: {
      ROTATE: 0,
      X: 83,
      Y: 77,
    },
    PLAYER_4_4: {
      ROTATE: 0,
      X: 122,
      Y: 77,
    },
    // PLAYER 5
    PLAYER_5_1: {
      ROTATE: 0,
      X: -2,
      Y: 77,
    },
    PLAYER_5_2: {
      ROTATE: 0,
      X: 37,
      Y: 77,
    },
    PLAYER_5_3: {
      ROTATE: 0,
      X: 76,
      Y: 77,
    },
    PLAYER_5_4: {
      ROTATE: 0,
      X: 115,
      Y: 77,
    },
    // PLAYER 6
    PLAYER_6_1: {
      ROTATE: 0,
      X: -71,
      Y: 3,
    },
    PLAYER_6_2: {
      ROTATE: 0,
      X: -31,
      Y: 3,
    },
    PLAYER_6_3: {
      ROTATE: 0,
      X: 9,
      Y: 3,
    },
    PLAYER_6_4: {
      ROTATE: 0,
      X: 49,
      Y: 3,
    },
    // PLAYER 7
    PLAYER_7_1: {
      ROTATE: 222,
      X: -3,
      Y: 32,
    },
    PLAYER_7_2: {
      ROTATE: 222, //402
      X: 26,
      Y: 58,
    },
    PLAYER_7_3: {
      ROTATE: 222,
      X: 55,
      Y: 84,
    },
    PLAYER_7_4: {
      ROTATE: 222,
      X: 84,
      Y: 110,
    },
    // PLAYER 8
    PLAYER_8_1: {
      ROTATE: 119,
      X: -30,
      Y: 44,
    },
    PLAYER_8_2: {
      ROTATE: 119,
      X: -11,
      Y: 10,
    },
    PLAYER_8_3: {
      ROTATE: 119, //296
      X: 8,
      Y: -24,
    },
    PLAYER_8_4: {
      ROTATE: 119,
      X: 26,
      Y: -58,
    },
    // PLAYER 9
    PLAYER_9_1: {
      ROTATE: 0,
      X: -23,
      Y: -12,
    },
    PLAYER_9_2: {
      ROTATE: 0,
      X: 16,
      Y: -12,
    },
    PLAYER_9_3: {
      ROTATE: 0,
      X: 55,
      Y: -12,
    },
    PLAYER_9_4: {
      ROTATE: 0,
      X: 94,
      Y: -12,
    },
  },
}

export const HIGHLIGHT_POSITION: any = {
  pot_limit_omaha: {
    HIGHLIGHT_WIDTH: 170,
    HIGHLIGHT_HEIGHT: 64,
    // PLAYER 1
    PLAYER_1: {
      ROTATE: 0,
      top: 426,
      left: 204,
    },
    // PLAYER 2
    PLAYER_2: {
      ROTATE: 60,
      top: 361,
      left: 92,
    },
    // PLAYER 3
    PLAYER_3: {
      ROTATE: 133,
      top: 259,
      left: 148,
    },
    // PLAYER 4
    PLAYER_4: {
      ROTATE: 0,
      top: 213,
      left: 270,
    },
    // PLAYER 5
    PLAYER_5: {
      ROTATE: 0,
      top: 215,
      left: 435,
    },
    // PLAYER 6
    PLAYER_6: {
      ROTATE: 0,
      top: 216,
      left: 592,
    },
    // PLAYER 7
    PLAYER_7: {
      ROTATE: 222,
      top: 262,
      left: 706,
    },
    // PLAYER 8
    PLAYER_8: {
      ROTATE: 119,
      top: 371,
      left: 735,
    },
    // PLAYER 9
    PLAYER_9: {
      ROTATE: 0,
      top: 429,
      left: 620,
    },
  },
  no_limit_hold_em: {
    HIGHLIGHT_WIDTH: 98,
    HIGHLIGHT_HEIGHT: 64,
    // PLAYER 1
    PLAYER_1: {
      ROTATE: 0,
      top: 426,
      left: 230,
    },
    // PLAYER 2
    PLAYER_2: {
      ROTATE: 55,
      top: 384,
      left: 145,
    },
    // PLAYER 3
    PLAYER_3: {
      ROTATE: 133,
      top: 264,
      left: 167,
    },
    // PLAYER 4
    PLAYER_4: {
      ROTATE: 0,
      top: 214,
      left: 279,
    },
    // PLAYER 5
    PLAYER_5: {
      ROTATE: 0,
      top: 215,
      left: 454,
    },
    // PLAYER 6
    PLAYER_6: {
      ROTATE: 0,
      top: 216,
      left: 636,
    },
    // PLAYER 7
    PLAYER_7: {
      ROTATE: 222,
      top: 256,
      left: 749,
    },
    // PLAYER 8
    PLAYER_8: {
      ROTATE: 119,
      top: 387,
      left: 764,
    },
    // PLAYER 9
    PLAYER_9: {
      ROTATE: 0,
      top: 429,
      left: 670,
    },
  },
  plo_tournament: {
    HIGHLIGHT_WIDTH: 170,
    HIGHLIGHT_HEIGHT: 64,
    // PLAYER 1
    PLAYER_1: {
      ROTATE: 0,
      top: 426,
      left: 204,
    },
    // PLAYER 2
    PLAYER_2: {
      ROTATE: 60,
      top: 361,
      left: 92,
    },
    // PLAYER 3
    PLAYER_3: {
      ROTATE: 133,
      top: 259,
      left: 148,
    },
    // PLAYER 4
    PLAYER_4: {
      ROTATE: 0,
      top: 213,
      left: 270,
    },
    // PLAYER 5
    PLAYER_5: {
      ROTATE: 0,
      top: 215,
      left: 435,
    },
    // PLAYER 6
    PLAYER_6: {
      ROTATE: 0,
      top: 216,
      left: 592,
    },
    // PLAYER 7
    PLAYER_7: {
      ROTATE: 222,
      top: 262,
      left: 706,
    },
    // PLAYER 8
    PLAYER_8: {
      ROTATE: 119,
      top: 371,
      left: 735,
    },
    // PLAYER 9
    PLAYER_9: {
      ROTATE: 0,
      top: 429,
      left: 620,
    },
  },
}
