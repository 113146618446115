import { REMOVE_CARD_POSITION } from "@constants/PositionRemoveCard"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect } from "react"

export type ActionClearPros = {
  translateX: number
  translateY: number
  targets: string
  iconType: string
  className?: string
  control?: boolean
  delay?: number
  hideOldIcons?: string[]
  animationRef?: any
  gameMode: any
  counterAction: number
}

const ActionClear = (props: ActionClearPros) => {
  const { targets, animationRef, counterAction } = props

  useEffect(() => {
    console.log("ActionClear effect........")
    animationRef.is_array = true
    animationRef.subAnimations = []
    const singleCardElements = document.getElementsByClassName(`single-card`)
    const boardCardElements = document.getElementsByClassName(`board`)
    const revealCardElements = document.getElementsByClassName(`reveal-card`)
    const chipsELements = document.getElementsByClassName(`chips`)
    const muckIcon = document.getElementById('muckIcon');
    let countItemSingleCard = 0
    let countItemBoardCard = 0
    let countItemRevealCard = 0
    let countItemChips = 0
    for (let element of singleCardElements) {
      if (
        !element.classList.contains("reveal-card") &&
        !element.classList.contains("board")
      ) {
        countItemSingleCard += 1
        animationRef.subAnimations[
          `action-${counterAction}-single-card-${countItemSingleCard}`
        ] = {}
        animationRef.subAnimations[
          `action-${counterAction}-single-card-${countItemSingleCard}`
        ].current = anime({
          targets: element,
          autoplay: true,
          keyframes: [
            {
              translateY: 0,
              translateX: 0,
              duration: 700,
              rotate: 0,
              easing: "linear",
              scale: 0,
            },
            { opacity: 0 },
          ],
        })
      }
    }
    for (let element of boardCardElements) {
      countItemBoardCard += 1
      animationRef.subAnimations[
        `action-${counterAction}-board-card-${countItemBoardCard}`
      ] = {}
      animationRef.subAnimations[
        `action-${counterAction}-board-card-${countItemBoardCard}`
      ].current = anime({
        targets: element,
        autoplay: true,
        keyframes: [
          {
            translateY: 22,
            translateX: -12,
            duration: 700,
            rotate: 0,
            easing: "linear",
            scale: 0,
          },
          { opacity: 0 },
        ],
      })
    }
    for (let element of revealCardElements) {
      let playerPosition = 0
      for (let className of element.classList) {
        if (className.includes("player-position-")) {
          const listStr = className.split("player-position-")
          playerPosition = parseInt(listStr[1])
          break
        }
      }
      if (playerPosition > 0) {
        countItemRevealCard += 1
        animationRef.subAnimations[
          `action-${counterAction}-reveal-card-${countItemRevealCard}`
        ] = {}
        animationRef.subAnimations[
          `action-${counterAction}-reveal-card-${countItemRevealCard}`
        ].current = anime({
          targets: element,
          autoplay: true,
          keyframes: [
            {
              translateX: REMOVE_CARD_POSITION[playerPosition].X + 27,
              translateY: REMOVE_CARD_POSITION[playerPosition].Y + 50,
              duration: 700,
              rotate: 0,
              easing: "linear",
              scale: 0,
            },
            { opacity: 0 },
          ],
        })
      }
    }
    for (let element of chipsELements) {
      if (
        !element.classList.contains("winner-main-pot") &&
        !element.classList.contains("winner-side-pot") &&
        !element.classList.contains("burn-in-pot")
      ) {
        countItemChips += 1
        animationRef.subAnimations[
          `action-${counterAction}-chips-${countItemChips}`
        ] = {}
        animationRef.subAnimations[
          `action-${counterAction}-chips-${countItemChips}`
        ].current = anime({
          targets: element,
          autoplay: true,
          keyframes: [
            {
              translateY: 0,
              translateX: 0,
              duration: 300,
              rotate: 0,
              easing: "linear",
              scale: 0,
              opacity: 0,
            },
          ],
        })
      }
    }
    animationRef.subAnimations[`action-${counterAction}-other`] = {}
    animationRef.subAnimations[`action-${counterAction}-other`].current = anime(
      {
        targets: [".winner-main-pot", ".winner-side-pot", ".burn-in-pot"],
        rotate: 0,
        opacity: 0,
        scale: 0,
        duration: 300,
        easing: "easeInOutQuad",
        autoplay: true,
      },
    )

    //hide muckIcon
    animationRef.subAnimations['muckIcon'] = {
      current: anime({
        targets: muckIcon,
        translateY: 60,
        translateX: 25,
        opacity: 0,
        scale: 0,
        duration: 700,
        easing: 'linear',
        autoplay: true
      })
    }

  }, [animationRef, counterAction, targets])

  return <React.Fragment></React.Fragment>
}

export default ActionClear
