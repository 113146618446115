import { MAIN_CARD_TRANSLATE } from "@constants/SingleCard"
import anime from "animejs/lib/anime.es.js"
import React, { useEffect } from "react"
export type ActionLiftProps = {
  cardNumbers: number[]
  animationRef?: any
}

const ActionLift = (props: ActionLiftProps) => {
  const { animationRef, cardNumbers } = props
  useEffect(() => {
    if (cardNumbers && cardNumbers.length > 0) {
      animationRef.is_array = true
      animationRef.subAnimations = []
      const boardElements = document.getElementsByClassName(`board`)
      cardNumbers.forEach((cardNumber) => {
        for (let boardElement of boardElements) {
          if (
            cardNumber &&
            boardElement.classList.contains(`board-${cardNumber}`)
          ) {
            animationRef.subAnimations[`lift-${cardNumber}`] = {}
            animationRef.subAnimations[`lift-${cardNumber}`].current = anime({
              targets: boardElement,
              translateY: MAIN_CARD_TRANSLATE.Y - 15,
              easing: "easeInOutExpo",
              autoplay: true,
            })
          }
        }
      })
    }
  }, [animationRef, cardNumbers])
  return <React.Fragment></React.Fragment>
}

export default ActionLift
