import MODAL_CHIP from "@assets/modal/bg-chip.png"
import BTN_BACK from "@assets/modal/btn-back.png"
import BTN_CLOSE from "@assets/modal/btn-close.png"
import BTN_OK from "@assets/modal/btn-ok.png"
import {
  backAction,
  setDataModalChip,
  setDataModalChipSubmitted,
  setModalChipIsOpen,
} from "@redux/slices/actionsSlice"
import { RootState } from "@redux/store"
import React, { useEffect, useRef, useState } from "react"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"

import { nextAction } from "@redux/slices/actionsSlice"
import { setMetric } from "@redux/slices/metricSlice"
import { backCount, betErrorCount } from "@services/performanceMetricApi"
import { MetricDto } from "@types"
import styles from "./Modal.module.css"
import { gameModeIcons } from "@helpers/modeIcons"

const ModalChip = ({ scalding }: { scalding: number }) => {
  const inputBetRef = useRef(null)
  const inputChangeRef = useRef(null)

  const {
    modalChipIsOpen,
    dataModalChip,
    currentIndex,
    dataModalChipSubmitted,
    gameMode,
  } = useSelector((state: RootState) => state.actions)
  const { metric } = useSelector((state: RootState) => state.metric)
  const [inputChange, setInputChange] = useState<number | undefined>(undefined)
  const [inputBet, setInputBet] = useState<number | undefined>(undefined)
  const { whiteCoin, greenCoin, redCoin, blackCoin } = gameModeIcons(gameMode)

  const dispatch = useDispatch()
  const {
    actionTitle,
    whileChipNumber = 0,
    redChipNumber = 0,
    greenChipNumber = 0,
    blackChipNumber = 0,
    bet = 0,
    change = 0,
    onBackAction = false,
    modalIndex = 0,
  } = dataModalChip

  const submittedInput =
    dataModalChipSubmitted.find((e: any) => e.modalIndex === modalIndex) ||
    undefined
  // @ts-ignore:next-line
  const previousInputBet = submittedInput?.inputBet || undefined
  // @ts-ignore:next-line
  const previousInputChange = submittedInput?.inputChange || undefined

  useEffect(() => {
    if (previousInputBet) {
      setInputBet(previousInputBet)
    }
    if (previousInputChange) {
      setInputChange(previousInputChange)
    }
  }, [previousInputBet, previousInputChange])

  const onCloseModal = async (back: boolean) => {
    if (back) {
      console.log('back on modal')
      backCount((metric as MetricDto).id)
        .then((data) => {
          console.log("backend response backCount", data)
          dispatch(setMetric(data))
        })
        .catch((error) => {
          console.error(error)
        })
    }
    dispatch(setModalChipIsOpen(false))
    if (!onBackAction) {
      dispatch(backAction(currentIndex - 1))
    }
    dispatch(
      setDataModalChip({
        actionName: "",
        whileChipNumber: 0,
        redChipNumber: 0,
        greenChipNumber: 0,
        blackChipNumber: 0,
        bet: 0,
        change: 0,
        onBackAction: false,
      }),
    )
  }

  const onOk = async () => {
    dispatch(setModalChipIsOpen(false))

    // @ts-ignore:next-line
    const currentUserBet = inputBetRef?.current?.value
    // @ts-ignore:next-line
    const currentUserChange = inputChangeRef?.current?.value

    setInputBet(currentUserBet)
    setInputChange(currentUserChange)

    // console.log(`result: expect bet ${Number(bet)} - change ${Number(change)}`)
    // console.log(`result: previous bet ${Number(previousInputBet)} - change ${Number(previousInputChange)}`)
    // console.log(`result: user bet ${Number(currentUserBet)} - change ${Number(currentUserChange)}`)

    if (
      Number(bet) === Number(currentUserBet) &&
      Number(change) === Number(currentUserChange)
    ) {
      console.log("-- User answered correctly ")
      // if (onBackAction) {
      //   const data = await backAnswer((metric as MetricDto).id, "bet")
      //   console.log("backend response backAnswer", data)
      //   dispatch(setMetric(data))
      // }
    } else {
      console.log("-- User answered incorrectly ")
      if (onBackAction) {
        if (
          Number(previousInputBet) !== Number(currentUserBet) ||
          Number(previousInputChange) !== Number(currentUserChange)
        ) {
          console.log("--- onBackAction")
          const data = await betErrorCount((metric as MetricDto).id)
          console.log(">>> betErrorCount", data)
          dispatch(setMetric(data))
        }
      } else {
        if (
          Number(previousInputBet) !== Number(currentUserBet) ||
          Number(previousInputChange) !== Number(currentUserChange)
        ) {
          console.log("--- User re-anwser with different input")
          const data = await betErrorCount((metric as MetricDto).id)
          console.log(">>> betErrorCount", data)
          dispatch(setMetric(data))
        } else {
          console.log("--- User re-anwser with same input")
        }
      }
    }

    dispatch(
      setDataModalChip({
        actionName: "",
        whileChipNumber: 0,
        redChipNumber: 0,
        greenChipNumber: 0,
        blackChipNumber: 0,
        bet: 0,
        change: 0,
        onBackAction: false,
      }),
    )

    // store user submitted
    let newSubmittedList = [...dataModalChipSubmitted]
    const modalIndexFound = newSubmittedList.find(
      (i: any) => i.modalIndex === modalIndex,
    )
    // console.log('modalIndexFound', modalIndexFound)

    if (modalIndexFound) {
      newSubmittedList.splice(newSubmittedList.indexOf(modalIndexFound), 1)
    }

    newSubmittedList = newSubmittedList.concat({
      modalIndex: modalIndex,
      inputBet: currentUserBet,
      inputChange: currentUserChange,
    })

    console.log("newSubmittedList", newSubmittedList)

    dispatch(setDataModalChipSubmitted(newSubmittedList))

    if (onBackAction) {
      // go to next action after re-anwser on back
      // console.log('go to next action after re-anwser on back')
      dispatch(nextAction(currentIndex + 1))
    }
  }
  Modal.setAppElement("#root")
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "900px",
      overflow: "hidden",
      zoom: scalding,
    },
    overlay: {
      zIndex: 100000,
    },
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modalChipIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            fontFamily: "Lato",
          }}
          className="relative w-[100%] text-white"
          id="popupModal"
        >
          <img src={MODAL_CHIP} alt="MODAL_CHIP" />
          <img
            src={whiteCoin}
            alt="WHITE_CHIP"
            className="absolute w-[680px] top-[-30px] left-[402px]"
          />
          <img
            src={redCoin}
            alt="RED_CHIP"
            className="absolute w-[680px] top-[-30px] left-[210px]"
          />
          <img
            src={greenCoin}
            alt="GREEN_CHIP"
            className="absolute w-[680px] top-[-30px] left-[18px]"
          />
          <img
            src={blackCoin}
            alt="BLACK_CHIP"
            className="absolute w-[680px] top-[-30px] left-[-180px]"
          />
          <div className="absolute top-[5px] left-[15px] font-bold text-[28px]">
            {actionTitle}
          </div>
          <img
            onClick={() => { onCloseModal(false) }}
            src={BTN_CLOSE}
            alt="BTN_CLOSE"
            className="w-[45px] absolute top-[6px] right-[40px] cursor-pointer"
          />
          <div className="absolute top-[265px] left-[714px] font-bold text-[18px] w-[46px] text-center">
            <div className="">{whileChipNumber}</div>
          </div>

          <div className="absolute top-[265px] left-[522px] font-bold text-[18px] w-[46px] text-center">
            <div className="">{redChipNumber}</div>
          </div>

          <div className="absolute top-[265px] left-[330px] font-bold text-[18px] w-[46px] text-center">
            <div className="">{greenChipNumber}</div>
          </div>

          <div className="absolute top-[265px] left-[132px]  font-bold text-[18px] w-[46px] text-center">
            <div className="">{blackChipNumber}</div>
          </div>
          <form
            action=""
            onSubmit={(event) => {
              event.preventDefault()
            }}
          >
            <div className="absolute top-[364px] left-[589px] font-bold text-[18px] w-[270px] text-center">
              <input
                ref={inputBetRef}
                type="number"
                name="bet"
                className={`bg-transparent w-[264px] h-[41px] p-1 border-transparent text-center ${styles.inputOutline}`}
                value={previousInputBet ? inputBet : undefined}
                onChange={(e) => {
                  setInputBet(Number(e.target.value))
                }}
                autoFocus
              />
            </div>
            <div className="absolute top-[423px] left-[589px] font-bold text-[18px] w-[270px] text-center">
              <input
                ref={inputChangeRef}
                type="number"
                name="change"
                className={`bg-transparent w-[264px] h-[41px] p-1 border-transparent text-center ${styles.inputOutline}`}
                value={previousInputChange ? inputChange : undefined}
                onChange={(e) => {
                  setInputChange(Number(e.target.value))
                }}
              />
            </div>
          </form>
          <div className="absolute top-[490px] left-[322px] w-[250px] flex justify-between">
            <img
              src={BTN_BACK}
              alt="BTN_BACK"
              className="w-[120px] cursor-pointer"
              onClick={() => { onCloseModal(true) }}
              id="popupBackBtn"
            />
            <img
              src={BTN_OK}
              alt="BTN_OK"
              className="w-[120px] cursor-pointer"
              onClick={onOk}
              id="popupNextBtn"
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ModalChip
