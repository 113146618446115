import ActionShowOut from "@components/Animations/ActionShowOut"
import { PLAYER_POSITION_COIN } from "@constants/Player"
import { COIN_POSITION_TRANSLATE } from "@constants/ShowCoin"
import { randomIntFromInterval } from "@helpers/commonFunctions"
import React, { useCallback } from "react"

export type ActionBlindPros = {
  translateX: number
  translateY: number
  targets: string
  iconType: string
  counterAction: number
  className?: string
  control?: boolean
  delay?: number
  playerPositionStart: number
  chipInfos?: {
    quantityOfType: any
    bet: number
    change: number
  }
  animationRef?: any
}

const ActionBlind = (props: ActionBlindPros) => {
  const {
    delay = 0,
    playerPositionStart,
    chipInfos,
    animationRef,
    counterAction,
  } = props

  const ActionCollectChips = useCallback(() => {
    if (chipInfos) {
      const Elements: JSX.Element[] = []

      animationRef.is_array = true
      animationRef.subAnimations = []

      Object.keys(chipInfos.quantityOfType).forEach((key, index) => {
        if (chipInfos.quantityOfType[key] > 0) {
          for (let i = 1; i <= chipInfos.quantityOfType[key]; i++) {
            const domElements: any = document.getElementsByClassName(
              `${key}-${playerPositionStart}-${i}-${counterAction}`,
            )
            for (let element of domElements) {
              if (
                element.classList.contains("side-pot-collected") ||
                element.classList.contains("main-pot-collected") ||
                element.classList.contains("rake-collected") ||
                element.classList.contains("promo2-collected")
              ) {
                element.classList.remove(`${key}-${playerPositionStart}-${i}`)
              }
            }
            animationRef.subAnimations[`${key}-${playerPositionStart}-${i}`] =
              {}
            Elements.push(
              <ActionShowOut
                translateX={
                  COIN_POSITION_TRANSLATE[playerPositionStart].X +
                  randomIntFromInterval(-10, 10)
                }
                translateY={
                  COIN_POSITION_TRANSLATE[playerPositionStart].Y +
                  randomIntFromInterval(-10, 10)
                }
                targets={`${key}-${playerPositionStart}-${i}-${counterAction}`}
                className={`chips-${playerPositionStart}-${key}-${i} chips absolute w-[90px] ${key}-${playerPositionStart} ${key}-${playerPositionStart}-${i}-${counterAction} chips-${playerPositionStart} p-${playerPositionStart}-step-${counterAction} z-[30]`}
                iconType={key}
                delay={delay}
                hideOldIcons={[
                  "icon-f",
                  "icon-c",
                  "icon-a",
                  `chips-${playerPositionStart}`,
                ]}
                key={`${key}-${playerPositionStart}-${i}`}
                animationRef={
                  animationRef.subAnimations[
                    `${key}-${playerPositionStart}-${i}`
                  ]
                }
                action=""
                style={{
                  top: `${PLAYER_POSITION_COIN[playerPositionStart].top}px`,
                  left: `${PLAYER_POSITION_COIN[playerPositionStart].left}px`,
                }}
              />,
            )
          }
        }
      })
      return Elements
    }
  }, [animationRef, chipInfos, counterAction, delay, playerPositionStart])
  return <React.Fragment>{ActionCollectChips()}</React.Fragment>
}

export default ActionBlind
