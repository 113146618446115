import * as React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import NewLogoWithText from "../../assets/main/new-logo-with-text.png"
import styles from "./RegisterPage.module.css"
import { register } from "@redux/slices/authSlice"
import InputCard from "@components/Stripe/InputCard"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const MySwal = withReactContent(Swal)

export type StripeInputCardRef = React.ElementRef<typeof InputCard>

const RegisterPage = () => {
  const dispatch = useDispatch<any>()
  let navigate = useNavigate()
  const [errorMessage, setErrorMessage] = React.useState(
    "The register credentials are incorrect. Please try again.",
  )
  const [loginSuccess, setLoginSuccess] = React.useState<boolean | null>(null)
  const [allowSubmit, setAllowSubmit] = React.useState(false)
  const isShowLoginError = typeof loginSuccess === "boolean" && !loginSuccess
  const stripeCardRef = React.useRef<StripeInputCardRef>(null)
  const errorMessageLowerCase = errorMessage.toLowerCase()
  const errorAlert = React.useCallback((message: string) => {
    setLoginSuccess(false)
    setErrorMessage(message)
    MySwal.fire({
      icon: "error",
      title: "Error!",
      text: message,
    })
  }, [])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    let formData = new FormData(event.currentTarget)
    let userId = formData.get("username") as string
    let password = formData.get("password") as string
    let name = formData.get("name") as string
    let email = formData.get("email") as string
    let phoneNumber = formData.get("phoneNumber") as string
    let address = formData.get("address") as string
    let city = formData.get("city") as string
    let state = formData.get("state") as string
    // @ts-ignore:next-line
    let zipcode = formData.get("zipcode") as number

    let confirmPassword = formData.get("confirmPassword") as string
    if (password !== confirmPassword) {
      setAllowSubmit(false)
      errorAlert("Passwords do not match!")
      return
    }
    const stripeCard = await stripeCardRef.current?.confirmPayment(formData)
    if (stripeCard?.error) {
      // setAllowSubmit(false)
      errorAlert(stripeCard.error.message as string)
      return
    }
    const paymentMethodId = stripeCard?.paymentMethod?.id
    if (!paymentMethodId) {
      errorAlert("Payment method is required")
      return
    }
    // @ts-ignore:next-line
    const registerStatus = await dispatch(
      register({
        username: userId,
        password: password,
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        address: address,
        city: city,
        state: state,
        zipcode: zipcode,
        paymentMethodId: paymentMethodId,
      }),
    )
    const isRegistered = registerStatus.success
    // @ts-ignore:next-line
    setLoginSuccess(isRegistered)
    if (isRegistered) {
      // TODO: Check user
      await MySwal.fire({
        icon: "success",
        title: "Please confirm your email",
        text: "Please check your email to verify your account. If you haven't received the verification email, kindly check your spam folder. For further assistance, contact support.",
      })
      navigate("/login")
    } else {
      setErrorMessage(registerStatus.error)
      errorAlert(registerStatus.error)
    }
  }

  const handleFormChange = (event: React.FormEvent<HTMLFormElement>) => {
    setAllowSubmit(event.currentTarget.checkValidity())
  }

  return (
    <>
      <div className=" h-[calc(100%-24px)] overflow-auto  mt-[24px] flex flex-1 flex-col px-6 py-12 lg:px-8">
        <div className="mt-10 mx-auto relative w-[1024px]">
          <img
            src={NewLogoWithText}
            className="max-w-full h-auto mb-10"
            alt="new main logo"
          />
          <form
            className="w-full"
            onSubmit={handleSubmit}
            onChange={handleFormChange}
          >
            <div className="flex justify-between mb-20">
              <label htmlFor="username" className="text-[55px] text-[#000000]">
                Username:
              </label>
              <div>
                <input
                  id="username"
                  name="username"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${
                    errorMessageLowerCase.includes("username")
                      ? "border-2 border-red-500"
                      : "border-0"
                  }`}
                />
              </div>
            </div>

            <div className="flex justify-between mb-20">
              <label htmlFor="password" className="text-[55px] text-[#000000]">
                Password:
              </label>
              <div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${
                    errorMessageLowerCase.includes("password")
                      ? "border-2 border-red-500"
                      : "border-0"
                  }`}
                />
              </div>
            </div>

            <div className="flex justify-between mb-20">
              <label
                htmlFor="confirmPassword"
                className="text-[55px] text-[#000000]"
              >
                Confirm Password:
              </label>
              <div>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="current-password"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${
                    errorMessageLowerCase.includes("password")
                      ? "border-2 border-red-500"
                      : "border-0"
                  }`}
                />
              </div>
            </div>

            <div className="flex justify-between mb-20">
              <label htmlFor="name" className="text-[55px] text-[#000000]">
                Name:
              </label>
              <div>
                <input
                  id="name"
                  name="name"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] `}
                />
              </div>
            </div>

            <div className="flex justify-between mb-20">
              <label htmlFor="Email" className="text-[55px] text-[#000000]">
                Email:
              </label>
              <div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${
                    errorMessageLowerCase.includes("email")
                      ? "border-2 border-red-500"
                      : "border-0"
                  }`}
                />
              </div>
            </div>

            <div className="flex justify-between mb-20">
              <label
                htmlFor="phoneNumber"
                className="text-[55px] text-[#000000]"
              >
                Phone Number:
              </label>
              <div>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  pattern="[\+0-9\- \(\)]*"
                  type="tel"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${
                    errorMessageLowerCase.includes("phone")
                      ? "border-2 border-red-500"
                      : "border-0"
                  }`}
                />
              </div>
            </div>

            <div className="flex justify-between mb-20">
              <label htmlFor="address" className="text-[55px] text-[#000000]">
                Address line:
              </label>
              <div>
                <input
                  id="address"
                  name="address"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${
                    errorMessageLowerCase.includes("address")
                      ? "border-2 border-red-500"
                      : "border-0"
                  }`}
                />
              </div>
            </div>

            <div className="flex justify-between mb-20">
              <label htmlFor="city" className="text-[55px] text-[#000000]">
                City:
              </label>
              <div>
                <input
                  id="city"
                  name="city"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${
                    errorMessageLowerCase.includes("city")
                      ? "border-2 border-red-500"
                      : "border-0"
                  }`}
                />
              </div>
            </div>

            <div className="flex justify-between mb-20">
              <label htmlFor="state" className="text-[55px] text-[#000000]">
                State:
              </label>
              <div>
                <input
                  id="state"
                  name="state"
                  required
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${
                    errorMessageLowerCase.includes("state")
                      ? "border-2 border-red-500"
                      : "border-0"
                  }`}
                />
              </div>
            </div>

            <div className="flex justify-between mb-20">
              <label htmlFor="zipcode" className="text-[55px] text-[#000000]">
                Zip:
              </label>
              <div>
                <input
                  id="zipcode"
                  name="zipcode"
                  type="tel"
                  pattern="[0-9]*"
                  className={`w-[716px] h-[80px] rounded-[10px] px-2  text-[40px] bg-[#E7E6E6] ${
                    errorMessageLowerCase.includes("zipcode")
                      ? "border-2 border-red-500"
                      : "border-0"
                  }`}
                />
              </div>
            </div>

            <div className="text-3xl mb-16 flex flex-row items-center gap-2 justify-center">
              <input
                type="checkbox"
                name="acceptTOS"
                id="acceptTOS"
                className="mr-1 scale-150"
                required
              />
              <span>I have read and agreed to the</span>
              <a
                href="/register/tos"
                className="text-center underline underline-offset-4 cursor-pointer text-[#0000EE]"
                target="_blank"
              >
                Terms of Service
              </a>
            </div>

            {isShowLoginError ? (
              <div className="flex justify-between">
                <p className="text-red-500 text-[30px] m-auto">
                  {errorMessage}
                </p>
              </div>
            ) : null}
            <InputCard ref={stripeCardRef} />
            <div className="flex m-auto mt-20">
              <button
                type="submit"
                className={`w-[356px] h-[123px] m-auto justify-center rounded-[20px] px-3 py-1.5 text-[55px] font-bold text-white ${
                  styles.bgNextBtn
                }
                  ${!allowSubmit && "grayscale"}`}
                disabled={!allowSubmit}
              ></button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default RegisterPage
