import { MAIN_POT_POSITION_TRANSLATE, MAIN_POT_V2_POSITION_TRANSLATE } from "@constants/Chip"
import { PLAYER_POSITION, CHECK_FOLD_POSITION } from "@constants/Player"
import { CHECK_FOLD_ICON_POSITION_TRANSLATE } from "@constants/ShowOut"
import {
  clearChipClassTypePlayerNumber,
  randomIntFromInterval,
} from "@helpers/commonFunctions"
import anime from "animejs/lib/anime.es.js"
import React, { useCallback, useEffect } from "react"
import ActionCollectChip from "./ActionCollectChip"
import ActionShowOut from "./ActionShowOut"
import { RootState } from "@redux/store"
import { useSelector } from "react-redux"

export type ActionFoldPros = {
  translateX: number
  translateY: number
  rotate: number
  targets: string
  playerPositionStart: number
  control?: boolean
  delay?: number
  chipInfos?: {
    quantityOfType: any
    bet: number
    change: number
  }
  withChip?: boolean
  animationRef?: any
  currentIndex?: number
}

const ActionFold = (props: ActionFoldPros) => {
  const {
    control = false,
    withChip = false,
    translateX,
    translateY,
    rotate,
    delay = 0,
    playerPositionStart,
    chipInfos,
    animationRef,
    currentIndex = 1,
  } = props
  const { user } = useSelector((state: RootState) => state.auth)

  animationRef.is_array = true
  animationRef.subAnimations = []
  animationRef.subAnimations[`icon-f-${playerPositionStart}`] = {}

  useEffect(() => {
    const domElements: any = document.getElementsByClassName(
      `f-card-${playerPositionStart}`,
    )
    let index = 1
    for (let element of domElements) {
      animationRef.subAnimations[`fold-${playerPositionStart}-${index}`] = {}
      animationRef.subAnimations[
        `fold-${playerPositionStart}-${index}`
      ].current = anime({
        targets: element,
        autoplay: true,
        delay: delay,
        keyframes: [
          { zIndex: currentIndex, duration: 0 },
          {
            translateX: translateX + randomIntFromInterval(-10, 10),
            translateY: translateY + randomIntFromInterval(-10, 10),
            rotate: randomIntFromInterval(-360, rotate),
            easing: "easeInOutQuad",
            delay: 10,
            duration: 1000,
          },
        ],
      })
      index += 1
    }
  }, [
    animationRef,
    currentIndex,
    delay,
    playerPositionStart,
    rotate,
    translateX,
    translateY,
  ])
  useEffect(() => {
    if (animationRef && animationRef.current) {
      if (control) {
        animationRef.current.play()
      }
      if (!control && animationRef.current.completed) {
        animationRef.current.reverse()
      }
    }
  }, [animationRef, control])

  const ActionCollectChips = useCallback(() => {
    if (withChip && chipInfos && chipInfos.quantityOfType) {
      const Elements: JSX.Element[] = []
      Object.keys(chipInfos.quantityOfType).forEach((key) => {
        if (chipInfos.quantityOfType[key] > 0) {
          const elements = document.getElementsByClassName(
            `${key}-${playerPositionStart}`,
          )
          let _counter = 1
          for (let element of elements) {
            animationRef.subAnimations[
              `${key}-${playerPositionStart}-${_counter}`
            ] = {}
            if (
              !element.classList.contains("rake-collected") &&
              !element.classList.contains("main-pot-collected") &&
              !element.classList.contains("side-pot-collected") &&
              !element.classList.contains("promo2-collected") &&
              !element.classList.contains("hidden")
            ) {
              Elements.push(
                <ActionCollectChip
                  translateX={
                    (user?.alternatePotLocation ? MAIN_POT_V2_POSITION_TRANSLATE[playerPositionStart].X : MAIN_POT_POSITION_TRANSLATE[playerPositionStart].X) +
                    randomIntFromInterval(-5, 5)
                  }
                  translateY={
                    (user?.alternatePotLocation ? MAIN_POT_V2_POSITION_TRANSLATE[playerPositionStart].Y : MAIN_POT_POSITION_TRANSLATE[playerPositionStart].Y) +
                    randomIntFromInterval(-5, 5)
                  }
                  targets={element}
                  delay={delay}
                  animationRef={
                    animationRef.subAnimations[
                      `${key}-${playerPositionStart}-${_counter}`
                    ]
                  }
                  key={`${key}-${playerPositionStart}-${randomIntFromInterval(
                    0,
                    100000,
                  )}`}
                />,
              )
              _counter += 1
              setTimeout(() => {
                element.classList.add("main-pot-collected")
                clearChipClassTypePlayerNumber(
                  element,
                  playerPositionStart,
                  key,
                )
              }, 100)
            }
          }
        }
      })
      return Elements
    } else {
      return <></>
    }
  }, [
    withChip,
    chipInfos,
    animationRef.subAnimations,
    playerPositionStart,
    delay,
  ])

  return (
    <React.Fragment>
      <ActionShowOut
        translateX={CHECK_FOLD_ICON_POSITION_TRANSLATE[playerPositionStart].X}
        translateY={CHECK_FOLD_ICON_POSITION_TRANSLATE[playerPositionStart].Y}
        targets={`icon-f-${playerPositionStart}-${currentIndex}`}
        className={`absolute w-[150px] ${CHECK_FOLD_POSITION[playerPositionStart].top} ${CHECK_FOLD_POSITION[playerPositionStart].left} icon-f icon-f-${playerPositionStart}-${currentIndex}`}
        iconType="f"
        delay={delay}
        hideOldIcons={["icon-f", "icon-c", "icon-a"]}
        animationRef={
          animationRef.subAnimations[`icon-f-${playerPositionStart}`]
        }
        action=""
      />
      {ActionCollectChips()}
    </React.Fragment>
  )
}

export default ActionFold
