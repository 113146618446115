import ActionReveal from "@components/Animations/ActionReveal"
import { CARD_POSITION_REVEAL } from "@constants/CardPositionReveal"
import { PLAYER_POSITION_REVEAL } from "@constants/PlayerPositionReveal"
import { RootState } from "@redux/store"
import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import styles from "./ActionRevealCards.module.css"
import {
  CARD_POSITION_HAND_READER,
  HIGHLIGHT_POSITION,
} from "@constants/HandReaderPosition"
import anime from "animejs"
import {
  selectWinPlayer,
  unSelectWinPlayer,
} from "@redux/slices/actionsSlice"
import { DELAY_DEFAULT } from "@constants/Actions"

export type ActionRevealCardsPros = {
  translateX: number
  translateY: number
  rotate: number
  targets: string
  control?: boolean
  delay?: number
  cards?: any
  playerPositionStart: number
  animationRef?: any
}

const ActionRevealCards = (props: ActionRevealCardsPros) => {
  const { playerPositionStart, animationRef, cards } = props
  const dispatch = useDispatch()
  const { gameMode, modalWinActionIsOpen, currentDealerPosition, dataModalWinAction } = useSelector((state: RootState) => state.actions)
  const { winPlayersSelection } = dataModalWinAction
  const [isUpdating, setIsUpdating] = useState(false)
  const windowProps = window as any

  const winPlayersCanSelect = useMemo(() => {
    if (winPlayersSelection) {
      return winPlayersSelection.map((x:string) => parseInt(x)).sort()
    }
  }, [winPlayersSelection])

  const ShowCardsForPlayer = useCallback(() => {
    const Elements: JSX.Element[] = []
    let num = 1
    const _cards = cards.filter((card: any) => card)
    let _cardSessions =
      _cards.length > 0 &&
      _cards.map((card: any) => {
        let subCard = card.split(";")
        if (subCard.length === 1) {
          subCard = card.split(":")
        }
        return {
          cardType: subCard[1],
          cardNumber: subCard[0],
        }
      })
    animationRef.is_array = true
    if (!animationRef.subAnimations) {
      animationRef.subAnimations = []
    }
    _cardSessions.forEach((card: any) => {
      if (!animationRef.subAnimations[`s-${playerPositionStart}-${num}`]) {
        animationRef.subAnimations[`s-${playerPositionStart}-${num}`] = {}
      }
      Elements.push(
        <ActionReveal
          cardType={card.cardType}
          cardNumber={card.cardNumber}
          translateX={
            CARD_POSITION_REVEAL[gameMode][
              `PLAYER_${playerPositionStart}_${num}`
            ].X
          }
          translateY={
            CARD_POSITION_REVEAL[gameMode][
              `PLAYER_${playerPositionStart}_${num}`
            ].Y
          }
          rotate={
            CARD_POSITION_REVEAL[gameMode][
              `PLAYER_${playerPositionStart}_${num}`
            ].ROTATE
          }
          playerPositionStart={playerPositionStart}
          targets={`s-${playerPositionStart}-${num}`}
          key={`s-${playerPositionStart} s-${playerPositionStart}-${num}`}
          subAnimations={animationRef.subAnimations}
          animationRef={
            animationRef.subAnimations[`s-${playerPositionStart}-${num}`]
          }
          className={`show-cards-${gameMode}-${playerPositionStart} reveal-card opacity-0 absolute w-[15px] ${PLAYER_POSITION_REVEAL[playerPositionStart].top} ${PLAYER_POSITION_REVEAL[playerPositionStart].left} action-s s-${playerPositionStart} s-${playerPositionStart}-${num} ${styles.revealCard} player-position-${playerPositionStart}`}
        />,
      )
      num += 1
    })
    return Elements
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationRef])

  useEffect(() => {
    // init for highlightRevealCards
    console.log("----- init highlightRevealCards ----- player position -----", playerPositionStart)
    animationRef.highlightRevealCardsSubAnimations = []
    animationRef.highlightRevealCards = {}
    animationRef.highlightRevealCardsEmpty = true
  }, [])

  const handleClickOnRevealCard = () => {
    if (!modalWinActionIsOpen) {
      return
    }

    if (isUpdating) {
      console.log('running animation, please wait!')
      return
    }

    let playerPositionWithDealerButton;
    if (currentDealerPosition == 9) {
      playerPositionWithDealerButton = playerPositionStart
    } else {
      const _temp = (playerPositionStart - currentDealerPosition)
      if (_temp == 0) {
        playerPositionWithDealerButton = 9
      } else {
        if (_temp < 0) {
          playerPositionWithDealerButton = 9 + _temp
        } else {
          playerPositionWithDealerButton = _temp
        }
      }
    }

    if (!winPlayersCanSelect.includes(playerPositionWithDealerButton)) {
      console.log("player cannot be selectable", playerPositionWithDealerButton)
      console.log("player can select", winPlayersCanSelect)
      return
    }

    setIsUpdating(true)

    console.log('------- playerPositionStart Action Reveal --------', playerPositionStart)
    console.log('highlightAnimationRef', animationRef)

    if (animationRef.highlightRevealCardsEmpty) {
      console.log('%cSTARTING highlightRevealCards', 'color: green', animationRef)

      animationRef.highlightRevealCards = anime({
        targets: `.highlight-reveal-cards-${playerPositionStart}`,
        opacity: [0,1],
        duration: 750,
        scale: 1.05,
        easing: "linear",
        loop: true,
        zIndex: 1000001
      })
      animationRef.highlightRevealCardsEmpty = false
      animationRef.highlightRevealCardsSubAnimations = []
      let numberCards = [1, 2, 3, 4]
      if (gameMode === "no_limit_hold_em") {
        numberCards = [1, 2]
      }
      numberCards.forEach((num: number, index: number) => {
        const key = `highlight-sub-scale-reveal-cards-${playerPositionStart}-${num}-${index}`
        animationRef.highlightRevealCardsSubAnimations[key] = {}
        animationRef.highlightRevealCardsSubAnimations[key].current = anime({
          targets: `.s-${playerPositionStart}-${num}`,
          translateX:
            CARD_POSITION_HAND_READER[gameMode][
              `PLAYER_${playerPositionStart}_${num}`
            ].X,
          translateY:
            CARD_POSITION_HAND_READER[gameMode][
              `PLAYER_${playerPositionStart}_${num}`
            ].Y,
          duration: 200,
          scale: 2.5,
          easing: "linear",
          zIndex: 1000000
        })
      })

      dispatch(selectWinPlayer(playerPositionWithDealerButton))

    } else {
      console.log('%cSTOPPING highlightRevealCards', 'color: red', animationRef)
      console.log('remove ', `.highlight-reveal-cards-${playerPositionStart}`)

      animationRef.highlightRevealCards.remove(`.highlight-reveal-cards-${playerPositionStart}`)
      animationRef.highlightRevealCards = {}
      animationRef.highlightRevealCardsEmpty = true
      // @ts-ignore:next-line
      document.getElementsByClassName(`highlight-reveal-cards-${playerPositionStart}`)[0].style.opacity = 0

      Object.entries(animationRef.highlightRevealCardsSubAnimations).forEach((entry) => {
        let subHighlightAnime: any = entry[1]
        if (subHighlightAnime.current && subHighlightAnime.current.completed) {
          subHighlightAnime.current.reverse()
          subHighlightAnime.current.play()
        }
      })

      dispatch(unSelectWinPlayer(playerPositionWithDealerButton))
    }

    setTimeout(() => {
      setIsUpdating(false)
    }, DELAY_DEFAULT)
  }

  const handleReplayActionHighlightRevealCards = () => {
    console.log('%cRE-STARTING highlightRevealCards', 'color: green', animationRef)

    animationRef.highlightRevealCards = anime({
      targets: `.highlight-reveal-cards-${playerPositionStart}`,
      opacity: [0,1],
      duration: 750,
      scale: 1.05,
      easing: "linear",
      loop: true,
      zIndex: 1000001
    })
    animationRef.highlightRevealCardsEmpty = false
    animationRef.highlightRevealCardsSubAnimations = []
    let numberCards = [1, 2, 3, 4]
    if (gameMode === "no_limit_hold_em") {
      numberCards = [1, 2]
    }
    numberCards.forEach((num: number, index: number) => {
      const key = `highlight-sub-scale-reveal-cards-${playerPositionStart}-${num}-${index}`
      animationRef.highlightRevealCardsSubAnimations[key] = {}
      animationRef.highlightRevealCardsSubAnimations[key].current = anime({
        targets: `.s-${playerPositionStart}-${num}`,
        translateX:
          CARD_POSITION_HAND_READER[gameMode][
            `PLAYER_${playerPositionStart}_${num}`
          ].X,
        translateY:
          CARD_POSITION_HAND_READER[gameMode][
            `PLAYER_${playerPositionStart}_${num}`
          ].Y,
        duration: 200,
        scale: 2.5,
        easing: "linear",
        zIndex: 1000000
      })
    })
  }

  const handleReverseActionHighLightRevealCards = () => {
    console.log('%cSTOPPING highlightRevealCards', 'color: red', animationRef)
    console.log('remove ', `.highlight-reveal-cards-${playerPositionStart}`)

    animationRef.highlightRevealCards.remove(`.highlight-reveal-cards-${playerPositionStart}`)
    animationRef.highlightRevealCards = {}
    animationRef.highlightRevealCardsEmpty = true
    // @ts-ignore:next-line
    document.getElementsByClassName(`highlight-reveal-cards-${playerPositionStart}`)[0].style.opacity = 0

    for (let entry in animationRef.highlightRevealCardsSubAnimations) {
      let subHighlightAnime: any = animationRef.highlightRevealCardsSubAnimations[entry]
      if (subHighlightAnime.current) {
        console.log('reversing subHighlightAnime ...', subHighlightAnime)
        subHighlightAnime.current.reverse()
        subHighlightAnime.current.play()
      }
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          top: HIGHLIGHT_POSITION[gameMode][`PLAYER_${playerPositionStart}`].top,
          left: HIGHLIGHT_POSITION[gameMode][`PLAYER_${playerPositionStart}`]
            .left,
          width: HIGHLIGHT_POSITION[gameMode].HIGHLIGHT_WIDTH,
          height: HIGHLIGHT_POSITION[gameMode].HIGHLIGHT_HEIGHT,
          transform: `rotate(${HIGHLIGHT_POSITION[gameMode][`PLAYER_${playerPositionStart}`].ROTATE}deg)`,
          zIndex: 1000001,
        }}
        className={`opacity-0 highlight-reveal-cards-${playerPositionStart} border-[2px] rounded-[15px] border-[#fc3d17]`}
        onClick={handleClickOnRevealCard}
      ></div>
      <button
        id={`reverse-action-highlight-reveal-cards-${playerPositionStart}`}
        className="opacity-0"
        onClick={handleReverseActionHighLightRevealCards}
      ></button>
      <button
        id={`replay-action-highlight-reveal-cards-${playerPositionStart}`}
        className="opacity-0"
        onClick={handleReplayActionHighlightRevealCards}
      ></button>
      {ShowCardsForPlayer()}
    </React.Fragment>
  )
}

export default ActionRevealCards
