import ICON_A from "@assets/icons/icon_a.png"
import ICON_C from "@assets/icons/check.png"
import ICON_F from "@assets/icons/icon_f.png"
import ActionBlind from "@components/Animations/ActionBlind"
import ActionBoard from "@components/Animations/ActionBoard"
import ActionBurn from "@components/Animations/ActionBurn"
import ActionCalculateAndCollectBets from "@components/Animations/ActionCalculateAndCollectBets"
import ActionClear from "@components/Animations/ActionClear"
import ActionCollectBets from "@components/Animations/ActionCollectBets"
import ActionD from "@components/Animations/ActionD"
import ActionDiscardCard from "@components/Animations/ActionDiscardCard"
import ActionDropChip from "@components/Animations/ActionDropChip"
import ActionFold from "@components/Animations/ActionFold"
import ActionHighlightCards from "@components/Animations/ActionHighlightCards"
import ActionLift from "@components/Animations/ActionLift"
import ActionMoveDealerButton from "@components/Animations/ActionMoveDealerButton"
import ActionPlaceRake from "@components/Animations/ActionPlaceRake"
import ActionMainPot from "@components/Animations/ActionPot"
import ActionPromo2 from "@components/Animations/ActionPromo2"
import ActionRevealCards from "@components/Animations/ActionRevealCards"
import ActionShowOut from "@components/Animations/ActionShowOut"
import ActionSidePot from "@components/Animations/ActionSidePot"
import { DELAY_DEFAULT } from "@constants/Actions"
import { CHIP_TYPE, CHIP_VALUE } from "@constants/Chip"
import { GAME_MODES } from "@constants/GameMode"
import {
  PLAYER_NUMBER,
  PLAYER_POSITION,
  CHECK_FOLD_POSITION,
} from "@constants/Player"
import { COIN_POSITION_TRANSLATE } from "@constants/ShowCoin"
import {
  ICON_POSITION_TRANSLATE,
  CHECK_FOLD_ICON_POSITION_TRANSLATE,
} from "@constants/ShowOut"
import {
  BURN_CARD_POSITION,
  SINGLE_CARD_POSITION,
  BURN_CARD_V2_POSITION,
} from "@constants/SingleCard"
import {
  setDataModalCalculate,
  setDataModalChip,
  setDataModalRemaining,
  setModalCalculateIsOpen,
  setModalChipIsOpen,
  setModalRemainingIsOpen,
  setLargestTranslateX,
  setModalPromoIsOpen,
  setDataModalPromo,
  setDataModalHandReader,
  setModalHandReaderIsOpen,
  setDataModalWinAction,
  setModalWinActionIsOpen,
} from "@redux/slices/actionsSlice"
import { AnyAction } from "@reduxjs/toolkit"
import { ActionDto } from "@types"
import React, { Dispatch } from "react"
import { gameModeIcons } from "@helpers/modeIcons"
import { onlyUnique } from "@helpers/utils"

export const MAPPING: any = {
  deal: ActionD,
  f: ActionFold,
  l: ActionBlind,
  k: ActionShowOut,
  a2: ActionShowOut,
  f2: ActionFold,
  bets: ActionCollectBets,
  side: ActionCalculateAndCollectBets,
  pot: ActionMainPot,
  pot2: ActionSidePot,
  rake: ActionPlaceRake,
  rake2: ActionDropChip,
  r: ActionBlind,
  c: ActionBlind,
  p: ActionBlind,
  b: ActionBlind,
  s: ActionRevealCards,
  a: ActionBlind,
  board: ActionBoard,
  muck: ActionDiscardCard,
  clear: ActionClear,
  dealb: ActionMoveDealerButton,
  burn: ActionBurn,
  promo2: ActionPromo2,
  promo3: ActionDropChip,
  lift: ActionLift,
  read: ActionHighlightCards,
}

export const resetDealer = (
  deadlerPosition: number,
  counterAction: number,
  animationRef: any,
): JSX.Element => {
  let actionElement: any = <React.Fragment key={counterAction}></React.Fragment>
  const ActionAnimation = MAPPING["dealb"] || <></>
  actionElement = (
    <ActionAnimation
      currentDealerPosition={deadlerPosition}
      targets="btn-dealer"
      key={`dealb-${counterAction}`}
      animationRef={animationRef}
      flagReset={true}
    />
  )
  return actionElement
}

export const getActionElement = (
  action: ActionDto,
  autoCounter: number,
  counterAction: number,
  animationRef: any,
  dispatch: Dispatch<AnyAction>,
  onBackAction?: boolean,
  gameMode?: string,
  user?: any,
): JSX.Element => {
  let actionElement: any = <React.Fragment key={counterAction}></React.Fragment>
  const ActionAnimation = MAPPING[action.type] || <></>

  switch (action.type) {
    case "deal":
      actionElement = (
        <ActionAnimation
          translateX={
            SINGLE_CARD_POSITION[gameMode || GAME_MODES.PLO][
              `PLAYER_${action.playerPositionEnd}_${action.cardNumber}`
            ].X - 4
          }
          translateY={
            SINGLE_CARD_POSITION[gameMode || GAME_MODES.PLO][
              `PLAYER_${action.playerPositionEnd}_${action.cardNumber}`
            ].Y - 30
          }
          rotate={
            SINGLE_CARD_POSITION[gameMode || GAME_MODES.PLO][
              `PLAYER_${action.playerPositionEnd}_${action.cardNumber}`
            ].ROTATE
          }
          targets={`deal-card-${action.playerPositionEnd}-${action.cardNumber}`}
          key={`deal-card-${action.playerPositionEnd}-${action.cardNumber}-${counterAction}`}
          delay={DELAY_DEFAULT * autoCounter}
          className={`f-card-${action.playerPositionEnd}`}
          animationRef={animationRef}
          type="deal"
        />
      )
      break
    case "burn":
      actionElement = (
        <ActionAnimation
          translateX={
            user?.alternatePotLocation
              ? BURN_CARD_V2_POSITION[gameMode || GAME_MODES.PLO]["BURN_POT"].X
              : BURN_CARD_POSITION[gameMode || GAME_MODES.PLO]["BURN_POT"].X
          }
          translateY={
            user?.alternatePotLocation
              ? BURN_CARD_V2_POSITION[gameMode || GAME_MODES.PLO]["BURN_POT"]
                  .Y - 30
              : BURN_CARD_POSITION[gameMode || GAME_MODES.PLO]["BURN_POT"].Y -
                30
          }
          targets={`deal-card-${action.playerPositionEnd}-${counterAction}`}
          key={`deal-card-${action.playerPositionEnd}-${counterAction}`}
          delay={DELAY_DEFAULT * autoCounter}
          className={`f-card-${action.playerPositionEnd}`}
          animationRef={animationRef}
          type="burn"
        />
      )
      break
    case "f":
      actionElement = (
        <ActionAnimation
          translateX={SINGLE_CARD_POSITION.STACK_POSITION.X}
          translateY={SINGLE_CARD_POSITION.STACK_POSITION.Y}
          rotate={SINGLE_CARD_POSITION.STACK_POSITION.ROTATE}
          playerPositionStart={action.playerPositionStart}
          targets={`f-card-${action.playerPositionStart}`}
          key={`f-${action.playerPositionStart}-${counterAction}`}
          delay={DELAY_DEFAULT * autoCounter}
          animationRef={animationRef}
          currentIndex={counterAction}
        />
      )
      break
    case "f2":
      if (action.playerPositionStart === 0) {
        dispatch(
          setDataModalChip({
            actionTitle: `Player ${action.originalPositionPlayer} folds and loses $${action.bet} to the Pot.`,
            whileChipNumber: action.white,
            redChipNumber: action.red,
            greenChipNumber: action.green,
            blackChipNumber: action.black,
            bet: action.bet,
            change: action.change,
            onBackAction: onBackAction,
            modalIndex: counterAction,
          }),
        )
        setTimeout(() => {
          dispatch(setModalChipIsOpen(true))
        }, 50)
        actionElement = "pop-up"
      } else {
        actionElement = (
          <ActionAnimation
            translateX={SINGLE_CARD_POSITION.STACK_POSITION.X}
            translateY={SINGLE_CARD_POSITION.STACK_POSITION.Y}
            rotate={SINGLE_CARD_POSITION.STACK_POSITION.ROTATE}
            playerPositionStart={action.playerPositionStart}
            targets={`f-card-${action.playerPositionStart}`}
            key={`f2-${action.playerPositionStart}-${counterAction}`}
            delay={DELAY_DEFAULT * autoCounter}
            chipInfos={{
              quantityOfType: {
                black: action.black,
                green: action.green,
                red: action.red,
                white: action.white,
              },
              bet: action.bet,
              change: action.change,
            }}
            withChip={true}
            animationRef={animationRef}
            currentIndex={counterAction}
          />
        )
      }
      break
    case "l":
      actionElement = action.playerPositionStart ? (
        <ActionAnimation
          translateX={COIN_POSITION_TRANSLATE[action.playerPositionStart].X}
          translateY={COIN_POSITION_TRANSLATE[action.playerPositionStart].Y}
          targets={`icon-r-${action.playerPositionStart}`}
          className={`absolute w-[120px] ${
            PLAYER_POSITION[action.playerPositionStart].top
          } ${PLAYER_POSITION[action.playerPositionStart].left} icon-r icon-r-${
            action.playerPositionStart
          }`}
          playerPositionStart={action.playerPositionStart}
          chipInfos={{
            quantityOfType: {
              red: action.red,
              white: action.white,
              green: action.green,
              black: action.black,
            },
            bet: 11,
            change: 5,
          }}
          key={`l-${action.playerPositionStart}-${counterAction}`}
          animationRef={animationRef}
          delay={DELAY_DEFAULT * autoCounter}
          counterAction={counterAction}
        />
      ) : (
        <></>
      )
      break
    case "r":
      if (action.playerPositionStart) {
        actionElement = (
          <ActionAnimation
            translateX={COIN_POSITION_TRANSLATE[action.playerPositionStart].X}
            translateY={COIN_POSITION_TRANSLATE[action.playerPositionStart].Y}
            targets={`icon-r-${action.playerPositionStart}`}
            className={`absolute w-[120px] ${
              PLAYER_POSITION[action.playerPositionStart].top
            } ${
              PLAYER_POSITION[action.playerPositionStart].left
            } icon-r icon-r-${action.playerPositionStart}`}
            playerPositionStart={action.playerPositionStart}
            chipInfos={{
              quantityOfType: {
                red: action.red,
                white: action.white,
                green: action.green,
                black: action.black,
              },
              bet: action.bet,
              change: action.change,
            }}
            key={`r-${action.playerPositionStart}-${counterAction}`}
            animationRef={animationRef}
            delay={DELAY_DEFAULT * autoCounter}
            counterAction={counterAction}
          />
        )
      } else {
        dispatch(
          setDataModalChip({
            actionTitle: `Player ${action.originalPositionPlayer} raises to $${action.bet}`,
            whileChipNumber: action.white,
            redChipNumber: action.red,
            greenChipNumber: action.green,
            blackChipNumber: action.black,
            bet: action.bet,
            change: action.change,
            onBackAction: onBackAction,
            modalIndex: counterAction,
          }),
        )
        setTimeout(() => {
          dispatch(setModalChipIsOpen(true))
        }, 50)
        actionElement = "pop-up"
      }

      break
    case "b":
      if (action.playerPositionStart) {
        actionElement = (
          <ActionAnimation
            translateX={COIN_POSITION_TRANSLATE[action.playerPositionStart].X}
            translateY={COIN_POSITION_TRANSLATE[action.playerPositionStart].Y}
            targets={`icon-r-${action.playerPositionStart}`}
            className={`absolute w-[120px] ${
              PLAYER_POSITION[action.playerPositionStart].top
            } ${
              PLAYER_POSITION[action.playerPositionStart].left
            } icon-r icon-r-${action.playerPositionStart}`}
            playerPositionStart={action.playerPositionStart}
            chipInfos={{
              quantityOfType: {
                red: action.red,
                white: action.white,
                green: action.green,
                black: action.black,
              },
              bet: action.bet,
              change: action.change,
            }}
            key={`b-${action.playerPositionStart}-${counterAction}`}
            animationRef={animationRef}
            delay={DELAY_DEFAULT * autoCounter}
            counterAction={counterAction}
          />
        )
      } else {
        dispatch(
          setDataModalChip({
            actionTitle: `Player ${action.originalPositionPlayer} bets $${action.bet}`,
            whileChipNumber: action.white,
            redChipNumber: action.red,
            greenChipNumber: action.green,
            blackChipNumber: action.black,
            bet: action.bet,
            change: action.change,
            onBackAction: onBackAction,
            modalIndex: counterAction,
          }),
        )
        setTimeout(() => {
          dispatch(setModalChipIsOpen(true))
        }, 50)
        actionElement = "pop-up"
      }
      break
    case "c":
      if (action.playerPositionStart) {
        actionElement = (
          <ActionAnimation
            translateX={COIN_POSITION_TRANSLATE[action.playerPositionStart]?.X}
            translateY={COIN_POSITION_TRANSLATE[action.playerPositionStart]?.Y}
            targets={`icon-r-${action.playerPositionStart}`}
            className={`absolute w-[120px] ${
              PLAYER_POSITION[action.playerPositionStart].top
            } ${
              PLAYER_POSITION[action.playerPositionStart].left
            } icon-r icon-r-${action.playerPositionStart}`}
            playerPositionStart={action.playerPositionStart}
            chipInfos={{
              quantityOfType: {
                red: action.red,
                white: action.white,
                green: action.green,
                black: action.black,
              },
              bet: action.bet,
              change: action.change,
            }}
            key={`c-${action.playerPositionStart}-${counterAction}`}
            animationRef={animationRef}
            delay={DELAY_DEFAULT * autoCounter}
            counterAction={counterAction}
          />
        )
      } else {
        dispatch(
          setDataModalChip({
            actionTitle: `Player ${action.originalPositionPlayer} calls $${action.bet}`,
            whileChipNumber: action.white,
            redChipNumber: action.red,
            greenChipNumber: action.green,
            blackChipNumber: action.black,
            bet: action.bet,
            change: action.change,
            onBackAction: onBackAction,
            modalIndex: counterAction,
          }),
        )
        setTimeout(() => {
          dispatch(setModalChipIsOpen(true))
        }, 50)
        actionElement = "pop-up"
      }
      break
    case "p":
      if (action.playerPositionStart) {
        actionElement = (
          <ActionAnimation
            translateX={COIN_POSITION_TRANSLATE[action.playerPositionStart].X}
            translateY={COIN_POSITION_TRANSLATE[action.playerPositionStart].Y}
            targets={`icon-r-${action.playerPositionStart}`}
            className={`absolute w-[120px] ${
              PLAYER_POSITION[action.playerPositionStart].top
            } ${
              PLAYER_POSITION[action.playerPositionStart].left
            } icon-r icon-r-${action.playerPositionStart}`}
            playerPositionStart={action.playerPositionStart}
            chipInfos={{
              quantityOfType: {
                red: action.red,
                white: action.white,
                green: action.green,
                black: action.black,
              },
              bet: action.bet,
              change: action.change,
            }}
            key={`p-${action.playerPositionStart}-${counterAction}`}
            animationRef={animationRef}
            delay={DELAY_DEFAULT * autoCounter}
            counterAction={counterAction}
          />
        )
      } else {
        dispatch(
          setDataModalChip({
            actionTitle: `Player ${action.originalPositionPlayer} bets "Pot" ${action.formula}`,
            whileChipNumber: action.white,
            redChipNumber: action.red,
            greenChipNumber: action.green,
            blackChipNumber: action.black,
            bet: action.bet,
            change: action.change,
            onBackAction: onBackAction,
            modalIndex: counterAction,
          }),
        )
        setTimeout(() => {
          dispatch(setModalChipIsOpen(true))
        }, 50)
        actionElement = "pop-up"
      }
      break
    case "a":
      if (action.playerPositionStart) {
        actionElement = (
          <ActionAnimation
            translateX={COIN_POSITION_TRANSLATE[action.playerPositionStart].X}
            translateY={COIN_POSITION_TRANSLATE[action.playerPositionStart].Y}
            targets={`icon-r-${action.playerPositionStart}`}
            className={`absolute w-[120px] ${
              PLAYER_POSITION[action.playerPositionStart].top
            } ${
              PLAYER_POSITION[action.playerPositionStart].left
            } icon-r icon-r-${action.playerPositionStart}`}
            playerPositionStart={action.playerPositionStart}
            chipInfos={{
              quantityOfType: {
                red: action.red,
                white: action.white,
                green: action.green,
                black: action.black,
              },
              bet: action.bet,
              change: action.change,
            }}
            key={`a-${action.playerPositionStart}-${counterAction}`}
            animationRef={animationRef}
            delay={DELAY_DEFAULT * autoCounter}
            counterAction={counterAction}
          />
        )
      } else {
        dispatch(
          setDataModalChip({
            actionTitle: `Player ${action.originalPositionPlayer} goes "All In" for $${action.bet}`,
            whileChipNumber: action.white,
            redChipNumber: action.red,
            greenChipNumber: action.green,
            blackChipNumber: action.black,
            bet: action.bet,
            change: action.change,
            onBackAction: onBackAction,
            modalIndex: counterAction,
          }),
        )
        setTimeout(() => {
          dispatch(setModalChipIsOpen(true))
        }, 50)
        actionElement = "pop-up"
      }
      break
    case "k":
      actionElement = action.playerPositionStart ? (
        <ActionAnimation
          translateX={
            CHECK_FOLD_ICON_POSITION_TRANSLATE[action.playerPositionStart].X
          }
          translateY={
            CHECK_FOLD_ICON_POSITION_TRANSLATE[action.playerPositionStart].Y
          }
          targets={`icon-c-${action.playerPositionStart}-${counterAction}`}
          className={`absolute w-[150px] ${
            CHECK_FOLD_POSITION[action.playerPositionStart].top
          } ${
            CHECK_FOLD_POSITION[action.playerPositionStart].left
          } icon-c icon-c-${action.playerPositionStart}-${counterAction}`}
          iconType="c"
          hideOldIcons={["icon-f", "icon-c", "icon-a"]}
          key={`k-${action.playerPositionStart}-${counterAction}`}
          animationRef={animationRef}
          delay={DELAY_DEFAULT * autoCounter}
        />
      ) : (
        <></>
      )
      break
    case "a2":
      actionElement = action.playerPositionStart ? (
        <ActionAnimation
          translateX={
            ICON_POSITION_TRANSLATE[action.playerPositionStart].X + 25
          }
          translateY={
            ICON_POSITION_TRANSLATE[action.playerPositionStart].Y + 16
          }
          targets={`icon-a-${action.playerPositionStart}-${counterAction}`}
          className={`absolute w-[90px] ${
            PLAYER_POSITION[action.playerPositionStart].top
          } ${PLAYER_POSITION[action.playerPositionStart].left} icon-a icon-a-${
            action.playerPositionStart
          }-${counterAction}`}
          iconType="a"
          hideOldIcons={["icon-f", "icon-c", "icon-a"]}
          key={`a2-${action.playerPositionStart}-${counterAction}`}
          animationRef={animationRef}
          delay={DELAY_DEFAULT * autoCounter}
        />
      ) : (
        <></>
      )
      break
    case "bets":
      let arr: any = []
      actionElement = (
        <ActionAnimation
          key={`bets-${counterAction}`}
          animationRef={animationRef}
          returnTranslateX={(n: number) => {
            arr.push(n)
            dispatch(setLargestTranslateX(Math.max(...arr)))
          }}
        />
      )
      break
    case "side":
      actionElement = (
        <ActionAnimation
          playerPositionEnd={action.playerPositionEnd}
          chipToMainPot={action.input}
          chipToSidePot={action.black}
          key={`side-${action.playerPositionEnd}-${counterAction}`}
          animationRef={animationRef}
        />
      )
      break
    case "pot":
      actionElement = (
        <ActionAnimation
          playerPositionEnd={action.playerPositionEnd}
          chipInfos={{
            quantityOfType: {
              black: action.black,
              green: action.green,
              red: action.red,
              white: action.white,
            },
            bet: action.bet,
            change: action.change,
          }}
          key={`pot-${action.playerPositionEnd}-${counterAction}`}
          animationRef={animationRef}
        />
      )
      break
    case "pot2":
      actionElement = (
        <ActionAnimation
          playerPositionEnd={action.playerPositionEnd}
          chipInfos={{
            quantityOfType: {
              black: action.black,
              green: action.green,
              red: action.red,
              white: action.white,
            },
            bet: action.bet,
            change: action.change,
          }}
          key={`pot2-${action.playerPositionEnd}-${counterAction}`}
          animationRef={animationRef}
        />
      )
      break
    case "rake":
      actionElement = (
        <ActionAnimation
          key={`rake-${counterAction}`}
          animationRef={animationRef}
        />
      )
      break
    case "rake2":
      actionElement = (
        <ActionAnimation
          key={`rake2-${counterAction}`}
          animationRef={animationRef}
          targets=".rake-collected"
        />
      )
      break
    case "s":
      actionElement = (
        <ActionAnimation
          playerPositionStart={action.playerPositionStart}
          key={`action-s-${action.playerPositionStart}-${counterAction}`}
          targets={`s-${action.playerPositionStart}-${action.input}`}
          animationRef={animationRef}
          cards={[
            action.playerPositionEnd,
            action.input,
            action.black,
            action.green,
          ]}
        />
      )
      break
    case "board":
      actionElement = (
        <ActionAnimation
          control={true}
          key={`board-${counterAction}`}
          cards={[
            action.playerPositionEnd,
            action.input,
            action.black,
            action.green,
            action.red,
          ]}
          animationRef={animationRef}
        />
      )
      break
    case "muck":
      actionElement = (
        <ActionAnimation
          playerPositionEnd={action.playerPositionEnd}
          key={`muck-${action.playerPositionEnd}-${counterAction}`}
          animationRef={animationRef}
        />
      )
      break
    case "clear":
      actionElement = (
        <ActionAnimation
          gameMode={gameMode}
          key={`clear-${counterAction}`}
          animationRef={animationRef}
          counterAction={counterAction}
        />
      )
      break
    case "num":
      dispatch(
        setDataModalRemaining({
          players: action.playerPositionEnd,
          onBackAction: onBackAction,
          modalIndex: counterAction,
        }),
      )
      setTimeout(() => {
        dispatch(setModalRemainingIsOpen(true))
      }, 50)
      actionElement = "pop-up"
      break
    case "promo":
      dispatch(
        setDataModalPromo({
          promo: action.playerPositionEnd,
          onBackAction: onBackAction,
          modalIndex: counterAction,
        }),
      )
      setTimeout(() => {
        dispatch(setModalPromoIsOpen(true))
      }, 50)
      actionElement = "pop-up"
      break
    case "promo2":
      actionElement = (
        <ActionAnimation
          key={`promo2-${counterAction}`}
          animationRef={animationRef}
          betsForPromo={action.playerPositionEnd}
        />
      )
      break
    case "promo3":
      actionElement = (
        <ActionAnimation
          key={`promo3-${counterAction}`}
          animationRef={animationRef}
          targets=".promo2-collected"
        />
      )
      break
    case "calc":
      dispatch(
        setDataModalCalculate({
          potNumber: action.playerPositionEnd,
          rakeNumber: action.input,
          sidePotNumber: action.black,
          onBackAction: onBackAction,
          modalIndex: counterAction,
          hasRakeNumber: true,
        }),
      )
      setTimeout(() => {
        dispatch(setModalCalculateIsOpen(true))
      }, 50)
      actionElement = "pop-up"
      break
    case "calc2":
      dispatch(
        setDataModalCalculate({
          potNumber: action.playerPositionEnd,
          hasRakeNumber: false,
          sidePotNumber: action.black,
          onBackAction: onBackAction,
          modalIndex: counterAction,
        }),
      )
      setTimeout(() => {
        dispatch(setModalCalculateIsOpen(true))
      }, 50)
      actionElement = "pop-up"
      break
    case "dealb":
      actionElement = (
        <ActionAnimation
          currentDealerPosition={action.deadlerPosition}
          targets="btn-dealer"
          key={`dealb-${counterAction}`}
          animationRef={animationRef}
          flagReset={false}
        />
      )
      break
    case "read":
      // dispatch(
      //   setDataModalHandReader({
      //     code: action.input,
      //     playerPosition: Number(action.playerPositionEnd),
      //     onBackAction: onBackAction,
      //     modalIndex: counterAction,
      //   }),
      // )
      // setTimeout(() => {
      //   dispatch(setModalHandReaderIsOpen(true))
      // }, 50)
      actionElement = (
        <ActionAnimation
          counterAction={counterAction}
          animationRef={animationRef}
          playerPositionEnd={Number(action.playerPositionEnd)}
          key={`read-${counterAction}`}
        />
      )
      break
    case "lift":
      actionElement = (
        <ActionAnimation
          animationRef={animationRef}
          cardNumbers={[
            action.playerPositionEnd,
            action.input,
            action.black,
            action.green,
            action.red,
          ]}
        />
      )
      break
    case "win":
      const winPlayers = (String(action.winPlayers).indexOf(',') > -1) ? action.winPlayers.split(',') : [...String(action.winPlayers)].map(Number)
      const winPlayersOthers = (String(action.winPlayersSelection).indexOf(',') > -1) ? action.winPlayersSelection.split(',') : [...String(action.winPlayersSelection)].map(Number)
      const winPlayersCombined = winPlayers.concat(winPlayersOthers).filter(onlyUnique)

      console.log('winPlayersCombined', winPlayersCombined)

      dispatch(
        setDataModalWinAction({
          actionTitle: `Click on all the winning Hands. Click OK when done.`,
          winPlayers: winPlayers,
          winPlayersSelection: winPlayersCombined,
          onBackAction: onBackAction,
          modalIndex: counterAction,
        }),
      )
      setTimeout(() => {
        dispatch(setModalWinActionIsOpen(true))
      }, 50)
      actionElement = "pop-up"
      break
    default:
      alert(`Action ${action.type} is not handled`)
      break
  }
  return actionElement
}

export const getElementForActionShowOut = (gameMode: string, actionType: string) => {
  const { whiteCoin, greenCoin ,redCoin ,blackCoin  } = gameModeIcons(gameMode)

  switch (actionType) {
    case "f":
      return <img src={ICON_F} alt="ICON_F" />
    case "c":
      return <img src={ICON_C} alt="ICON_C" />
    case "a":
      return <img src={ICON_A} alt="ICON_A" />
    case "red":
      return <img src={redCoin} alt="Red Coin Icon" />
    case "black":
      return <img src={blackCoin} alt="Black Coin Icon" />
    case "green":
      return <img src={greenCoin} alt="Green Coin Icon" />
    case "white":
      return <img src={whiteCoin} alt="White Coin Icon" />
    default:
      return <></>
  }
}

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const getActionTargets = (targets: any | any[] | undefined) => {
  if (typeof targets === "string") return `.${targets}`
  else return targets
}

export const calculateBets = (
  className: string,
  classNotContain: string[] = [],
) => {
  const domElements: any = document.getElementsByClassName(className)
  const result: any = { black: 0, green: 0, red: 0, white: 0, bet: 0 }
  CHIP_TYPE.forEach((type) => {
    PLAYER_NUMBER.forEach((num) => {
      for (let element of domElements) {
        const classNameRemove = getClassNames(element, classNotContain)
        if (
          element.classList.contains(`${type}-${num}`) &&
          classNameRemove.length === 0
        ) {
          result[type] += 1
          result.bet += CHIP_VALUE[type]
        }
      }
    })
  })
  return result
}

export const clearClassNames = (element: Element, classNames: string[]) => {
  if (element.classList.length > 0) {
    const pattern = new RegExp(classNames.join("|"), "i")
    element.classList.forEach((_class) => {
      if (pattern.test(_class)) {
        element.classList.remove(_class)
      }
    })
  }
  return element
}

export const getClassNames = (element: Element, prefix: string[]) => {
  const classNames: string[] = []
  if (element.classList.length > 0 && prefix.length > 0) {
    const pattern = new RegExp(prefix.join("|"), "i")
    element.classList.forEach((_class) => {
      if (pattern.test(_class)) {
        classNames.push(_class)
      }
    })
  }
  return classNames
}

export const clearChipClassTypePlayerNumber = (
  element: Element,
  playerPosition: number,
  type: string,
) => {
  if (element.classList.length > 0) {
    const pattern = new RegExp(`${type}-${playerPosition}-[0-9]+`, "i")
    element.classList.forEach((_class) => {
      if (pattern.test(_class)) {
        element.classList.remove(_class)
      }
    })
  }
  return element
}

export const findNearestChipElements = (
  playerPosition: number,
  currentStep: number,
) => {
  const chipElements = Array.from(
    document.getElementsByClassName(`chips-${playerPosition}`),
  )

  //group chip elements by their step number
  const groups = chipElements.reduce((group: { [key: number]: any }, el) => {
    const classWithStep = Array.from(el.classList).filter((a) =>
      a.startsWith(`p-${playerPosition}-step-`),
    )

    if (classWithStep.length > 0) {
      const ids = classWithStep[0].split("-")
      const step = parseInt(ids[ids.length - 1])
      group[step] = group[step] ?? []
      group[step].push(el)
    }

    return group
  }, {})

  // only find the last one smaller than the current step
  const nearestStep = Object.keys(groups)
    .map(Number)
    .sort()
    .findLast((s) => s < currentStep)

  if (nearestStep != undefined) {
    return groups[nearestStep]
  } else {
    return []
  }
}
